import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { AsyncComponent } from "../components/common/AsyncComponent";
import { InventoryUrl } from "../config";
import { useApiCall } from "../hooks/useApiCall";
import { ProductModel } from "../models/api/product";
import { ActionEnum } from "../models/enums/actionEnum";
import { GroupModel } from "../models/api/group";
import { ModifyGroup } from "../components/feature/ModifyGroup";

export const InventoryModifyStyle = styled.div`
  h4,
  h5,
  h6 {
    text-align: left;
    margin-top: 1rem;
  }
`;

export function InventoryGroupModifyView() {
  const id = Number(
    new URLSearchParams(new URL(window.location.href).search).get("id")
  );
  const [, , error, get, post] = useApiCall<GroupModel>({});
  const [inventoryData, setInventoryData] = useState<{
    products: ProductModel[];
    group: GroupModel[];
  }>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const _products = await get(InventoryUrl + "/product");
      const _group = await get(InventoryUrl + "/group?id=" + id);
      setInventoryData({
        products: _products.data,
        group: _group.data[0],
      });
      setLoading(false);
    };
    fetchData();
  }, []);

  async function updateGroup(group: GroupModel) {
    setLoading(true);
    await post(InventoryUrl + "/group/modify", {
      Action: ActionEnum.Update,
      Item: { ...group },
    });
    setLoading(false);
    navigate("/inventory");
  }

  return (
    <InventoryModifyStyle>
      <AsyncComponent data={inventoryData} isLoading={loading} error={error}>
        {(response: { products: ProductModel[]; group: GroupModel }) => {
          return (
            <>
              <div className="product-editor">
                <h4>Modyfikuj grupę</h4>
              </div>
              <ModifyGroup
                group={response.group}
                allProducts={response.products.filter(
                  (e) => !e.groupId || e.groupId === id
                )}
                onGroupModified={(group) => {
                  updateGroup(group);
                }}
              ></ModifyGroup>
            </>
          );
        }}
      </AsyncComponent>
    </InventoryModifyStyle>
  );
}
