import styled from "styled-components";

const ViewMoreButtonStyle = styled.div`
  margin-left: auto;
  .view-more {
    font-size: 0.8rem;
    padding-top: 0rem;
    justify-content: right;
    white-space: nowrap;
    .btn--text-only {
      padding: unset;
      margin-left: 1rem;
    }
  }
`;

export interface IViewMoreButton {
  onClick?: (data: unknown) => unknown;
  label?: string;
  className?: string;
  children?: React.ReactElement;
}

export function ViewMoreButton(props: IViewMoreButton) {
  return (
    <ViewMoreButtonStyle>
      <div className={"view-more " + props.className ?? ""}>
        <button className="btn--text-only" onClick={props.onClick}>
          {props.children ? props.children : props.label ?? "▶ Pokaż filtry"}
        </button>
      </div>
    </ViewMoreButtonStyle>
  );
}
