import { useState } from "react";
import styled from "styled-components";
import { useEffect } from "react";

const ColorSelectorStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  .btn {
    width: 3rem;
    margin: 0.2rem;
    padding: 0.2rem;
    position: relative;
    &--selected {
      ::before {
        position: absolute;
        color: var(--col-bg-primary);
        content: "✓";
      }
    }
  }
`;
export interface IColorSelector {
  onSelectedColorChanged: (color: string) => void;
  selectedColor?: string;
}

export function ColorSelector(props: IColorSelector) {
  const [selectedColor, setSelectedColor] = useState<string>();
  const [availableAccents, setAvailableAccents] = useState<string[]>([]);

  useEffect(() => {
    const _colors = [];
    _colors.push("--col-fg-active");
    getComputedStyle(document.body);
    for (let i = 0; i <= 99; i++) {
      const _style = getComputedStyle(document.body).getPropertyValue(
        "--col-accent-" + i
      );
      if (_style !== "") {
        _colors.push("--col-accent-" + i);
      } else {
        setAvailableAccents([..._colors]);
        return;
      }
    }
  }, []);

  useEffect(() => {
    setSelectedColor(props.selectedColor ?? "--col-fg-active");
  }, [props]);

  return (
    <ColorSelectorStyle>
      {availableAccents.map((color) => (
        <div className="color" key={color}>
          <button
            className={color === selectedColor ? "btn btn--selected" : "btn"}
            style={{ background: `var(${color})` }}
            onClick={() => props.onSelectedColorChanged(color)}
          ></button>
        </div>
      ))}
    </ColorSelectorStyle>
  );
}
