import { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const DropDownStyle = styled.div`
  width: 100%;
  .drop-down {
    border: var(--border-width) solid var(--col-fg-secondary);
    background-color: var(--col-bg-primary);
    width: 100%;
    min-width: fit-content;
    min-height: 2rem;
    padding: 0.2rem;
    color: var(--col-fg-primary);
    cursor: pointer;
    &:focus {
      outline: none;
      border: var(--border-width) solid var(--col-fg-active);
    }
  }
`;
export interface IDropDown {
  items: { key: any; value: any }[];
  selectedIndex?: number;
  onItemSelected?: (item: any) => void;
}

export function DropDown(props: IDropDown) {
  const [expanded, setExpanded] = useState(false);
  const [selectedItem, setSelectedItem] = useState<{ key: any; value: any }>(
    props.items?.[props.selectedIndex ?? 0]
  );
  const dropDownBodyRef = useRef<any>(null);
  const dropDownHeaderRef = useRef<any>(null);

  function onClick(event: MouseEvent) {
    if (
      !dropDownHeaderRef.current?.contains(event.target) &&
      !dropDownBodyRef.current?.contains(event.target)
    ) {
      setExpanded(false);
    }
  }

  useEffect(() => {
    window.addEventListener("mousedown", onClick);
    return () => {
      window.removeEventListener("mousedown", onClick);
    };
  }, []);

  useEffect(() => {
    if (props.onItemSelected) {
      props.onItemSelected(selectedItem);
    }
  }, [selectedItem]);

  useEffect(() => {
    const index = props.selectedIndex ?? 0;
    setSelectedItem(props.items[index > -1 ? index : 0]);
  }, [props.selectedIndex]);

  return (
    <DropDownStyle>
      <select
        className={`drop-down ${expanded ? " drop-down--expanded" : ""}`}
        defaultValue={
          props.selectedIndex &&
          props.items[props.selectedIndex > -1 ? props.selectedIndex : 0]?.key
        }
        onChange={(e) => {
          setSelectedItem(props.items[e.target.selectedIndex]);
        }}
      >
        <>
          {props.items.map((item, index) => (
            <option
              key={`${item.value ?? item}${index}`}
              data-index={index}
              className="drop-down__selection__value"
              tabIndex={0}
              value={item.key}
            >
              {item.value ?? item}
            </option>
          ))}
          )
        </>
      </select>
    </DropDownStyle>
  );
}
