import { AsyncComponent } from "../common/AsyncComponent";
import { useApiCall } from "../../hooks/useApiCall";
import { GeneralUrl } from "../../config";
import { useEffect, useState } from "react";
import { APP_VERSION } from "../../app-version";
import { ConfirmButton } from "../common/ConfirmButton";

export function AdminAppInfo() {
  const [, , error, get, post] = useApiCall({});
  const [generalInfo, setGenerealInfo] = useState<{} | null>(null);

  const getData = async () => {
    const clientVerion = APP_VERSION;
    const appVersion = await get(GeneralUrl + "/version");
    const uptime = await get(GeneralUrl + "/uptime");
    setGenerealInfo({
      clientVersion: clientVerion,
      appVersion: appVersion.data.version,
      uptime: uptime.data.uptime,
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <AsyncComponent data={generalInfo} isLoading={!generalInfo} error={error}>
      {(data: any) => {
        return (
          <section>
            <p>Wersja aplikacji: {data.appVersion}</p>
            <p>Wersja klienta web: {data.clientVersion}</p>
            <p>Czas pracy serwera: {data.uptime}</p>
            <hr />
            <ConfirmButton
              label="Zrestartuj aplikację na serwerze"
              confirmLabel="Kliknij aby zrestartować aplikację na serwerze"
              action={() => {
                post(GeneralUrl + "/restart", null);
                setGenerealInfo(null);
                setTimeout(() => getData(), 3000);
              }}
            ></ConfirmButton>
          </section>
        );
      }}
    </AsyncComponent>
  );
}
