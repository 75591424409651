import styled from "styled-components";
import { RecurringPromo } from "../../models/api/recurringPromo";
import { WeekDaySelector } from "../common/WeekDaySelector";

const ModifyPromoStyle = styled.section`
  white-space: nowrap;
  width: 100%;
  .time-selector {
    &:first-child {
      margin-right: 0.5rem;
    }
    input {
      margin-left: 0.5rem;
    }
  }
  .weekday-selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    gap: 0.5rem;
  }
`;

export interface IModifyPromoComponent {
  promo: RecurringPromo;
  onPromoModified: (promo: RecurringPromo) => void;
}

export function ModifyRecurringPromo(props: IModifyPromoComponent) {
  return (
    <ModifyPromoStyle>
      <div className="flex-center-justify">
        <div className="flex-center-justify time-selector">
          Od godziny:
          <input
            type={"time"}
            value={props.promo?.fromTime ?? "00:00"}
            onChange={(e) =>
              props.onPromoModified({
                ...props.promo,
                fromTime: e.target.value,
              })
            }
          ></input>
        </div>
        <div className="flex-center-justify time-selector">
          Do godziny:
          <input
            type={"time"}
            value={props.promo?.toTime ?? "00:00"}
            onChange={(e) =>
              props.onPromoModified({ ...props.promo, toTime: e.target.value })
            }
          ></input>
        </div>
      </div>
      <div className="flex-center-justify weekday-selector">
        Dni tygodnia:
        <WeekDaySelector
          selectedWeekDays={props.promo.daysOfWeek}
          onSelectedWeekDaysChanged={(e) =>
            props.onPromoModified({ ...props.promo, daysOfWeek: e })
          }
        ></WeekDaySelector>
      </div>
    </ModifyPromoStyle>
  );
}
