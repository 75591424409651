import styled from "styled-components";
import { useEffect, useState } from "react";
import { VariantModel } from "../../models/api/variant";
import { IngredientModel } from "../../models/api/ingredient";
import { NewIngridient } from "./NewProductCreatorIngridient";
import { UnitModel } from "../../models/api/unit";
import { Input } from "../common/Input";
import { ColorSelector } from "../common/ColorSelector";
import isEqual from "lodash.isequal";

export const NewVariantStyle = styled.section`
  border: var(--border-width) solid var(--col-bg-focus);
  margin: 0.2rem;
  .variant {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
  }
  .input-field {
    padding: 0.2rem;
    display: flex;
    flex-direction: column;
    .name {
      text-align: left;
    }
    .input {
    }
  }
  .variant-actions {
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
    display: flex;
    justify-content: space-between;
    padding: 0.2rem;
    gap: 0.1rem;
    &__add,
    &__copy-delete {
      display: flex;
      flex-wrap: wrap;
      gap: 0.1rem;
      justify-content: end;
    }
  }
  .ingridients {
    margin-left: 2rem;
    summary {
      text-align: left;
    }
  }
`;

export interface INewVariant {
  variant: VariantModel;
  ingridients: IngredientModel[];
  units: UnitModel[];
  onVariantChanged: (variant: VariantModel) => void;
  onVariantDeleted: (variant: VariantModel) => void;
  onVariantCopied: (variant: VariantModel) => void;
}

export function NewVariant(props: INewVariant) {
  const [variant, setVariant] = useState(props.variant);
  const [ingridients, setIngridients] = useState<IngredientModel[]>(
    variant.ingridients ?? []
  );

  useEffect(() => {
    !isEqual(props.variant, variant) && props.onVariantChanged(variant);
  }, [variant]);

  useEffect(() => {
    setVariant(props.variant);
  }, [props.variant]);

  useEffect(() => {
    setVariant((old) => {
      return {
        ...old,
        ingridients: ingridients,
      };
    });
  }, [ingridients]);

  return (
    <NewVariantStyle>
      <div className="variant" key={props.variant.id}>
        <div className="input-field ">
          <div className="name">Nazwa wariantu:</div>{" "}
          <Input
            className="input"
            value={props.variant.description ?? ""}
            onChange={(e) =>
              setVariant((old: VariantModel) => {
                return {
                  ...old,
                  description: String(e),
                };
              })
            }
          ></Input>
        </div>
        <div className="input-field">
          <div className="name">Cena:</div>{" "}
          <Input
            type="number"
            className="input"
            value={props.variant.price ?? ""}
            onChange={(e) =>
              setVariant((old: VariantModel) => {
                return {
                  ...old,
                  price: isNaN(Number(e)) ? undefined : Number(e),
                };
              })
            }
          ></Input>
        </div>
      </div>
      <div className="variant flex-center-justify flex-wrap">
        <div className="name">Kolor kafelka:</div>{" "}
        <ColorSelector
          selectedColor={variant.colorCode}
          onSelectedColorChanged={(value) =>
            setVariant((old: VariantModel) => {
              return {
                ...old,
                colorCode: value,
              };
            })
          }
        ></ColorSelector>
      </div>
      <div className="ingridients">
        <details>
          <summary>Składniki</summary>
          <div className="ingridients-container">
            {ingridients.map((ingridient, i) => (
              <div className="variant" key={i}>
                <NewIngridient
                  ingridient={ingridient}
                  units={props.units}
                  availableIngridients={props.ingridients}
                  onIngridientChanged={(_ig) =>
                    setIngridients((old) => {
                      old[i] = _ig;
                      return [...old];
                    })
                  }
                  onIngridientDeleted={(_ig) =>
                    setIngridients((old: any) => {
                      old[i] = null;
                      return old.filter((el: any) => el);
                    })
                  }
                ></NewIngridient>
              </div>
            ))}
          </div>
        </details>
      </div>
      <div className="variant-actions">
        <span className="variant-actions__add">
          <button
            onClick={() => {
              setIngridients((old: VariantModel[]) => {
                return [...old, {}];
              });
            }}
            className="btn--active"
          >
            + Dodaj składnik
          </button>
        </span>
        <span className="variant-actions__copy-delete">
          <button
            className="btn--active"
            onClick={() => {
              props.onVariantCopied(variant);
            }}
          >
            Kopiuj
          </button>
          <button
            className="btn--alert"
            onClick={() => {
              props.onVariantDeleted(variant);
            }}
          >
            Usuń
          </button>
        </span>
      </div>
    </NewVariantStyle>
  );
}
