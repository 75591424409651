import styled from "styled-components";
import { useApiCall } from "../../hooks/useApiCall";
import { InventoryUrl } from "../../config";
import { AsyncComponent } from "../common/AsyncComponent";
import { ProductModel } from "../../models/api/product";
import { Link } from "react-router-dom";
import { useEffect, useMemo } from "react";
import useDispatcherState from "../../hooks/useDispatcherState";
import { StoreKeysEnum } from "../../store/storeKeysEnum";
import { productsOrdered } from "../../helpers/productsOrdered";
import { ActionButton } from "../common/ActionButton";
import { Row } from "react-table";
import { ProductStateEnum } from "../../models/enums/productStateEnum";
import { Table } from "../common/Table";
import { VariantModel } from "../../models/api/variant";
import useGroup from "../../hooks/useGroup";

const ManageProductsStyle = styled.section`
  .manage {
    &__btn {
      a {
        text-decoration: none;
      }
    }
  }
  .expander {
    white-space: nowrap;
    display: flex;
    justify-content: left;
    .icon {
      margin-right: 0.5rem;
    }
  }
  .child-row {
    .variant {
      display: flex;
      padding: 0.5rem;
      width: 100%;
      justify-content: space-between;
      border-top: var(--border-width) solid var(--col-bg-secondary);
      &:first-child {
        border-top: unset;
      }
      &__price {
        padding-right: 0.5rem;
      }
      &__description {
      }
    }
    .variant-ingridients {
      display: flex;
      justify-content: space-between;
      &__ingridient {
        padding-left: 5%;
      }
      &__unit {
        display: flex;
        gap: 0.2rem;
        padding-right: 1rem;
        width: 50%;
        justify-content: right;
      }
      .unit {
        display: flex;
        &__amount {
        }
        &__description {
        }
        &__price {
          flex-basis: 10rem;
          justify-content: end;
        }
      }
    }
  }
`;

export function ManageProducts() {
  const [response, isLoading, error] = useApiCall<ProductModel[]>({
    url: InventoryUrl + "/product",
  });

  const [products, setProducts] = useDispatcherState<ProductModel[] | null>(
    StoreKeysEnum.Products
  );
  const { getGroup } = useGroup();

  const Variant = (variant: VariantModel) => {
    return (
      <div className="child-row">
        <div className="variant">
          <div className="variant__description">{variant.description}</div>
          <div className="variant__price">
            Cena: {variant.price + "zł"}, Koszt:{" "}
            {Number(variant.ingredientsTotalCost).toFixed(2) + "zł"}
          </div>
        </div>
        {variant.ingridients?.map((ingridient, i) => (
          <div className="variant-ingridients" key={i}>
            <div className="variant-ingridients__ingridient">
              {ingridient.description}
            </div>
            <div className="variant-ingridients__unit">
              <div className="unit unit__amount"> {ingridient.amount}</div>
              <div className="unit unit__descripiton">
                {ingridient.unit?.description}
              </div>
              <div className="unit unit__price">
                {Number(
                  Number(ingridient.unitPrice) * Number(ingridient.amount)
                ).toFixed(2) + "zł"}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Menu ID",
        accessor: "variants",
        id: "expander",
        Cell: (props: { value: VariantModel[]; row: any }) =>
          props.value && props.value.length > 0 ? (
            <span
              className="expander"
              {...props.row.getToggleRowExpandedProps({})}
            >
              <div className="icon">{props.row.isExpanded ? "▼" : "▶"}</div>
              <div>{props.row.original.displaySequence}</div>
            </span>
          ) : null,
        Child: (el: VariantModel) => Variant(el),
      },
      {
        Header: "Opis",
        accessor: "description",
      },
      {
        Header: "Grupa",
        accessor: "groupId",
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          const _first = getGroup(Number(rowA.values[id]))?.description ?? "";
          const _second = getGroup(Number(rowB.values[id]))?.description ?? "";
          return _first.localeCompare(_second);
        },
        Cell: (props: { value: number | null }) => {
          return (
            <div>{props.value ? getGroup(props.value)?.description : ""}</div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "state",
        Cell: (props: { value: ProductStateEnum }) => {
          return (
            <div>
              {props.value === ProductStateEnum.Active
                ? "Aktywny"
                : "Nieaktywny"}
            </div>
          );
        },
      },
      {
        id: "Action",
        Cell: (props: { value: string; row: Row }) => (
          <Link
            className="flex flex-right no-underline"
            to={`/inventory/product/modify?id=${
              (props.row.original as any).id
            }`}
          >
            <button className="btn--active">Edytuj</button>
          </Link>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    if (response) {
      setProducts(productsOrdered(response));
    }
  }, [response]);

  return (
    <ManageProductsStyle>
      <AsyncComponent data={products} isLoading={isLoading} error={error}>
        {(products: ProductModel[]) => {
          return (
            <Table
              columns={columns}
              data={products}
              getSubRows={(data: any) => data.variants}
            ></Table>
          );
        }}
      </AsyncComponent>
      <ActionButton label="+ Dodaj nowy produkt">
        <Link to="/inventory/product/create">+ Dodaj nowy produkt</Link>
      </ActionButton>
    </ManageProductsStyle>
  );
}
