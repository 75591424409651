import { useEffect, useState } from "react";
import styled from "styled-components";
import { AsyncComponent } from "../components/common/AsyncComponent";
import { GenericView } from "../components/common/GenericView";
import { AvailablePromotionsComponent } from "../components/feature/AvailablePromotions";
import { PromotionsUrl } from "../config";
import { useApiCall } from "../hooks/useApiCall";
import useDispatcherState from "../hooks/useDispatcherState";
import useProduct from "../hooks/useProduct";
import useVariant from "../hooks/useVariant";
import { AvailablePromosModel } from "../models/api/availablePromos";
import { BasePromo } from "../models/api/basePromo";
import { OrderModel } from "../models/api/order";
import { ActiveOrderModel } from "../models/api/activeOrderModel";
import { ProductModel } from "../models/api/product";
import { SelectVariantView } from "./SelectVariantView";

const PromotionsStyle = styled.div``;

export function CurrentPromotionsView() {
  const [response, isLoading, error] = useApiCall<AvailablePromosModel>({
    url: PromotionsUrl + "/available",
  });
  const [promos, setPromos] = useState<BasePromo[]>();
  const [variantView, setVariantView] = useState<any>();
  const [order, setOrder] = useDispatcherState<ActiveOrderModel>("order");
  const { getProduct } = useProduct();
  const { getVariant } = useVariant();

  function onProductAddedToOrder(order: OrderModel) {
    const { product, variant, sides, remarks } = order;
    setOrder((old) => {
      const order = old?.orders
        ? [...old.orders, { product, variant, sides, remarks }]
        : [{ product, variant, sides, remarks }];
      return { orders: order };
    });
    setVariantView(null);
  }

  function onProductSelected(product: ProductModel, variantId?: number) {
    setVariantView(
      <GenericView
        viewTitle={product.description || ""}
        onClose={() => setVariantView(null)}
      >
        <SelectVariantView
          onProductAddedToOrder={onProductAddedToOrder}
          product={product}
          variantId={variantId}
        ></SelectVariantView>
      </GenericView>
    );
  }

  function onCreateOrderRequest(promo: BasePromo) {
    const _product = () => {
      return promo.variantId
        ? getProduct(getVariant(promo.variantId)?.productId)
        : getProduct(promo.productId);
    };
    _product && onProductSelected(_product()!, promo.variantId);
  }

  useEffect(() => {
    const _promos: BasePromo[] = [];
    response?.recurringPromos.forEach((promo) => _promos.push(promo));
    response?.timeConstrainedPromos.forEach((promo) => _promos.push(promo));
    setPromos(_promos);
  }, [response]);

  return (
    <PromotionsStyle>
      <AsyncComponent data={promos} isLoading={isLoading} error={error}>
        {(promos: BasePromo[]) => {
          return (
            <AvailablePromotionsComponent
              promos={promos}
              onCreateOrderRequest={onCreateOrderRequest}
            />
          );
        }}
      </AsyncComponent>
      {variantView}
    </PromotionsStyle>
  );
}
