import { IDispatcher, IState, IWatchable } from "./dispatcher-interfaces";

export class State implements IState {
  private states = {} as any;
  private dispatcher: IDispatcher;
  private pendingKey: string = "$$$pending";

  constructor(dispatcher: IDispatcher) {
    this.dispatcher = dispatcher;
  }

  // key - object name ;
  public getState = (key: string, fallback?: (args?: any) => Promise<any>) => {
    const value = this.states[key];
    if (value === undefined && fallback) {
      this.states[key] = this.pendingKey;
      fallback().then((data) => {
        this.saveState(key, data);
      });
    }
    return value === this.pendingKey ? undefined : value;
  };

  // key - object name ; value - object; can be used also to initialize, can override existing state
  public saveState = (key: string, value: unknown) => {
    const previousValue = this.states[key];
    const currentValue = value;
    this.states[key] = value;
    this.invokeCallback(key, previousValue, currentValue);
    return this.states[key];
  };

  // key - object name ; value - object; is not overriding existing state
  public initState = (key: string, value: unknown) => {
    if (this.states[key] === undefined) {
      this.states[key] = value || null;
    }
    return this.states[key];
  };

  private invokeCallback = (
    key: string,
    previous: unknown,
    current: unknown
  ) => {
    const disaptched: IWatchable<unknown> = {
      previousValue: previous,
      currentValue: current,
    };
    this.dispatcher.dispatch(key, disaptched);
  };
}
