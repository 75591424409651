import { IEvent } from "./dispatcher-interfaces";
export class DispatcherEvent implements IEvent {
    private eventId: string;
    private callbacks: Array<{ callback: ((data: unknown) => void) | undefined, id: string }>;

    constructor(eventId: string) {
        this.eventId = eventId;
        this.callbacks = [];
    }

    public registerCallback = (id: string, callback?: (data: unknown) => unknown,) => {
        this.callbacks.push({ callback, id });
    }

    public unregisterCallback = (id: string) => {
        const index = this.callbacks.findIndex(element => element.id === id);
        if (index > -1) {
            this.callbacks.splice(index, 1);
        }
    }

    public fire = (data: unknown) => {
        const callbacks = this.callbacks.slice(0);
        callbacks.forEach((registered) => {
            try {
                registered.callback?.(data);
            } catch (err) {
                const errorObject = {
                    message: "Error while executing callback.",
                    registered,
                    data,
                    err,
                };
                console.error(errorObject);
            }
        });
    }
}
