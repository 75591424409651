import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { SalesHeatMapReportModel } from "../../models/api/heatMapModel";

const HeatMapStyle = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  .heat-map-tooltip {
    position: absolute;
    background-color: var(--col-bg-secondary);
    width: fit-content;
    height: fit-content;
    &__value {
      vertical-align: middle;
      font-size: 0.7rem;
      padding: 0.5rem;
    }
    visibility: hidden;
    .visible {
      visibility: visible;
    }
    &::before {
      position: absolute;
      content: "";
      transform: translate(-8px, 3px);
      rotate: -45deg;
      height: 0.5rem;
      width: 0.5rem;
      background-color: var(--col-bg-secondary);
    }
  }

  .heat-map-legend {
    display: flex;
    &__bar {
      display: flex;
    }
    #heat-map-range-bar {
      accent-color: var(--col-fg-active);
      appearance: slider-vertical;
      width: 1.4rem;
      height: 100%;
    }
    &__values {
      font-size: 0.8rem;
      margin-left: 0.1rem;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .min-value {
      }
      .max-value {
      }
    }
    &__track {
      width: 0.5rem;
    }
  }
  .heat-map-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 0.05rem;
    ${(props) => {
      return `
        grid-row: 1/${props.theme.rows};
        grid-column: 1/${props.theme.columns} 
        `;
    }};
    &__row {
      border-radius: unset;
      width: 100%;
      height: 2rem;
      /* max-width: calc(100% - ${(props) => props.theme.columns}rem); */
      &__label {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        width: min-content;
        margin-right: 0.5rem;
      }
    }
  }
`;

// const heatMapData: IHeatMap<SalesByHour> = {
//   rows: props.hours
//     .sort((a, b) => {
//       return Number(a) - Number(b);
//     })
//     .map((e) => String(e)),
//   data: props.heatMapData,
//   minValue: props.minCashValue,
//   maxValue: props.maxCashValue,
// };

export interface IHeatMap {
  data: SalesHeatMapReportModel;
  showCounts: boolean;
}

export function HeatMap(props: IHeatMap) {
  const maxValue = props.showCounts
    ? props.data.maxOrdersCount
    : props.data.maxCashValue;
  const minValue = props.showCounts
    ? props.data.minOrdersCount
    : props.data.minCashValue;
  const tooltipRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<HTMLDivElement>(null);

  const columnLabels = props.data.dates;
  const rowLabels = props.data.hours;
  const data = props.data.heatMapData.map((e) => e);

  const [sliderValue, setSliderValue] = useState(minValue);
  const [range, setRange] = useState(
    Math.abs(sliderValue) + Math.abs(maxValue)
  );
  const [ratio, setRatio] = useState(100 / range);
  const hMax = 360; //hsla(360, 50%, 50%, 100%) - high range

  function getColor(cellValue: number, hRatio: number) {
    return `hsl(${hMax}, ${Math.round(cellValue * hRatio)}%, 50%)`;
  }

  function onNodeClick(e: React.MouseEvent, value: string) {
    if (tooltipRef.current) {
      tooltipRef.current?.classList.contains("visible")
        ? tooltipRef.current?.classList.remove("visible")
        : tooltipRef.current?.classList.add("visible");
      tooltipRef.current.style.top = e.clientY + "px";
      tooltipRef.current.style.left = e.clientX + "px";

      const target = tooltipRef.current.firstChild;
      if (target) target.textContent = value;
    }
  }

  function onLabelClick(e: React.MouseEvent) {
    e.preventDefault();
    if (tooltipRef.current) {
      tooltipRef.current?.classList.remove("visible");
    }
  }

  function onSliderSlide(e: React.ChangeEvent<HTMLInputElement>) {
    setSliderValue(Number(e.target.value));
  }

  useEffect(() => {
    // Firefox fix for vertical alignment
    const el = document.getElementById("heat-map-range-bar");
    el?.setAttribute("orient", "vertical");
  }, []);

  useEffect(() => {
    const range = Math.abs(Math.abs(maxValue) - Math.abs(sliderValue));
    setRange(range);
    setRatio(100 / range);
  }, [sliderValue]);

  return (
    <HeatMapStyle
      theme={{ columns: columnLabels.length, rows: rowLabels.length }}
    >
      <div className="heat-map-wrapper">
        <>
          {rowLabels.map((el, i) => {
            //rows
            return (
              <div
                key={"label" + i}
                className="heat-map-wrapper__row heat-map-wrapper__row__label"
                style={{
                  gridColumn: 1,
                  gridRow: 1 + i,
                }}
              >
                {el}
              </div>
            );
          })}
          {data.map((el, i) => {
            //data
            return (
              <>
                {el.value.hourCashList.map((e, listindex) => {
                  return (
                    <div
                      key={i + "value" + listindex}
                      className="heat-map-wrapper__row heat-map-wrapper__row__value"
                      onClick={(u) =>
                        onNodeClick(
                          u,
                          `${
                            el.key +
                            "  -> " +
                            String(
                              props.showCounts ? e.ordersCount : e.cashAmount
                            )
                          }`
                        )
                      }
                      title={
                        el.key +
                        " → " +
                        String(props.showCounts ? e.ordersCount : e.cashAmount)
                      }
                      style={{
                        border: "none",
                        gridColumn:
                          2 + columnLabels.findIndex((e) => e === el.key),
                        gridRow: 1 + listindex,
                        background: getColor(
                          props.showCounts ? e.ordersCount : e.cashAmount,
                          ratio
                        ),
                      }}
                    ></div>
                  );
                })}
              </>
            );
          })}
        </>
      </div>
      <div className="heat-map-legend">
        <div className="heat-map-legend__bar" ref={sliderRef}>
          <input
            type="range"
            id="heat-map-range-bar"
            name="range"
            onChange={onSliderSlide}
            value={sliderValue}
            min={minValue}
            max={maxValue}
          ></input>
          <span
            className="heat-map-legend__track"
            style={{
              background: `linear-gradient(180deg, ${getColor(
                minValue,
                ratio
              )}, ${getColor(maxValue, ratio)}`,
            }}
          ></span>

          <span className="heat-map-legend__values">
            <div className="heat-map-legend__values min-value">{minValue}</div>
            <div className="heat-map-legend__values max-value">
              {maxValue - sliderValue}
            </div>
          </span>
        </div>
      </div>
      <div className="heat-map-tooltip" ref={tooltipRef} onClick={onLabelClick}>
        <span className="heat-map-tooltip__value"></span>
      </div>
    </HeatMapStyle>
  );
}
