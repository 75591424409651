import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { AsyncComponent } from "../components/common/AsyncComponent";
import { ModifyProduct } from "../components/feature/ModifyProduct";
import { InventoryUrl } from "../config";
import { useApiCall } from "../hooks/useApiCall";
import { IngredientModel } from "../models/api/ingredient";
import { ProductModel } from "../models/api/product";
import { UnitModel } from "../models/api/unit";
import { ActionEnum } from "../models/enums/actionEnum";
import useVariant from "../hooks/useVariant";
import isEqual from "lodash.isequal";
import useProduct from "../hooks/useProduct";

const InventoryModifyStyle = styled.div`
  h4,
  h5,
  h6 {
    text-align: left;
    margin-top: 1rem;
  }
`;

export function InventoryProductModifyView() {
  const [, , error, get, post] = useApiCall({});
  const params = new URLSearchParams(new URL(window.location.href).search);
  const [inventoryData, setInventoryData] = useState<{
    ingridients: IngredientModel[];
    units: UnitModel[];
    product: ProductModel;
  }>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { getVariant } = useVariant();
  const { getProduct } = useProduct();

  async function updateProduct(product: ProductModel) {
    setLoading(true);
    await post(InventoryUrl + "/product/modify", {
      Action: ActionEnum.Update,
      Item: { ...product, variants: undefined, sides: undefined },
    });
    if (product.variants) {
      for await (const variant of product.variants) {
        if (variant.id) {
          // update variant
          !isEqual(variant, getVariant(variant.id)) &&
            (await post(InventoryUrl + "/variant/modify", {
              Action: ActionEnum.Update,
              Item: {
                Id: variant.id,
                Description: variant.description,
                ProductId: product.id,
                Price: variant.price,
                ColorCode: variant.colorCode,
                Ingridients: variant.ingridients?.map((i) => {
                  return { Id: i.id, Amount: i.amount };
                }),
              },
            }));
        } else {
          // create new variant
          await post(InventoryUrl + "/variant/modify", {
            Action: ActionEnum.Create,
            Item: {
              Description: variant.description,
              ProductId: product.id,
              Price: variant.price,
              ColorCode: variant.colorCode,
              Ingridients: variant.ingridients?.map((i) => {
                return { Id: i.id, Amount: i.amount };
              }),
            },
          });
        }
      }
      // remove deleted variants
      const _oldVariants = getProduct(product.id ?? 0)?.variants;
      if (_oldVariants)
        for await (const variant of _oldVariants) {
          const _v = product.variants.find((e) => e.id === variant.id)?.id;
          if (!_v) {
            await post(InventoryUrl + "/variant/modify", {
              Action: ActionEnum.Delete,
              Item: { Id: variant.id },
            });
          }
        }
    }
    if (product.sides) {
      for await (const side of product.sides) {
        if (side.id) {
          // update side
          !isEqual(
            side,
            getProduct(product.id ?? 0)?.sides?.find((e) => e.id === side.id)
          ) &&
            (await post(InventoryUrl + "/side/modify", {
              Action: ActionEnum.Update,
              Item: {
                Id: side.id,
                Description: side.description,
                ProductId: product.id,
                Price: side.price,
                Ingredients: side.ingredients?.map((i) => {
                  return { Id: i.id, Amount: i.amount };
                }),
              },
            }));
        } else {
          // create new side
          await post(InventoryUrl + "/side/modify", {
            Action: ActionEnum.Create,
            Item: {
              Description: side.description,
              ProductId: product.id,
              Price: side.price,
              Ingredients: side.ingredients?.map((i) => {
                return { Id: i.id, Amount: i.amount };
              }),
            },
          });
        }
      }
      // remove deleted sides
      const _oldSides = getProduct(product.id ?? 0)?.sides;
      if (_oldSides)
        for await (const side of _oldSides) {
          const _v = product.sides.find((e) => e.id === side.id)?.id;
          if (!_v) {
            await post(InventoryUrl + "/side/modify", {
              Action: ActionEnum.Delete,
              Item: { Id: side.id },
            });
          }
        }
    }
    setLoading(false);
    navigate("/inventory");
  }

  useEffect(() => {
    const fetchData = async () => {
      const _ingridients = await get(InventoryUrl + "/ingridient");
      const _units = await get(InventoryUrl + "/unit");
      const _product = await get(
        InventoryUrl + "/product?id=" + params.get("id")
      );
      const ingridients = _ingridients.data.map((el: any) => {
        return { ...el, unit: { id: el.fkUnitId } };
      });
      const units = _units.data;
      const product = _product.data[0];
      setInventoryData({
        ingridients: ingridients,
        units: units,
        product: product,
      });
    };
    fetchData();
  }, []);

  return (
    <InventoryModifyStyle>
      <AsyncComponent
        data={inventoryData}
        isLoading={loading || !inventoryData}
        error={error}
      >
        {(props: {
          ingridients: IngredientModel[];
          units: UnitModel[];
          product: ProductModel;
        }) => {
          return (
            <>
              <div className="product-editor">
                <h4>Modyfikuj produkt</h4>
              </div>
              <ModifyProduct
                units={props.units}
                ingredients={props.ingridients}
                product={props.product}
                onProductModified={(product: ProductModel) =>
                  updateProduct(product)
                }
              ></ModifyProduct>
            </>
          );
        }}
      </AsyncComponent>
    </InventoryModifyStyle>
  );
}
