import { useState, useEffect } from "react";
import styled from "styled-components";

const sliderHeight = "2rem";
const sliderWidth = "3.8rem";
const sliderDiameter = "2rem";
const TogglerStyle = styled.div`
  .read-only {
    opacity: 0.5;
    pointer-events: none;
  }
  .toggler-container {
    border: var(--border-width) solid transparent;
    &:focus {
      outline: none;
      border: var(--border-width) solid var(--col-fg-active);
    }
    .playpause {
      border: 0.4rem solid var(--col-bg-primary);
      background-color: var(--col-bg-primary);
      width: 4rem;
      height: 4rem;
      border-radius: 4rem;
      margin: auto;
      label {
        margin: 1rem 1rem 1rem 1.2rem;
        display: block;
        box-sizing: border-box;
        width: 0;
        height: 2rem;
        border-color: transparent transparent transparent var(--col-fg-active);
        transition: 100ms all ease;
        cursor: pointer;
        border-style: solid;
        border-width: 1rem 0 1rem 2rem;
      }
      input[type="checkbox"] {
        display: none;
        &:checked + label {
          margin-left: 1rem;
          border-style: solid;
          border-color: transparent transparent transparent var(--col-fg-alert);
          border-width: 0 0 0 2rem;
        }
      }
    }
    .slide-toggle {
      &__switch {
        position: relative;
        display: inline-block;
        width: var(--slider-width, ${sliderWidth});
        height: var(--slider-height, ${sliderHeight});
        &__input {
          opacity: 0;
          width: 0;
          height: 0;
          &:checked {
            + .slide-toggle__switch__slider {
              background-color: var(--col-fg-secondary);
              &:before {
                transform: translateX(
                  calc(
                    var(--slider-width, ${sliderWidth}) -
                      var(--slider-diameter, ${sliderDiameter})
                  )
                );
                border-color: var(--col-fg-secondary);
                background-color: var(--col-fg-active);
              }
            }
          }
        }
        &__slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: var(--col-fg-secondary);
          transition: 0.4s;
          &:before {
            box-sizing: border-box;
            position: absolute;
            content: "";
            top: calc(
              (
                  var(--slider-height, ${sliderHeight}) -
                    var(--slider-diameter, ${sliderDiameter})
                ) / 2
            );
            left: 0;
            height: var(--slider-diameter, ${sliderDiameter});
            width: var(--slider-diameter, ${sliderDiameter});
            background-color: #d8e3ea;
            transition: 0.4s;
            border: 0.1rem solid var(--col-fg-secondary);
          }
          &--round {
            border-radius: ${sliderHeight};
            &:before {
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
`;
export enum Style {
  slider = "Slider",
  playStop = "PlayStop",
}

export interface IToggler {
  toggled?: boolean;
  onToggleChanged?: (toggled: boolean) => void;
  style?: Style;
  readOnly?: boolean;
}

export function Toggler(props: IToggler) {
  const [isToggled, setIsToggled] = useState(props.toggled ?? false);

  useEffect(() => {
    if (props.onToggleChanged) {
      props.onToggleChanged(isToggled);
    }
  }, [isToggled]);

  useEffect(() => {
    setIsToggled(props.toggled ?? false);
  }, [props.toggled]);

  return (
    <TogglerStyle>
      <div
        className={`${
          props.readOnly ? "toggler-container read-only" : "toggler-container"
        }`}
        tabIndex={0}
        onKeyDown={(e) =>
          e.key === "Enter" && !props.readOnly && setIsToggled(!isToggled)
        }
      >
        {props.style === Style.playStop ? (
          <div className="playpause">
            <input
              type="checkbox"
              value="None"
              id="playpause"
              name="check"
              onChange={() => {
                if (!props.readOnly) setIsToggled(!isToggled);
              }}
              checked={isToggled}
            />
            <label htmlFor="playpause"></label>
          </div>
        ) : (
          <div className="slide-toggle">
            <label className="slide-toggle__switch">
              <input
                className="slide-toggle__switch__input"
                type="checkbox"
                onChange={() => {
                  if (!props.readOnly) setIsToggled(!isToggled);
                }}
                checked={isToggled}
              ></input>
              <span className="slide-toggle__switch__slider slide-toggle__switch__slider--round"></span>
            </label>
          </div>
        )}
      </div>
    </TogglerStyle>
  );
}
