import { useApiCall } from "../../hooks/useApiCall";
import { OrdersUrl } from "../../config";
import { AsyncComponent } from "../common/AsyncComponent";
import { TableCellEditButton } from "../common/TableCellEditButton";
import { Input } from "../common/Input";
import { useEffect, useMemo, useState } from "react";
import useEditableTable from "../../hooks/useEditableTable";
import { Table } from "../common/Table";
import useDispatcherState from "../../hooks/useDispatcherState";
import { StoreKeysEnum } from "../../store/storeKeysEnum";
import { ActionButton } from "../common/ActionButton";
import { LongPress } from "../common/hocs/LongPress";
import { OptionModel } from "../../models/api/optionModel";
import { ModifiedInventoryModel } from "../../models/api/modifiedInventory";
import { ConfirmButton } from "../common/ConfirmButton";
import { ActionEnum } from "../../models/enums/actionEnum";

export function ManageOrderOptions() {
  const [response, isLoading, error, get, post, refresh] = useApiCall<
    OptionModel[]
  >({
    url: OrdersUrl + "/getAvailableOptions",
    setResponseOnPost: false,
  });
  const [options, setOptions] = useDispatcherState<OptionModel[] | null>(
    StoreKeysEnum.AvailableOrderOptions
  );
  const [currentOptions, setCurrentOptions] = useState<OptionModel[]>(
    options ?? []
  );
  const { updateEntry, abortUnfocusedEdits, abortAllEdits } =
    useEditableTable();

  const applyChanges = async (option: ModifiedInventoryModel<OptionModel>) => {
    if (option.action === ActionEnum.Delete && !option.item.id) {
      refresh();
      return;
    }
    post(OrdersUrl + "/option/modify", option).then((val: any) => {
      refresh();
    });
  };

  function addNewItem() {
    setCurrentOptions((old) => {
      const _old = old ? [...old] : [];
      _old.push({
        value: "Nowa opcja do zamówienia",
      });
      return _old;
    });
  }

  useEffect(() => {
    if (response) {
      setOptions(response);
      setCurrentOptions(response);
    }
  }, [response]);

  const columns = useMemo(
    () => [
      {
        Header: "Wartość",
        accessor: "value",
        Cell: (props: { value: string; row: any }) => {
          return (
            <div style={{ width: "15rem" }}>
              {props.row.state.isEditEnabled ? (
                <Input
                  defaultValue={props.value}
                  onChange={(e) => {
                    props.row.state.value = e;
                  }}
                ></Input>
              ) : (
                props.value
              )}
            </div>
          );
        },
      },
      {
        id: "Action",
        accessor: "id",
        Cell: (props: { value: number; row: any; preparedRows: any }) => {
          return (
            <LongPress onLongPress={() => abortAllEdits(props.preparedRows)}>
              {props.row.state.isEditEnabled ? (
                <div className="flex flex-right gap">
                  <ConfirmButton
                    className="action flex flex-center-justify btn--active"
                    confirmClassName="action flex flex-center-justify btn--alert"
                    label="Usuń"
                    confirmLabel="Usunąć?"
                    action={() =>
                      applyChanges({
                        action: ActionEnum.Delete,
                        item: { id: props.value },
                      })
                    }
                  ></ConfirmButton>
                  <TableCellEditButton
                    row={props.row}
                    onEntryUpdated={(row) => applyChanges(updateEntry(row))}
                  ></TableCellEditButton>
                </div>
              ) : (
                <TableCellEditButton
                  row={props.row}
                  onEntryUpdated={(row) => applyChanges(updateEntry(row))}
                ></TableCellEditButton>
              )}
            </LongPress>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <AsyncComponent data={currentOptions} isLoading={isLoading} error={error}>
        {(options: OptionModel[]) => {
          return (
            <Table
              columns={columns}
              data={options}
              onRowClick={abortUnfocusedEdits}
            ></Table>
          );
        }}
      </AsyncComponent>
      <ActionButton
        label=" + Dodaj nową opcję"
        onClick={() => addNewItem()}
      ></ActionButton>
    </>
  );
}
