import styled from "styled-components";
import { ActionButton } from "../common/ActionButton";
import { useState } from "react";
import { ActiveOrderModel } from "../../models/api/activeOrderModel";
import { OrderStateEnum } from "../../models/enums/orderStateEnum";
import useDispatcherState from "../../hooks/useDispatcherState";
import { StoreKeysEnum } from "../../store/storeKeysEnum";
import { OptionModel } from "../../models/api/optionModel";
import { OrderOptions } from "./OrderOptions";

const CreateOrderStyle = styled.section`
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
  .order-edits {
  }
  .order-inputs {
    .input {
      margin-top: 0.5rem;
      .label {
        margin-left: 0.5rem;
      }
    }
    .order-dates {
      margin-bottom: 2rem;
    }
    .order-selects {
      margin-bottom: 2rem;
      text-align: left;
      &__header {
        font-size: inherit;
        margin-bottom: 1rem;
      }
    }
    .remarks {
      margin-bottom: 2rem;
    }
    .date-wrapper,
    #order-remarks {
      width: 15rem;
    }
  }
`;

export interface IOrderOptionsContent {
  order: ActiveOrderModel;
  onOrderModified: (order: ActiveOrderModel) => void;
}

export const OrderOptionsContent = (props: IOrderOptionsContent) => {
  const [modifiedOrder, setModifiedOrder] = useState(props.order);
  const initialState =
    props.order.expectedStartingDate ?? props.order.expectedCompletionDate
      ? "custom"
      : props!.order!.orders![0].state === OrderStateEnum.Awaiting
      ? "custom"
      : "instant";

  const [selected, setSelected] = useState(initialState);
  const [availableOptions] = useDispatcherState<OptionModel[]>(
    StoreKeysEnum.AvailableOrderOptions
  );

  const handleSelection = (e: any) => {
    setSelected(e.target.value);
    if (e.target.value === "instant") {
      setModifiedOrder((old) => {
        return {
          ...old,
          orders: old!.orders!.map((e) => {
            return { ...e, state: OrderStateEnum.InProgress };
          }),
          expectedStartingDate: undefined,
          expectedCompletionDate: undefined,
        };
      });
    } else {
      setModifiedOrder((old) => {
        return {
          ...old,
          orders: old!.orders!.map((e) => {
            return { ...e, state: OrderStateEnum.Awaiting };
          }),
        };
      });
    }
  };
  return (
    <CreateOrderStyle>
      <section className="order-edits">
        <section className="order-inputs">
          <fieldset className="order-selects">
            <legend className="order-selects__header">
              Czas realizacji zamówienia:
            </legend>
            <div className="input">
              <input
                name="choose"
                type="radio"
                id="instant"
                value="instant"
                checked={selected === "instant"}
                onChange={handleSelection}
              />
              <label className="label" htmlFor="instant">
                Natychmiast
              </label>
            </div>
            <div className="input">
              <input
                name="choose"
                type="radio"
                id="custom"
                value="custom"
                checked={selected === "custom"}
                onChange={handleSelection}
              />
              <label className="label" htmlFor="custom">
                Własne
              </label>
            </div>
          </fieldset>
          <OrderOptions
            order={modifiedOrder}
            onOrderModified={(order) => setModifiedOrder(order)}
            showDatePickers={selected === "custom"}
            showAttachmentPicker={true}
          ></OrderOptions>
        </section>
      </section>
      <div className="order__actions flex-center-justify">
        <div></div>
        <ActionButton
          label="Zatwierdź zmiany"
          fitToContent={true}
          sticky={false}
          onClick={() => {
            props.onOrderModified(modifiedOrder);
          }}
        ></ActionButton>
      </div>
    </CreateOrderStyle>
  );
};
