import { useRef, useState, useEffect, memo } from "react";
import styled from "styled-components";

const CheckboxFilterStyle = styled.div`
  width: 100%;
  .checkbox-filter {
    &__option {
      width: 100%;
      display: flex;
      flex-direction: row;
      &--hidden {
        display: none;
      }
      .value {
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 0.1rem;
        cursor: pointer;
        &__label {
        }
        &__checkmark {
          margin-right: 1rem;
        }
        &--selected {
          color: var(--col-fg-active);
        }
      }
    }
  }
`;

export interface ICheckboxFilter {
  items: { key: any; value: any; selected?: boolean }[];
  onSelectionChanged?: (
    selection: { key: any; value: any; selected?: boolean }[]
  ) => void;
  multiSelectMode?: boolean;
}

const CheckboxFilter = (props: ICheckboxFilter) => {
  const { items, multiSelectMode = false, onSelectionChanged } = props;
  const [mutableItems, setMutableItems] = useState(items ? [...items] : []);
  const filterRef = useRef<HTMLDivElement>(null);

  function handleSelection(
    item: { key: any; value: any; selected?: boolean },
    node: any
  ) {
    setMutableItems((old) => {
      const _old = [...old];
      const index = _old.findIndex((e) => e.key === item.key);
      if (index > -1) _old[index] = { ...item, selected: !item.selected };
      if (!multiSelectMode) {
        _old.forEach((item: any) => {
          item.selected = false;
        });
      }
      if (index > -1) _old[index] = { ...item, selected: !item.selected };
      return _old;
    });
    const checkboxes =
      filterRef.current?.getElementsByClassName("value__checkmark");
    const collection = Array.from(checkboxes ?? []);

    if (!multiSelectMode) {
      collection.forEach((item: any) => {
        if (item !== node) item.checked = false;
      });
    }
    collection.forEach((item: Element) => {
      if ((item as HTMLInputElement).checked) {
        item.parentElement?.classList.add("value--selected");
      } else {
        item.parentElement?.classList.remove("value--selected");
      }
    });

    const _checked = collection
      .filter((item: any) => item.checked === true)
      .map((el: any) => props.items[Number(el.dataset.index)]);

    onSelectionChanged && onSelectionChanged(_checked);
  }

  useEffect(() => {
    props.items && setMutableItems([...props.items]);
  }, [props.items]);
  return (
    <CheckboxFilterStyle>
      <div className="checkbox-filter" ref={filterRef}>
        {mutableItems?.map((el: any, index: number) => (
          <div className="checkbox-filter__option" key={el.key}>
            <label className="value">
              <span className="value__label">{el.value}</span>
              <input
                className="value__checkmark"
                type="checkbox"
                data-index={index}
                checked={el.selected ?? false}
                onChange={(e) => {
                  handleSelection(el, e.target);
                }}
              />
            </label>
          </div>
        ))}
      </div>
    </CheckboxFilterStyle>
  );
};

export default memo(CheckboxFilter);
