import { useState, useEffect, useContext } from "react";
import useMounted from "./useMounted";
import { HistoryContext } from "..";
import { Action } from "history";

export default function useBackButton() {
  const mounted = useMounted();
  const [backPressed, setBackPressed] = useState(false);
  const history = useContext(HistoryContext);
  const href = window.location.pathname;

  useEffect(() => {
    return history.listen(() => {
      if (history.action === Action.Pop) {
        history.push(href);
        setBackPressed(true);
      }
    });
  }, [mounted]);

  return backPressed;
}
