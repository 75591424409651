import { useState, useEffect } from "react";
import styled from "styled-components";
import * as mixin from "../../styles/mixins";

const LoaderStyle = styled.div`

${mixin.vBackdrop}

.hidden {
  display: none;
}
.spinner-container {
  box-sizing: border-box;
}
.lds-dual-ring {
  display: inline-block;
  width: 4rem;
  height: 4rem;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 4rem;
  border-radius: 50%;
  border: 0.3rem solid;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 0.8s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
    border-color: var(--col-fg-primary) transparent var(--col-fg-primary) transparent;
  }
  50% {
    transform: rotate(180deg);
    border-color:  var(--col-fg-alert) transparent  var(--col-fg-alert) transparent;
  }
  100% {
    transform: rotate(360deg);
    border-color:  var(--col-fg-primary) transparent  var(--col-fg-primary) transparent;
  }
}
.local {
  .loader-spinner {
    z-index: unset;
  }
  .backdrop {
    display:none;
  }
}
.loader-spinner {
  display: flex;
  position: absolute;
  z-index: 90;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 40%;
}
.progress {
  display:block;
  padding-top: 1.4rem;
  padding-left: 0.1rem;
  color: var(--col-fg-primary);
  &::after{
    font-size: 0.5rem;
    vertical-align: middle;
    content: '%';
  }
}
`
export interface ILoader {
  loaderVisible?: boolean;
  progress?: number;
  attachLocally?: boolean;
}

export function Loader(props: ILoader) {

  const [loaderVisible, setLoaderVisible] = useState(props.loaderVisible);
  const [progress, setProgress] = useState<number | null>(null);


  useEffect(() => {
    props.loaderVisible ? setLoaderVisible(true) : setLoaderVisible(false);
    props.progress ? setProgress(props.progress) : setProgress(null);

  }, [props]);

  return (
    <LoaderStyle>
      <div className={`spinner-container ${loaderVisible ? "visible" : "hidden"}  ${props.attachLocally ? "local" : ""}`}>
        {progress ? <div className={`progress loader-spinner ${progress ? "visible" : "hidden"}`}>{Math.floor(progress)}</div> : null}
        <div className="backdrop" />
        <div className="loader-spinner lds-dual-ring"></div>
      </div>
    </LoaderStyle>
  );
}
