import { useState } from "react";
import styled from "styled-components";
import { useEffect } from "react";
import { DayOfWeekEnum } from "../../models/enums/dayOfWeekEnum";

const WeekDaySelectorStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  .weekDay .btn {
    width: 3rem;
    margin: 0.2rem;
  }
`;
export interface IWeekDaySelector {
  onSelectedWeekDaysChanged: (weekDays: number[]) => void;
  selectedWeekDays: DayOfWeekEnum[];
}

export const daysOfWeek = [
  {
    key: DayOfWeekEnum.Monday,
    value: "Pn",
    fullName: "Poniedziałek",
  },
  {
    key: DayOfWeekEnum.Tuesday,
    value: "Wt",
    fullName: "Wtorek",
  },
  {
    key: DayOfWeekEnum.Wednesday,
    value: "Śr",
    fullName: "Środa",
  },
  {
    key: DayOfWeekEnum.Thursday,
    value: "Cz",
    fullName: "Czwartek",
  },
  {
    key: DayOfWeekEnum.Friday,
    value: "Pt",
    fullName: "Piątek",
  },
  {
    key: DayOfWeekEnum.Saturday,
    value: "So",
    fullName: "Sobota",
  },
  {
    key: DayOfWeekEnum.Sunday,
    value: "N",
    fullName: "Niedziela",
  },
];

export function WeekDaySelector(props: IWeekDaySelector) {
  const [selectedDays, setSelectedDays] = useState<DayOfWeekEnum[]>();

  function buildDays(days: number[]) {
    return daysOfWeek.map((e) => {
      return { ...e, selected: days.includes(e.key) };
    });
  }

  useEffect(() => {
    selectedDays && props.onSelectedWeekDaysChanged(selectedDays);
  }, [selectedDays]);

  useEffect(() => {
    setSelectedDays(props.selectedWeekDays);
  }, [props.selectedWeekDays]);

  return (
    <WeekDaySelectorStyle>
      {buildDays(selectedDays ?? []).map((day) => (
        <div className="weekDay" key={day.value}>
          <button
            className={day.selected ? "btn  btn--active" : "btn"}
            onClick={() =>
              setSelectedDays((old) => {
                let _selected = [...(old ?? [])];
                old?.includes(day.key)
                  ? (_selected = _selected.filter((e) => e !== day.key))
                  : _selected.push(day.key);
                return _selected;
              })
            }
          >
            {day.value}
          </button>
        </div>
      ))}
    </WeekDaySelectorStyle>
  );
}
