import { useMemo } from "react";
import { Table } from "../common/Table";
import { OrderModel } from "../../models/api/order";
import useProduct from "../../hooks/useProduct";
import useSide from "../../hooks/useSide";
import useVariant from "../../hooks/useVariant";

export interface IAwaitingOrdersChildTable {
  orders: OrderModel[];
  isEditEnabled: boolean;
  parentState: any;
  setParentState: any;
}

export function AwaitingOrdersChildTable(props: IAwaitingOrdersChildTable) {
  const { orders } = props;

  const { getProduct } = useProduct();
  const { getVariant } = useVariant();
  const { getSide } = useSide();

  const childColumns = useMemo(
    () => [
      {
        Header: "Produkt",
        accessor: "productId",
        Cell: (props: { value: number; row: any }) =>
          getProduct(props.value)?.description ?? null,
      },
      {
        Header: "Wariant",
        accessor: "variantId",
        Cell: (props: { value: number; row: any }) =>
          getVariant(props.value)?.description ?? null,
      },
      {
        Header: "Dodatki",
        accessor: "sideIds",
        Cell: (props: { value?: number[]; row: any }) =>
          props.value
            ? props.value.map((e) => getSide(e)?.description).join(", ")
            : "nie",
      },
      {
        Header: "Promocja",
        accessor: "promoId",
        Cell: (props: { value?: number; row: any }) =>
          props.value ? "tak" : "nie",
      },
      {
        Header: "Uwagi",
        accessor: "remarks",
        Cell: (props: { value: string; row: any }) => props.value,
      },
      {
        Header: "Cena",
        accessor: "price",
      },
    ],
    [orders]
  );

  return (
    <Table className="child-table" columns={childColumns} data={orders}></Table>
  );
}
