import { Dispatcher } from "../../services/dispatcher";
import { getDefaultFontSize } from "../../helpers/getDefaultFontSize";

export enum ColorScheme {
  Dark = "dark",
  Light = "light",
}

export class AppSettings {
  constructor(storedConfig: any) {
    this.preferredColorScheme = storedConfig?.preferredColorScheme;
    this.notificationsEnabled = storedConfig?.notificationsEnabled;
    this.appId = storedConfig?.appId || Dispatcher.UUID();
    this.baseFontSize =
      storedConfig?.baseFontSize || (getDefaultFontSize() + "px");
  }
  appId: string | undefined;
  preferredColorScheme: ColorScheme | undefined;
  notificationsEnabled: boolean | undefined;
  baseFontSize: string | undefined;
}
