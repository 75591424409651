export interface Credentials {
  username: string;
  password: string;
}

export interface User {
  id?: number;
  role?: Role;
  accessToken?: string;
  registerDate?: Date;
  lastLogInDate?: Date | null;
  status?: Status;
  firstName?: string;
  lastName?: string;
  hourlyRate?: number;
  authenticated: boolean;
  profileImageHref?: string;
}

export enum Role {
  User = "User",
  Admin = "Admin",
  Manager = "Manager",
  Employee = "Employee",
}

export enum Status {
  Activated = "Activated",
  Unactivated = "Unactivated",
}
