import { Loader } from "./Loader";
import { aFadeIn, aFadeOut, vVisible, vHidden } from "../../styles/mixins";
import styled from "styled-components";

const AsyncViewStyle = styled.div`
  ${aFadeIn}
  ${aFadeOut}
  ${vVisible}
  ${vHidden}
  .error {
    color: var(--col-fg-alert);
  }
  height: 100%;
  width: 100%;
  .visible {
    height: 100%;
    width: 100%;
  }
  @keyframes deferred {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  .defer {
    animation: deferred 1s linear;
  }
`;

export interface IAsyncComponent {
  children: (value: any) => React.ReactNode;
  data?: any;
  isLoading?: boolean;
  error?: any;
  errorLabel?: string;
}

export function AsyncComponent(props: IAsyncComponent) {
  return (
    <AsyncViewStyle>
      {props.data && !props.error && (
        <div className="visible">{props.children(props.data)}</div>
      )}
      {props.error && (
        <div className="error">{props.errorLabel ?? "Coś poszło nie tak"} </div>
      )}
      {props.isLoading && (
        <div className="defer">
          <Loader loaderVisible={true}></Loader>
        </div>
      )}
    </AsyncViewStyle>
  );
}
