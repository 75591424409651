import { User } from "../../models/api/user";
import styled from "styled-components";
import {
  formatDateToLocaleString,
  getDurationTextFromDate,
} from "../../helpers/formatter";
import { ActionButton } from "../common/ActionButton";
import { useEffect, useRef, useState } from "react";
import { GenericView } from "../common/GenericView";
import { ConfirmButton } from "../common/ConfirmButton";
import { CurrentUserChangePassword } from "./CurrentUserChangePassword";
import { Input } from "../common/Input";
import { CurrentUserAppSettings } from "./CurrentUserAppSettings";

const CurrentUserStyle = styled.section`
  --mb: 1.5rem;
  padding: 0.5rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  background-color: var(--col-bg-primary);
  h5 {
    margin-bottom: 2rem;
  }
  .info {
    display: flex;
    margin: 0.5rem;
  }
  .hero-edits {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: min(89.5vw, 30rem);
  }
  .hero {
    &__data {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      margin: 1rem;
      gap: 1rem;
      &--image {
        img {
          max-height: 10rem;
        }
      }
      &--info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
      }
      .date-time {
        margin-right: 0.5rem;
      }
    }
    &__actions {
      display: flex;
      flex-wrap: wrap;
      white-space: nowrap;
      gap: 0.5rem;
      .action {
        justify-content: right;
      }
      button {
        width: 100%;
        max-width: 30rem;
        border: 1px solid red;
      }
    }
    &__inputs {
      margin: 0.5rem;
      .current-password {
        margin-bottom: 3rem;
      }
    }
  }
`;

export interface ICurrentUser {
  user: User;
  onUserModified: (user: User) => void;
}

export function CurrentUser(props: ICurrentUser) {
  const user = props.user;
  const [view, setView] = useState<React.ReactNode>();
  const imageHref = new URL(window.location.href).origin + "/logo.png";
  const [updatedUser, setUpdatedUser] = useState(user);
  const mutatedUserData = useRef(user);

  function updateUser(_user: User) {
    props.onUserModified({ ..._user });
  }

  useEffect(() => {
    setUpdatedUser(user);
  }, [user]);

  useEffect(() => {
    mutatedUserData.current = updatedUser;
  }, [updatedUser]);

  const invokeModifyUserView = (user: User, viewClosed?: boolean) => {
    setView(
      <GenericView
        viewTitle={"Zmień swoje dane"}
        closed={viewClosed}
        onClose={() => setView(undefined)}
        fitToContent={true}
      >
        <section className="hero-edits">
          <section className="hero__inputs">
            <div className="form-field flex-center-justify">
              <label htmlFor="user_first-name">Imię:</label>
              <Input
                id="user_first-name"
                value={updatedUser.firstName}
                onChange={(e) =>
                  setUpdatedUser((old) => {
                    return {
                      ...old,
                      firstName: String(e),
                    };
                  })
                }
              ></Input>
            </div>
            <div className="form-field flex-center-justify">
              <label htmlFor="user_last-name">Nazwisko:</label>
              <Input
                id="user_last-name"
                value={updatedUser.lastName}
                onChange={(e) =>
                  setUpdatedUser((old) => {
                    return {
                      ...old,
                      lastName: String(e),
                    };
                  })
                }
              ></Input>
            </div>
          </section>
          <div className="flex-center-justify">
            <div></div>
            <ConfirmButton
              label="Zatwierdź zmiany"
              confirmLabel="Zatwierdzić zmiany?"
              fitToContent={true}
              sticky={true}
              action={() => updateUser(mutatedUserData.current)}
            ></ConfirmButton>
          </div>
        </section>
      </GenericView>
    );
  };

  const invokeModifyPasswordView = (user: User, viewClosed?: boolean) => {
    setView(
      <GenericView
        viewTitle={"Zmień swoje hasło"}
        closed={viewClosed}
        onClose={() => setView(undefined)}
        fitToContent={true}
      >
        <CurrentUserChangePassword
          onPasswordChanged={() => invokeModifyPasswordView(user, true)}
        />
      </GenericView>
    );
  };

  const invokeAppSettingsView = (viewClosed?: boolean) => {
    setView(
      <GenericView
        viewTitle={"Ustawienia aplikacji"}
        closed={viewClosed}
        onClose={() => setView(undefined)}
        fitToContent={true}
      >
        <CurrentUserAppSettings />
      </GenericView>
    );
  };

  return (
    <CurrentUserStyle>
      <>
        <h5>Cześć, {" " + user.firstName + " "}😉</h5>
        <div className="hero">
          <section className="hero__data">
            <div className="hero__data--image">
              <img src={imageHref} alt="Zdjęcie profilowe"></img>
            </div>
            <div className="hero__data--info">
              <span className="flex flex-wrap info">
                {user.registerDate && (
                  <>
                    <div className="date-time">
                      Data rejestracji:{" "}
                      {formatDateToLocaleString(
                        new Date(user.registerDate).toISOString()
                      )}
                    </div>
                    <div className="flex">
                      (
                      {getDurationTextFromDate(
                        new Date(user.registerDate).toISOString()
                      )}
                      )
                    </div>
                  </>
                )}
              </span>
              <span className="flex flex-wrap info">
                {user.lastLogInDate && (
                  <>
                    <div className="date-time">
                      Data ostatniego logowania:{" "}
                      {formatDateToLocaleString(
                        new Date(user.lastLogInDate).toISOString()
                      )}
                    </div>
                    <div className="flex">
                      (
                      {getDurationTextFromDate(
                        new Date(user.lastLogInDate).toISOString()
                      )}
                      )
                    </div>
                  </>
                )}
              </span>
            </div>
            <section className="hero__actions">
              <ActionButton
                sticky={false}
                fitToContent={false}
                className="action btn btn--active"
                onClick={() => invokeModifyUserView(user)}
              >
                Edytuj swoje dane
              </ActionButton>
              <ActionButton
                sticky={false}
                fitToContent={false}
                className="action btn btn--active"
                onClick={() => invokeModifyPasswordView(user)}
              >
                Zmień hasło
              </ActionButton>
              <ActionButton
                sticky={false}
                fitToContent={false}
                className="action btn btn--active"
                onClick={() => invokeAppSettingsView()}
              >
                Ustawienia aplikacji
              </ActionButton>
            </section>
          </section>
        </div>
      </>
      {view}
    </CurrentUserStyle>
  );
}
