import styled from "styled-components";
import ExpandableContainer from "../common/ExpandableContainer";
import CheckboxFilter from "../common/CheckboxFilter";
import { useEffect, useState } from "react";
import { IngredientModel } from "../../models/api/ingredient";
import { IRange, RangeSelector } from "../common/RangeSelector";
import { Filters, IQuery } from "../common/Filters";
const ReportsFiltersStyle = styled.section`
  height: 100%;
`;

export interface IStockFilters {
  ingredients: IngredientModel[];
  onApply: (query: IQuery[]) => void;
  query?: IQuery[];
}

export function StockFilters(props: IStockFilters) {
  const [query, setQuery] = useState<IQuery[]>(props.query ?? []);
  const [ingredients, setIngredients] = useState<number[]>(
    props.query
      ?.filter((e) => e.key === "ingredientId")
      ?.map((v) => v.value)[0] ?? []
  );

  const [activeOnly, setActiveOnly] = useState(
    props.query?.find((e) => e.key === "activeOnly")?.value
  );
  const [aggregateIngredients, setAggregateIngredients] = useState(
    props.query?.find((e) => e.key === "aggregateIngredients")?.value
  );
  const [selectedRange, setSelectedRange] = useState<IRange>(
    getRangeFromQuery(props.query)
  );

  function getRangeFromQuery(query?: IQuery[]): IRange {
    const _from = new Date(query?.find((e) => e.key === "fromDate")?.value);
    const _to = new Date(query?.find((e) => e.key === "toDate")?.value);
    return {
      from: !isNaN(_from?.getTime()) ? _from : null,
      to: !isNaN(_to?.getTime()) ? _to : null,
    };
  }

  useEffect(() => {
    const query = [
      {
        key: "ingredientId",
        value: ingredients,
        label: ingredients
          .map((e) => props.ingredients.find((i) => i.id === e)?.description)
          .join(", "),
      },
      {
        key: "activeOnly",
        value: activeOnly,
        label: activeOnly ? "na stanie" : "wszystkie",
      },
      {
        key: "aggregateIngredients",
        value: aggregateIngredients,
        label: aggregateIngredients ? "widok uproszczony" : "widok standardowy",
      },
      {
        key: "fromDate",
        value: selectedRange?.from?.toISOString(),
        label: "od: " + selectedRange?.from?.toLocaleDateString(),
      },
      {
        key: "toDate",
        value: selectedRange?.to?.toISOString(),
        label: "do: " + selectedRange?.to?.toLocaleDateString(),
      },
    ];
    const isValid = (e: IQuery) => {
      if (e.value === null || e.value === undefined) return false;
      if (Array.isArray(e.value) && e.value.length === 0) return false;
      return true;
    };
    setQuery(query.filter((e) => isValid(e)) as IQuery[]);
  }, [ingredients, activeOnly, aggregateIngredients, selectedRange]);

  return (
    <ReportsFiltersStyle>
      <Filters query={query} setQuery={setQuery} onApply={props.onApply}>
        <section className="item filter filter__inline">
          <div className="filter-label">Zakres</div>
          <div className="filter-value">
            <RangeSelector
              fromDate={selectedRange.from ?? undefined}
              toDate={selectedRange.to ?? undefined}
              onSelectedRangeChanged={(date) => {
                setSelectedRange({ from: date.from, to: date.to });
              }}
            />
          </div>
        </section>
        <section className="item filter filter__inline">
          <CheckboxFilter
            onSelectionChanged={(e) =>
              setActiveOnly(e.length > 0 ? true : false)
            }
            items={[
              {
                key: "activeOnly",
                value: "Tylko składniki na stanie",
                selected: activeOnly,
              },
            ]}
          ></CheckboxFilter>
        </section>
        <section className="item filter filter__inline">
          <CheckboxFilter
            onSelectionChanged={(e) =>
              setAggregateIngredients(e.length > 0 ? true : false)
            }
            items={[
              {
                key: "aggregateIngredients",
                value: "Widok uproszczony",
                selected: aggregateIngredients,
              },
            ]}
          ></CheckboxFilter>
        </section>
        <section className="item filter filter__inline">
          <ExpandableContainer
            header={"Składniki"}
            expanded={ingredients?.length ? true : false}
          >
            <CheckboxFilter
              multiSelectMode={true}
              onSelectionChanged={(e) => {
                setIngredients(e.map((i) => i.key));
              }}
              items={props.ingredients?.map((p) => {
                return {
                  key: p.id,
                  value: p.description,
                  selected: query
                    ?.find((e) => e.key === "ingredientId")
                    ?.value.includes(p.id),
                };
              })}
            ></CheckboxFilter>
          </ExpandableContainer>
        </section>
      </Filters>
    </ReportsFiltersStyle>
  );
}
