import { useApiCall } from "../../hooks/useApiCall";
import { InventoryUrl } from "../../config";
import { AsyncComponent } from "../common/AsyncComponent";
import { useEffect, useMemo } from "react";
import { Table } from "../common/Table";
import { UnitModel } from "../../models/api/unit";
import { IngredientModel } from "../../models/api/ingredient";
import { DropDown } from "../common/DropDown";
import { ModifiedInventoryModel } from "../../models/api/modifiedInventory";
import { TableCellEditButton } from "../common/TableCellEditButton";
import useEditableTable from "../../hooks/useEditableTable";
import { ActionButton } from "../common/ActionButton";
import { Input } from "../common/Input";
import useDispatcherState from "../../hooks/useDispatcherState";
import { StoreKeysEnum } from "../../store/storeKeysEnum";
import { LongPress } from "../common/hocs/LongPress";

export function ManageIngredients() {
  const [units] = useDispatcherState<UnitModel[]>(StoreKeysEnum.Units);
  const [ingredients, setIngredients] = useDispatcherState<IngredientModel[]>(
    StoreKeysEnum.Ingredients
  );
  const [response, isLoading, error, get, post] = useApiCall<IngredientModel[]>(
    {
      url: InventoryUrl + "/ingridient",
      setResponseOnPost: false,
    }
  );

  const getUnit = (id: number, units: UnitModel[]) => {
    return units.find((e) => e.id === id);
  };

  const { updateEntry, abortUnfocusedEdits, abortAllEdits } =
    useEditableTable();

  const applyChanges = async (
    ingredient: ModifiedInventoryModel<IngredientModel>
  ) => {
    post(InventoryUrl + "/ingridient/modify", ingredient).then((val: any) => {
      get(InventoryUrl + "/ingridient");
    });
  };

  function addNewItem(_units?: UnitModel[]) {
    setIngredients((old) => {
      const _old = old ? [...old] : [];
      _old.push({
        description: "Nowy składnik",
        fkUnitId: _units?.[0].id,
        unit: _units?.[0],
        unitPrice: 0,
      });
      return _old;
    });
  }

  useEffect(() => {
    response && setIngredients(response);
  }, [response]);

  const columns = useMemo(
    () => [
      {
        Header: "Nazwa",
        accessor: "description",
        Cell: (props: { value: number; row: any }) => {
          return (
            <div style={{ minWidth: "8rem" }}>
              {props.row.state.isEditEnabled ? (
                <Input
                  defaultValue={props.value}
                  onChange={(value) => {
                    props.row.state.description = value;
                  }}
                ></Input>
              ) : (
                props.value
              )}
            </div>
          );
        },
      },
      {
        Header: "Jednostka",
        accessor: "fkUnitId",
        Cell: (props: { value: number; row: any }) => {
          return (
            <div style={{ minWidth: "8rem" }}>
              {props.row.state.isEditEnabled ? (
                <DropDown
                  selectedIndex={units?.findIndex(
                    (el) => el.id === props.value
                  )}
                  items={
                    units
                      ? units?.map((el) => {
                          return { key: el.id, value: el.description };
                        })
                      : []
                  }
                  onItemSelected={(e) => {
                    props.row.state.unit = getUnit(e?.key, units);
                  }}
                ></DropDown>
              ) : (
                <div>
                  {units?.find((e) => e.id === props.value)?.description}
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Cena/jednostka",
        accessor: "unitPrice",
        Cell: (props: { value: number; row: any }) => {
          return (
            <div style={{ minWidth: "8rem" }}>
              {props.row.state.isEditEnabled ? (
                <Input
                  type="number"
                  defaultValue={props.value}
                  onChange={(e) => {
                    props.row.state.unitPrice = e;
                  }}
                ></Input>
              ) : (
                props.value
              )}
            </div>
          );
        },
      },
      {
        Header: "Uzysk/jednostka(%)",
        accessor: "percentYieldRatio",
        Cell: (props: { value: number; row: any }) => {
          return (
            <div style={{ minWidth: "8rem" }}>
              {props.row.state.isEditEnabled ? (
                <Input
                  type="number"
                  defaultValue={props.value}
                  onChange={(e) => {
                    props.row.state.percentYieldRatio = e;
                  }}
                ></Input>
              ) : (
                (props.value ?? 100) + "%"
              )}
            </div>
          );
        },
      },
      {
        id: "Action",
        Cell: (props: { row: any; preparedRows: any }) => {
          return (
            <LongPress onLongPress={() => abortAllEdits(props.preparedRows)}>
              <TableCellEditButton
                row={props.row}
                onEntryUpdated={(row) => applyChanges(updateEntry(row))}
              ></TableCellEditButton>
            </LongPress>
          );
        },
      },
    ],
    [ingredients, units]
  );

  return (
    <>
      <AsyncComponent data={ingredients} isLoading={isLoading} error={error}>
        {(ingredients: IngredientModel[]) => {
          return (
            <Table
              columns={columns}
              data={ingredients}
              onRowClick={abortUnfocusedEdits}
            ></Table>
          );
        }}
      </AsyncComponent>
      <ActionButton
        label="+ Dodaj nowy składnik"
        onClick={() => addNewItem(units)}
      ></ActionButton>
    </>
  );
}
