import { LocalStorageKey } from "../config";
import { AppSettings } from "../models/app/app-settings";
import { IAppSettingsService } from "../models/app/IAppSettingsService";
import { IDispatcher } from "../models/dispatcher/dispatcher-interfaces";
import { StoreKeysEnum } from "../store/storeKeysEnum";

export class AppSettingsService implements IAppSettingsService {
  constructor(dispatcher: IDispatcher) {
    this.dispatcher = dispatcher;
  }

  private dispatcher: IDispatcher;

  public getSettings(): AppSettings {
    const settings = window.localStorage.getItem(LocalStorageKey);
    if (settings) {
      return new AppSettings(JSON.parse(settings));
    } else {
      return new AppSettings(null);
    }
  }

  public setSettings = (
    value: AppSettings | ((oldValue: AppSettings) => AppSettings)
  ) => {
    const _settings =
      value instanceof Function ? value({ ...this.getSettings() }) : value;
    this.dispatcher.state.saveState(StoreKeysEnum.AppSettings, _settings);
    window.localStorage.setItem(LocalStorageKey, JSON.stringify(_settings));
  };
}
