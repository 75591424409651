import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
import {
  IDispatcher,
  IWatchable,
} from "../models/dispatcher/dispatcher-interfaces";
import { OrderHubUrl } from "../config";
import { StoreKeysEnum } from "../store/storeKeysEnum";
import { User } from "../models/api/user";
import { info, error } from "../components/common/Toast";
import { OrderModel } from "../models/api/order";
import { ProductModel } from "../models/api/product";
import { ActiveOrderModel } from "../models/api/activeOrderModel";
import { INotificationService } from "../models/app/INotifcationService";
import {
  updateActiveOrders,
  decorateData,
} from "../helpers/updateActiveOrders";
import { HubTopicsEnum } from "../models/enums/hubTopicsEnum";

export class OrderHub {
  private dispatcher: IDispatcher;
  private notification: INotificationService;

  private attachHub(user?: User) {
    const hubConnection = new HubConnectionBuilder()
      .withUrl(OrderHubUrl, {
        accessTokenFactory: () => user?.accessToken ?? "",
      })
      .withAutomaticReconnect()
      .build();

    this.dispatcher.state.initState(StoreKeysEnum.NewOrders, []);
    this.dispatcher.state.initState(StoreKeysEnum.LocalOrders, []);

    const startConnection = async () => {
      try {
        await hubConnection.start();
        info("Połączono z serwerem");
        const products = this.dispatcher.state.getState(
          StoreKeysEnum.Products
        ) as ProductModel[];
        if (!products) {
          this.dispatcher.on(StoreKeysEnum.Products, () => attachToEvents());
        } else {
          attachToEvents();
        }
      } catch (e) {
        error("Nie można połączyć z serwerem");
        console.error(e);
      }
    };

    const attachToEvents = () => {
      hubConnection.onreconnected((e) => {
        updateActiveOrders(this.dispatcher);
      });

      // attach to hub messages
      hubConnection.on(HubTopicsEnum.NewOrder, (message: ActiveOrderModel) => {
        const localOrders = this.dispatcher.state.getState(
          StoreKeysEnum.LocalOrders
        ) as ActiveOrderModel[];
        const _decorated = decorateData(message, this.dispatcher);
        const combined = [
          ...localOrders.filter(
            (x) =>
              (x.orders?.length ?? 0) > 0 && x.orderId !== _decorated.orderId
          ),
          _decorated,
        ];
        this.dispatcher.state.saveState(StoreKeysEnum.NewOrders, _decorated);
        this.dispatcher.state.saveState(StoreKeysEnum.LocalOrders, combined);

        this.notification.notify("Otrzymano nowe zamówienie");
      });

      hubConnection.on(HubTopicsEnum.OrderFinalized, (message: OrderModel) => {
        const localOrders = this.dispatcher.state.getState(
          StoreKeysEnum.LocalOrders
        ) as ActiveOrderModel[];

        localOrders.forEach((order) => {
          order.orders = order.orders!.filter((el) => el.id !== message.id);
        });
        this.dispatcher.state.saveState(StoreKeysEnum.LocalOrders, [
          ...localOrders.filter((x) => (x.orders?.length ?? 0) > 0),
        ]);
      });

      hubConnection.on(
        HubTopicsEnum.AwaitingOrdersChanged,
        (message: number) => {
          this.dispatcher.state.saveState(
            StoreKeysEnum.AwaitingOrdersCount,
            message
          );
        }
      );

      hubConnection.on(HubTopicsEnum.ActiveOrdersChanged, (message: number) => {
        this.dispatcher.state.saveState(
          StoreKeysEnum.ActiveOrdersCount,
          message
        );
      });
    };
    startConnection();
    return hubConnection;
  }

  private attachOnAuthorization() {
    this.dispatcher.on(StoreKeysEnum.User, (user: IWatchable<User>) => {
      const hub = this.dispatcher.state.getState(StoreKeysEnum.HubConnection);
      const attachHub = () => {
        const newHub = this.attachHub(user.currentValue);
        this.dispatcher.state.saveState(StoreKeysEnum.HubConnection, newHub);
      };
      if (user.currentValue?.authenticated === true) {
        if (!hub) {
          attachHub();
        } else {
          hub?.stop().then(() => {
            attachHub();
          });
        }
      } else {
        hub?.stop();
      }
    });
  }

  constructor(
    dispatcher: IDispatcher,
    notificationService: INotificationService
  ) {
    this.dispatcher = dispatcher;
    this.notification = notificationService;
    this.attachOnAuthorization();
  }
}
