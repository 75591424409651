import { useState, useEffect } from "react";
import axios, { Axios, AxiosError, AxiosResponse } from "axios";

// hook for getting data from API on component init (if url prop is provided)
//              return object = {   response - (state) actual response,
//                                  isLoading - (state) boolean indicating that loading is in progress,
//                                  error - (state) actual error, if null, there is no error
//                                  get - get method, ready to call on demand, sets state
//                                  post - post method, ready to call on demand, sets state
//                                  refresh - refresh method, re-fetch data, reset states  }

export interface IUseApiCall {
  url?: string;
  setResponseOnPost?: boolean;
  requestHeaders?: { [key: string]: string }[];
}

export function useApiCall<T>(
  props: IUseApiCall
): [
  T | null,
  boolean,
  any,
  (link: string | null) => any,
  (link: string | null, data: any) => any,
  () => void
] {
  const [response, setResponse] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const get = (link: string | null) => fetchData(link);
  const post = (link: string | null, data: any) =>
    fetchData(link, "post", data, props.setResponseOnPost);
  const headers = {
    "Content-Type": "application/json",
    ...props.requestHeaders,
  };

  function fetchData(
    _url: string | null,
    _method?: any,
    _data?: any,
    _setResponse = true
  ) {
    _setResponse && setError(null);
    _setResponse && setResponse && setResponse(null);
    if (!_url) return;
    _setResponse && setIsLoading(true);
    try {
      return (axios as any)({
        method: _method ?? "get",
        url: _url,
        data: _data,
        headers: headers,
      })
        .then((result: AxiosResponse) => {
          if (_setResponse && result) {
            handleResponse(result);
          }
          return result;
        })
        .catch((e: AxiosError) => {
          handleError(e);
          throw e;
        });
    } catch (e) {
      handleError(e);
    }
  }

  function handleError(e: any) {
    setError(e?.response ?? null);
    setResponse(null);
    setIsLoading(false);
    throw e;
  }

  function handleResponse(r: any) {
    setResponse(r?.data);
    setIsLoading(false);
  }

  function refresh() {
    if (props.url) {
      fetchData(props.url);
    }
  }

  useEffect(() => {
    refresh();
  }, [props.url]);

  return [response, isLoading, error, get, post, refresh];
}
