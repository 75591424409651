import { IDispatcher } from "../models/dispatcher/dispatcher-interfaces";
import { Event } from "../models/dispatcher/dispatcher-events";

export default function enableGestures(
  dispatcher: IDispatcher,
  element?: HTMLElement | null
) {
  if (!element) element = document.body;
  //disable pull-to-refresh behaviour (chrome)
  document.body.style.overscrollBehavior = "contain";
  let pStart = { x: 0, y: 0 };
  let pStop = { x: 0, y: 0 };
  let rect: DOMRect;

  function swipeStart(e: any) {
    if (typeof e["targetTouches"] !== "undefined") {
      let touch = e.targetTouches[0];
      pStart.x = touch.screenX;
      pStart.y = touch.screenY;
    } else {
      pStart.x = e.screenX;
      pStart.y = e.screenY;
    }
    rect = (element as any).getBoundingClientRect();
  }

  function swipeEnd(e: any) {
    if (typeof e["changedTouches"] !== "undefined") {
      let touch = e.changedTouches[0];
      pStop.x = touch.screenX;
      pStop.y = touch.screenY;
    } else {
      pStop.x = e.screenX;
      pStop.y = e.screenY;
    }
    swipeCheck(e);
  }

  function dispatchSwipe(x: number, y: number, e: any) {
    if (Math.abs(x) > Math.abs(y)) {
      //horizontal swipe
      if (x > 0 && rect?.right === rect?.width) {
        dispatcher.dispatch(Event.SwipeLeft, e.target);
      } else if (x < 0 && rect?.left <= 0) {
        dispatcher.dispatch(Event.SwipeRight, e.target);
      }
    } else {
      if (y > 0 && Math.ceil(rect?.bottom) <= Math.ceil(window.innerHeight)) {
        //swipe up event on top of page
        dispatcher.dispatch(Event.SwipeUp, e.target);
      } else if (y < 0 && rect?.bottom === rect.height) {
        //swipe down event on top of page
        dispatcher.dispatch(Event.SwipeDown, e.target);
      }
    }
  }

  function swipeCheck(e: any) {
    let changeY = pStart.y - pStop.y;
    let changeX = pStart.x - pStop.x;
    if (Math.abs(changeX) > 10 || Math.abs(changeY) > 10) {
      dispatchSwipe(changeX, changeY, e);
    }
  }

  document.addEventListener(
    "touchstart",
    function (e) {
      swipeStart(e);
    },
    false
  );
  document.addEventListener(
    "touchend",
    function (e) {
      swipeEnd(e);
    },
    false
  );
  document.addEventListener(
    "mousedown",
    function (e) {
      swipeStart(e);
    },
    false
  );
  document.addEventListener(
    "mouseup",
    function (e) {
      swipeEnd(e);
    },
    false
  );
}
