import { useEffect, useState } from "react";
import { IngredientModel } from "../../models/api/ingredient";
import { NewIngridient } from "./NewProductCreatorIngridient";
import { UnitModel } from "../../models/api/unit";
import { Input } from "../common/Input";
import isEqual from "lodash.isequal";
import { SideModel } from "../../models/api/side";
import { NewVariantStyle } from "./NewProductCreatorVariant";

export interface INewSide {
  side: SideModel;
  ingredients: IngredientModel[];
  units: UnitModel[];
  onSideChanged: (side: SideModel) => void;
  onSideDeleted: (side: SideModel) => void;
  onSideCopied: (side: SideModel) => void;
}

export function NewSide(props: INewSide) {
  const [side, setSide] = useState(props.side);
  const [ingredients, setIngredients] = useState<IngredientModel[]>(
    side.ingredients ?? []
  );

  useEffect(() => {
    !isEqual(props.side, side) && props.onSideChanged(side);
  }, [side]);

  useEffect(() => {
    setSide(props.side);
  }, [props.side]);

  useEffect(() => {
    setSide((old) => {
      return {
        ...old,
        ingredients: ingredients,
      };
    });
  }, [ingredients]);

  return (
    <NewVariantStyle>
      <div className="variant" key={props.side.id}>
        <div className="input-field ">
          <div className="name">Nazwa dodatku:</div>{" "}
          <Input
            className="input"
            value={props.side.description ?? ""}
            onChange={(e) =>
              setSide((old: SideModel) => {
                return {
                  ...old,
                  description: String(e),
                };
              })
            }
          ></Input>
        </div>
        <div className="input-field">
          <div className="name">Cena:</div>{" "}
          <Input
            type="number"
            className="input"
            value={props.side.price ?? ""}
            onChange={(e) =>
              setSide((old: SideModel) => {
                return {
                  ...old,
                  price: isNaN(Number(e)) ? undefined : Number(e),
                };
              })
            }
          ></Input>
        </div>
      </div>
      <div className="ingridients">
        <details>
          <summary>Składniki</summary>
          <div className="ingridients-container">
            {ingredients.map((ingredient, i) => (
              <div className="variant" key={i}>
                <NewIngridient
                  ingridient={ingredient}
                  units={props.units}
                  availableIngridients={props.ingredients}
                  onIngridientChanged={(_ig) =>
                    setIngredients((old) => {
                      old[i] = _ig;
                      return [...old];
                    })
                  }
                  onIngridientDeleted={(_ig) =>
                    setIngredients((old: any[]) => {
                      old[i] = null;
                      return old.filter((el: any) => el);
                    })
                  }
                ></NewIngridient>
              </div>
            ))}
          </div>
        </details>
      </div>
      <div className="variant-actions">
        <span className="variant-actions__add">
          <button
            onClick={() => {
              setIngredients((old: IngredientModel[]) => {
                return [...old, {}];
              });
            }}
            className="btn--active"
          >
            + Dodaj składnik
          </button>
        </span>
        <span className="variant-actions__copy-delete">
          <button
            className="btn--active"
            onClick={() => {
              props.onSideCopied(side);
            }}
          >
            Kopiuj
          </button>
          <button
            className="btn--alert"
            onClick={() => {
              props.onSideDeleted(side);
            }}
          >
            Usuń
          </button>
        </span>
      </div>
    </NewVariantStyle>
  );
}
