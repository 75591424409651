export enum StoreKeysEnum {
  Groups = "groups",
  Products = "products",
  Ingredients = "ingredients",
  Units = "units",
  Users = "users",
  User = "user",
  Order = "order",
  AvailableOrderOptions = "availableorderoptions",
  LocalOrders = "localorders",
  NewOrders = "neworders",
  ActiveOrdersCount = "activeorderscount",
  AwaitingOrdersCount = "awaitingorderscount",
  AppSettings = "appsettings",
  ServerSettings = "serversettings",
  HubConnection = "hubconnection",
}
