import { getGroup } from "../helpers/getGroup";
import useDispatcher from "./useDispatcher";

export default function useGroup(groupId?: number) {
  const dispatcher = useDispatcher();

  const getGroupFn = (id?: number) => {
    return getGroup(id ?? NaN, dispatcher.Dispatcher);
  };

  return {
    group: getGroup(groupId ?? NaN, dispatcher.Dispatcher),
    getGroup: getGroupFn,
  };
}
