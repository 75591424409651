export function TableCellEditButton(props: {
  row: any;
  onEntryUpdated: (row: any) => void;
  editLabel?: string;
  saveLabel?: string;
}) {
  return (
    <div className="action flex flex-right">
      <button
        className="btn--active "
        onClick={() => {
          props.row.state.isEditEnabled
            ? props.onEntryUpdated(props.row)
            : props.row.setState((state: any) => ({
                ...state,
                isEditEnabled: true,
              }));
        }}
      >
        {props.row.state?.isEditEnabled
          ? props.saveLabel ?? "Zapisz"
          : props.editLabel ?? "Edytuj"}
      </button>
    </div>
  );
}
