import { getProduct } from "../helpers/getProduct";
import useDispatcher from "./useDispatcher";

export default function useProduct(productId?: number) {
  const dispatcher = useDispatcher();

  const getProductFn = (id?: number) => {
    return getProduct(id ?? NaN, dispatcher.Dispatcher);
  };

  return {
    product: getProduct(productId ?? NaN, dispatcher.Dispatcher),
    getProduct: getProductFn,
  };
}
