import styled from "styled-components";
import { useEffect, useState } from "react";
import { ProductModel } from "../../models/api/product";
import { Toggler } from "../common/Toggler";
import { ProductStateEnum } from "../../models/enums/productStateEnum";
import { VariantModel } from "../../models/api/variant";
import { NewVariant } from "./NewProductCreatorVariant";
import { NewSide } from "./NewProductCreatorSide";
import { UnitModel } from "../../models/api/unit";
import { IngredientModel } from "../../models/api/ingredient";
import { ConfirmButton } from "../common/ConfirmButton";
import { Input } from "../common/Input";
import { ColorSelector } from "../common/ColorSelector";
import { SideModel } from "../../models/api/side";

const ModifyProductStyle = styled.section`
  input {
    max-width: 20rem;
    border: var(--border-width) solid (var(--col-fg-active));
  }
  .product-creator {
    &__first-step {
    }
    &__second-step {
    }
    h4,
    h5,
    h6 {
      text-align: left;
      margin-top: 1rem;
    }
    &__product {
      max-width: 50rem;
      margin: auto;
      .input-field {
        width: 100%;
        margin: 0.2rem;
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        .name {
          flex-basis: 40%;
          text-align: left;
        }
        .input {
          margin-left: auto;
        }
      }
    }
    &__variants {
      justify-content: center;
      .variant-container {
        margin: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 50rem;
        margin: auto;
        .variant {
          width: 100%;
        }
      }
      .creator-actions {
        margin-top: 0.5rem;
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;

export interface IModifyProductComponent {
  product?: ProductModel;
  units: UnitModel[];
  ingredients: IngredientModel[];
  onProductModified?: (product: ProductModel) => void;
}

export function ModifyProduct(props: IModifyProductComponent) {
  const [updatedProduct, setUpdatedProduct] = useState<ProductModel>({});
  const [variants, setVariants] = useState<VariantModel[]>([]);
  const [sides, setSides] = useState<SideModel[]>([]);

  function saveProduct(
    product: ProductModel,
    variants: VariantModel[],
    sides: SideModel[]
  ) {
    const merged = { ...product, variants: variants, sides: sides };
    props.onProductModified && props.onProductModified(merged);
  }

  useEffect(() => {
    setUpdatedProduct(props.product ? { ...props.product } : {});
    setVariants(props.product?.variants ? [...props.product.variants] : []);
    setSides(props.product?.sides ? [...props.product.sides] : []);
  }, [props.product]);

  return (
    <ModifyProductStyle>
      <div className="product-creator__product">
        <div className="input-field">
          <div className="name">Nazwa produktu:</div>{" "}
          <Input
            className="input"
            value={updatedProduct.description ?? ""}
            onChange={(e) =>
              setUpdatedProduct((old: any) => {
                return {
                  ...old,
                  description: e,
                };
              })
            }
          ></Input>
        </div>
        <div className="input-field">
          <div className="name">Numer w menu:</div>{" "}
          <Input
            type="number"
            className="input"
            value={updatedProduct.displaySequence ?? ""}
            onChange={(e) =>
              setUpdatedProduct((old: any) => {
                return {
                  ...old,
                  displaySequence: e,
                };
              })
            }
          ></Input>
        </div>
        <div className="input-field">
          <div className="name">Produkt aktywny:</div>
          <div className="input flex-right">
            <Toggler
              toggled={
                updatedProduct.state === ProductStateEnum.Active ? true : false
              }
              onToggleChanged={(value) =>
                setUpdatedProduct((old: any) => {
                  return {
                    ...old,
                    state: value
                      ? ProductStateEnum.Active
                      : ProductStateEnum.Inactive,
                  };
                })
              }
            ></Toggler>
          </div>
        </div>
        <div className="input-field">
          <div className="name">Kolor kafelka:</div>
          <div className="input">
            <ColorSelector
              selectedColor={updatedProduct?.colorCode}
              onSelectedColorChanged={(value) =>
                setUpdatedProduct((old: any) => {
                  return {
                    ...old,
                    colorCode: value,
                  };
                })
              }
            ></ColorSelector>
          </div>
        </div>
      </div>
      <div className="product-creator product-creator__variants">
        <h5>Warianty produktu</h5>
        <div className="variant-container">
          {variants?.map((variant, i) => (
            <div className="variant" key={i}>
              <NewVariant
                units={props.units}
                ingridients={props.ingredients}
                variant={variant}
                onVariantChanged={(variant) =>
                  setVariants((old) => {
                    old[i] = variant;
                    return [...old];
                  })
                }
                onVariantDeleted={(variant) =>
                  setVariants((old: any) => {
                    const _filtered = old.filter(
                      (el: VariantModel) => el.id !== variant.id
                    );
                    return [..._filtered];
                  })
                }
                onVariantCopied={(variant) => {
                  setVariants((old: any) => {
                    return [...old, { ...variant, id: null }];
                  });
                }}
              ></NewVariant>
            </div>
          ))}
        </div>
        <div className="creator-actions">
          <button
            className="btn btn--active"
            onClick={() =>
              setVariants((old: VariantModel[]) => {
                return [...old, {}];
              })
            }
          >
            + Dodaj wariant
          </button>
          <div></div>
        </div>
      </div>
      <div className="product-creator product-creator__variants">
        <h5>Dodatki do produktu</h5>
        <div className="variant-container">
          {sides?.map((side, i) => (
            <div className="variant" key={i}>
              <NewSide
                units={props.units}
                ingredients={props.ingredients}
                side={side}
                onSideChanged={(side) =>
                  setSides((old) => {
                    old[i] = side;
                    return [...old];
                  })
                }
                onSideDeleted={(side) =>
                  setSides((old: any) => {
                    const _filtered = old.filter(
                      (el: SideModel) => el.id !== side.id
                    );
                    return [..._filtered];
                  })
                }
                onSideCopied={(side) => {
                  setSides((old: any) => {
                    return [...old, { ...side, id: null }];
                  });
                }}
              ></NewSide>
            </div>
          ))}
        </div>
        <div className="creator-actions">
          <button
            className="btn btn--active"
            onClick={() =>
              setSides((old: SideModel[]) => {
                return [...old, {}];
              })
            }
          >
            + Dodaj dodatek
          </button>
          <ConfirmButton
            label={props.product ? "Zapisz zmiany" : "Zapisz ten produkt"}
            confirmLabel={
              props.product ? "Zapisać zmiany?" : "Zapisać ten produkt?"
            }
            className="btn btn--active"
            sticky={false}
            action={() => saveProduct(updatedProduct, variants, sides)}
          ></ConfirmButton>
        </div>
      </div>
    </ModifyProductStyle>
  );
}
