import { GroupModel } from "../models/api/group";
import { IDispatcher } from "../models/dispatcher/dispatcher-interfaces";
import { StoreKeysEnum } from "../store/storeKeysEnum";

export const getGroup = (id: number, dispatcher: IDispatcher) => {
  const groups = dispatcher.state.getState(
    StoreKeysEnum.Groups
  ) as GroupModel[];
  
  return !isNaN(id) ? groups?.find((p) => p.id === id) ?? null : null;
};
