import styled from "styled-components";
import { NewProductCreator } from "../components/feature/NewProductCreator";
import { useApiCall } from "../hooks/useApiCall";
import { InventoryUrl } from "../config";
import { useEffect, useState } from "react";
import { AsyncComponent } from "../components/common/AsyncComponent";
import { IngredientModel } from "../models/api/ingredient";
import { UnitModel } from "../models/api/unit";
import { ProductModel } from "../models/api/product";
import { useNavigate } from "react-router-dom";

const InventoryCreateStyle = styled.div``;

export function InventoryProductCreateView() {
  const [, , error, get, post] = useApiCall({});
  const [inventoryData, setInventoryData] = useState<{
    ingridients: IngredientModel[];
    units: UnitModel[];
  }>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function updateUnits(units: UnitModel[]) {
    for await (const unit of units) {
      if (!unit.id) {
        const result = await post(InventoryUrl + "/unit/modify", {
          Action: "Create",
          Item: { Description: unit.description },
        });
        const id = result.data.id;
        unit.id = id;
      }
    }
    return { units: units };
  }

  async function updateIngridients(ingridients: IngredientModel[]) {
    for await (const ingridient of ingridients) {
      if (!ingridient.id) {
        const result = await post(InventoryUrl + "/ingridient/modify", {
          Action: "Create",
          Item: {
            Description: ingridient.description,
            Unit: { Id: ingridient.unit?.id },
          },
        });
        const id = result.data.id;
        ingridient.id = id;
      }
    }
    return { ingridients: ingridients };
  }

  async function updateProduct(product: ProductModel) {
    setLoading(true);
    const newProduct = await post(InventoryUrl + "/product/modify", {
      Action: "Create",
      Item: {
        Description: product.description,
        DisplaySequence: product.displaySequence,
        State: product.state,
      },
    });
    if (product.variants) {
      for await (const variant of product.variants) {
        await post(InventoryUrl + "/variant/modify", {
          Action: "Create",
          Item: {
            Description: variant.description,
            ProductId: newProduct.data.id,
            Ingridients: variant.ingridients?.map((i) => {
              return { Id: i.id, Amount: i.amount };
            }),
            Price: variant.price,
          },
        });
      }
    }
    if (product.sides) {
      for await (const side of product.sides) {
        await post(InventoryUrl + "/side/modify", {
          Action: "Create",
          Item: {
            Description: side.description,
            ProductId: newProduct.data.id,
            Ingredients: side.ingredients?.map((i) => {
              return { Id: i.id, Amount: i.amount };
            }),
            Price: side.price,
          },
        });
      }
    }
    setLoading(false);
    navigate("/inventory");
  }

  useEffect(() => {
    const fetchData = async () => {
      const _ingridients = await get(InventoryUrl + "/ingridient");
      const _units = await get(InventoryUrl + "/unit");
      const ingridients = _ingridients.data.map((el: any) => {
        return { ...el, unit: { id: el.fkUnitId } };
      });
      const units = _units.data;
      setInventoryData({ ingridients: ingridients, units: units });
    };
    fetchData();
  }, []);

  return (
    <InventoryCreateStyle>
      <AsyncComponent
        data={inventoryData}
        isLoading={loading || !inventoryData}
        error={error}
      >
        {(props: { ingridients: IngredientModel[]; units: UnitModel[] }) => {
          return (
            <NewProductCreator
              ingridients={props.ingridients}
              units={props.units}
              onUnitsUpdated={updateUnits}
              onIngridientsUpdated={updateIngridients}
              onNewProductCreated={updateProduct}
            ></NewProductCreator>
          );
        }}
      </AsyncComponent>
    </InventoryCreateStyle>
  );
}
