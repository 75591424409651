import styled from "styled-components";

const IconStyle = styled.div`
  .icon {
    cursor: pointer;
    svg {
      height: 2em;
      width: 2em;
      fill: var(--col-fg-primary);
      margin: 0 var(--spacing-h) 0 var(--spacing-h);
    }
  }
`;

export interface IIcon {
  children: JSX.Element;
  onClick?: () => void;
  classes?: String;
  [x: string]: any;
}

export function Icon(props: IIcon) {
  const { children, onClick, ...rest } = props;
  return (
    <IconStyle>
      <div
        className={`icon ${props.classes}`}
        onClick={props.onClick}
        {...rest}
      >
        {children}
      </div>
    </IconStyle>
  );
}
