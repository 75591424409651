import styled from "styled-components";
import { TabComponent, TabItem } from "../components/common/TabComponent";
import { ManageUsers } from "../components/feature/ManageUsers";
import { Reports } from "../components/feature/Reports";
import { ManageTimeConstrainedPromosComponent } from "../components/feature/ManageTimeConstrainedPromos";
import { ManageRecurringPromosComponent } from "../components/feature/ManageRecurringPromos";

const ManageStyle = styled.div`
  .tab {
    margin: 0.5rem;
    padding-bottom: 0.2rem;
  }
`;

export function ManageView() {
  return (
    <ManageStyle>
      <TabComponent>
        <TabItem key="reports" title="Raporty">
          <div className="tab">
            <Reports />
          </div>
        </TabItem>
        <TabItem key="users" title="Użytkownicy">
          <div className="tab">
            <ManageUsers />
          </div>
        </TabItem>
        <TabItem key="tpromos" title="Promocje czasowe">
          <div className="tab">
            <ManageTimeConstrainedPromosComponent />
          </div>
        </TabItem>
        <TabItem key="cpromos" title="Promocje cykliczne">
          <div className="tab">
            <ManageRecurringPromosComponent />
          </div>
        </TabItem>
      </TabComponent>
    </ManageStyle>
  );
}
