import { useApiCall } from "../../hooks/useApiCall";
import { AsyncComponent } from "../common/AsyncComponent";
import { useEffect, useMemo, useState } from "react";
import { Table } from "../common/Table";
import { DateTimeComponent } from "../common/DateTimeComponent";
import styled from "styled-components";
import { AwaitingOrderModel } from "../../models/api/awaitingOrderModel";
import { OrdersUrl } from "../../config";
import { OrderModel } from "../../models/api/order";
import { ConfirmButton } from "../common/ConfirmButton";
import { StoreKeysEnum } from "../../store/storeKeysEnum";
import useDispatcherState from "../../hooks/useDispatcherState";
import { Role, User } from "../../models/api/user";
import { OptionModel } from "../../models/api/optionModel";
import { stringToHexColor } from "../../helpers/stringToHexColor";
import { AwaitingOrdersChildTable } from "./AwaitingOrdersChildTable";
import { GenericView } from "../common/GenericView";
import { IQuery } from "../common/Filters";
import { AwaitingOrdersFilters } from "./AwaitingOrdersFilters";
import { FilterPillButton } from "../common/FilterPill";
import { AppSettingModel } from "../../models/api/appSettingModel";
import { ViewMoreButton } from "../common/ViewMoreButton";
import { AxiosResponse } from "axios";
import { AwaitingOrdersChangeOrder } from "./AwaitingOrdersChangeOrder";
import { OrderList } from "./OrderList";
import { List } from "../common/List";
import { ActiveOrdersViewStyle } from "../../views/ActiveOrdersView";
import { Toggler } from "../common/Toggler";
import { AppSettingsKeys } from "../../models/app/appSettingsKeys";
import { ViewTypeEnum } from "../../models/enums/viewTypeEnum";
import { Attachments } from "./Attachments";

const AwaitingOrdersStyle = styled.section`
  .orders-selections {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: var(--border-width) solid var(--col-bg-secondary);
    display: flex;
    &__selections {
      display: flex;
      flex-wrap: wrap;
      gap: 0.2rem;
    }
    &__actions {
      .btn--active {
        width: 8rem;
      }
    }
  }
  .order-action {
    width: 6rem;
    justify-content: center;
  }
  .child-table {
    background-color: var(--col-bg-tertiary);
    thead th {
      background-color: var(--col-bg-tertiary);
    }
  }
  .order-list {
    width: 100%;
    &__list {
      display: flex;
    }
  }
`;

export function AwaitingOrders() {
  const allButUsers = [Role.Admin, Role.Employee, Role.Manager];
  const onlyManagers = [Role.Admin, Role.Manager];
  const [user] = useDispatcherState<User>(StoreKeysEnum.User);
  const [options] = useDispatcherState<OptionModel[]>(
    StoreKeysEnum.AvailableOrderOptions
  );
  const [settings] = useDispatcherState<AppSettingModel[]>(
    StoreKeysEnum.ServerSettings
  );
  const [response, isLoading, error, , post] = useApiCall<AwaitingOrderModel[]>(
    { url: OrdersUrl + "/getAwaitingOrders" }
  );
  const [awaitingOrders, setAwaitingOrders] = useState<AwaitingOrderModel[]>(
    []
  );
  const [listView, setListView] = useState(false);

  const [awaitingOrdersCount] = useDispatcherState<number>(
    StoreKeysEnum.AwaitingOrdersCount
  );
  const [query, setQuery] = useState<IQuery[]>([]);
  const [view, setView] = useState<React.ReactNode>();

  function activateOrder(order: AwaitingOrderModel) {
    post(OrdersUrl + "/makeAwaitingOrderActive", order).then(() => {
      setQuery((old) => [...old]);
    });
  }

  function clearSelection(key: string) {
    setQuery((old) => {
      const _filtered = old.filter((e) => e.key !== key);
      return [..._filtered];
    });
  }

  const invokeFiltersView = (
    options: OptionModel[],
    listView: boolean,
    viewClosed?: boolean
  ) => {
    setView(
      <GenericView
        viewTitle={"Filtruj wyniki"}
        closed={viewClosed}
        onClose={() => setView(undefined)}
        fitToContent={true}
      >
        <AwaitingOrdersFilters
          options={options}
          query={query}
          onApply={(filters) => {
            setQuery(filters);
            invokeFiltersView(options, listView, true);
          }}
        >
          <div className="flex flex-center-justify">
            Pokaż widok listy
            <Toggler
              toggled={listView}
              onToggleChanged={(value) => setListView(value)}
            ></Toggler>
          </div>
        </AwaitingOrdersFilters>
      </GenericView>
    );
  };

  const invokeEditView = (order: AwaitingOrderModel, viewClosed?: boolean) => {
    setView(
      <GenericView
        viewTitle={"Edytuj zamówienie"}
        closed={viewClosed}
        onClose={() => setView(undefined)}
        fitToContent={false}
      >
        <AwaitingOrdersChangeOrder
          order={order}
          onOrderModified={(order) => {
            post(OrdersUrl, order).then(() => {
              invokeEditView(order, true);
              setQuery((old) => [...old]);
            });
          }}
        ></AwaitingOrdersChangeOrder>
      </GenericView>
    );
  };

  useEffect(() => {
    response && Array.isArray(response) && setAwaitingOrders(response);
  }, [response]);

  useEffect(() => {
    const viewSetting = settings?.find(
      (e) => e.key === AppSettingsKeys.DefaultAwaitingOrdersViewType
    )?.value;
    setListView(viewSetting === ViewTypeEnum.List ? true : false);
  }, [settings]);

  useEffect(() => {
    const queryObj: any = query.reduce(
      (previous: any, current: any, index: number) => {
        return {
          ...previous,
          [current.key]: current.value,
        };
      },
      {}
    );
    post(OrdersUrl + "/getAwaitingOrders", queryObj).then(
      (response: AxiosResponse<AwaitingOrderModel[]>) => {
        setAwaitingOrders(response.data);
      }
    );
  }, [query, awaitingOrdersCount]);

  const columns = useMemo(
    () => [
      {
        Header: "Data złożenia",
        Expander: (props: any) => {
          const { allExpanded, setAllExpanded } = props;
          return (
            <span
              className="margin-right"
              onClick={(e) => {
                e.stopPropagation();
                setAllExpanded((old: boolean) => !old);
              }}
            >
              <div className="icon">{allExpanded ? "▼" : "▶"}</div>
            </span>
          );
        },
        accessor: "orders",
        isExpander: "true",
        Cell: (props: { value: OrderModel[]; row: any }) => {
          const canExpand = props.value && props.value.length > 0;
          return canExpand ? (
            <span
              className="expander"
              onClick={() => {
                props.row.isExpanded = !props.row.isExpanded;
                props.row.setState((e: any) => {
                  return { ...e };
                });
              }}
            >
              <div className="flex nowrap">
                <div className="icon">{props.row.isExpanded ? "▼" : "▶"}</div>
                <DateTimeComponent
                  className="margin-x"
                  date={props.row.original.creationDate}
                  displayTime={true}
                ></DateTimeComponent>
              </div>
            </span>
          ) : null;
        },

        ChildComponent: (order: AwaitingOrderModel, row: any) => (
          <>
            <AwaitingOrdersChildTable
              orders={order.orders}
              isEditEnabled={false}
              parentState={row.state}
              setParentState={row.setState}
            ></AwaitingOrdersChildTable>
            {order.attachments?.length ? (
              <Attachments attachments={order.attachments} />
            ) : null}
          </>
        ),
      },
      {
        Header: "Opcje",
        accessor: "options",
        Cell: (props: { value: OptionModel[]; row: any }) => {
          return <>{props.value?.map((e) => e.value).join(", ")}</>;
        },
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          const _first =
            rowA.values[id]?.map((e: any) => e?.value).join(" ") ?? "";
          const _second =
            rowB.values[id]?.map((e: any) => e?.value).join(" ") ?? "";
          return _first.localeCompare(_second);
        },
      },
      {
        Header: "Rozpocznij o",
        accessor: "expectedStartingDate",
        Cell: (props: { value: string; row: any }) => (
          <DateTimeComponent date={props.value} displayDayOfWeek={true} />
        ),
      },
      {
        Header: "Zakończ do",
        accessor: "expectedCompletionDate",
        Cell: (props: { value: string; row: any }) => (
          <DateTimeComponent date={props.value} displayDayOfWeek={true} />
        ),
      },
      {
        Header: "Uwagi",
        accessor: "description",
        Cell: (props: { value: string; row: any }) => {
          return <>{props.value}</>;
        },
      },
      {
        Header: "Kod zamówienia",
        accessor: "orderId",
        Cell: (props: { value: string; row: any }) => {
          const color = stringToHexColor(props.value);
          return (
            <section className="flex">
              <div
                style={{
                  width: "0.5rem",
                  height: "1rem",
                  backgroundColor: `${color}`,
                }}
              ></div>
              {color}
            </section>
          );
        },
      },
      {
        id: "Action",
        accessor: "id",
        Cell: (props: { value: number; row: any; preparedRows: any }) => {
          return (
            <div className="flex flex-right gap">
              {onlyManagers.includes(user.role!) && (
                <div className="action flex flex-right">
                  <button
                    className="btn--active "
                    onClick={() => {
                      invokeEditView(props.row.original as AwaitingOrderModel);
                    }}
                  >
                    Edytuj
                  </button>
                </div>
              )}
              {allButUsers.includes(user.role!) && (
                <ConfirmButton
                  className="action flex flex-center-justify btn--active order-action"
                  confirmClassName="action flex flex-center-justify btn--alert order-action"
                  label={"Aktywuj"}
                  confirmLabel={"Aktywować?"}
                  action={() => activateOrder(props.row.original)}
                >
                  Aktywuj
                </ConfirmButton>
              )}
            </div>
          );
        },
      },
    ],
    [response, user]
  );

  return (
    <AwaitingOrdersStyle>
      <div className="orders-selections flex-center-justify">
        <div className="orders-selections__selections">
          {query.map((q, i) => (
            <FilterPillButton key={i} onClick={() => clearSelection(q.key)}>
              <>{q.label}</>
            </FilterPillButton>
          ))}
        </div>
        <section className="orders-selections__actions flex-right">
          <ViewMoreButton
            onClick={() => invokeFiltersView(options ?? [], listView)}
          ></ViewMoreButton>
        </section>
      </div>
      <AsyncComponent data={awaitingOrders} isLoading={isLoading} error={error}>
        {(orders: AwaitingOrderModel[]) => {
          return (
            <>
              {!listView ? (
                <Table columns={columns} data={orders ?? []}></Table>
              ) : (
                <ActiveOrdersViewStyle>
                  <List
                    list={orders}
                    className={"order-list order-list__wrapper"}
                  >
                    {(order: AwaitingOrderModel) => (
                      <OrderList
                        order={order}
                        isAwaitingOrder={true}
                        onOrderFinalized={() => activateOrder(order)}
                      />
                    )}
                  </List>
                </ActiveOrdersViewStyle>
              )}
            </>
          );
        }}
      </AsyncComponent>
      {view}
    </AwaitingOrdersStyle>
  );
}
