import { IDispatcher, IEvent, IResponse } from "../models/dispatcher/dispatcher-interfaces";
import { DispatcherEvent } from "../models/dispatcher/event";
import { Event } from "../models/dispatcher/dispatcher-events"
import { ISendableRequest } from "../models/dispatcher/dispatcher-requests"
import { State } from "../models/dispatcher/state";

export class Dispatcher implements IDispatcher {
    public state: State;
    private events: any;
    constructor() {
        this.events = {} as any;
        // here we can save state for every object that we want to keep state across current app lifecycle.
        // we can also hook into an event that will be raised on state change
        this.state = new State(this);
    }

    public UUID = (): string => {
        return Dispatcher.UUID();
    }

    public static UUID = (): string => {
        return ([1e7] as any + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: number) =>
            // eslint-disable-next-line no-mixed-operators
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16),
        );
    }
    // after dispatching event
    // dispatcher don't care about listeners of this event and don't expect unknown response from receiver(s)
    public dispatch = (eventName: Event | string, data?: unknown) => {
        const event = this.events[eventName];
        if (event) {
            event.fire(data);
        }
    }
    // after sending request, dispatcher expect to receive response from receiver(s).
    // After receive response from all receivers, promise with all outputs will be resolved.
    public sendRequest = (request: ISendableRequest<any>): Promise<IResponse<unknown>> => {
        const defer = () => {
            const deferred = {
                promise: null as any,
                resolve: null as any,
                reject: null as any,
            };
            deferred.promise = new Promise((resolve, reject) => {
                deferred.resolve = resolve;
                deferred.reject = reject;
            });
            return deferred;
        };

        const requestID = this.UUID();
        const output = defer();
        if (this.events[request.request] === undefined || this.events[request.request].callbacks.length === 0) {
            const message = "No listeners for this request: " + requestID;
            output.reject(message);
        }
        const requestCompleted = (response: IResponse<any>) => {
            if (response.response) {
                output.resolve(response);
            } else {
                output.reject(response);
            }
        };
        const dataToSend = {
            requestData: request.data,
            requestID,
            requestCompleted,
        };
        const event = this.events[request.request];
        if (event) {
            event.fire(dataToSend);
        }
        return output.promise;
    }
    public on = (eventName: string, callback?: (data: any) => any) => {
        const callbackId = this.UUID();
        let event = this.events[eventName] as IEvent;
        if (!event) {
            event = new DispatcherEvent(eventName);
            this.events[eventName] = event;
        }
        event.registerCallback(callbackId, callback);
        return () => { event.unregisterCallback(callbackId) }
    }

    public off = (unregisterCallback: () => void) => {
        unregisterCallback();
    }
}
