import styled from "styled-components";
import { TabComponent, TabItem } from "../components/common/TabComponent";
import { ManageProducts } from "../components/feature/ManageProducts";
import { ManageIngredients } from "../components/feature/ManageIngredients";
import { ManageUnits } from "../components/feature/ManageUnits";
import { ManageOrderOptions } from "../components/feature/ManageOrderOptions";
import { ManageGroups } from "../components/feature/ManageGroups";

const InventoryStyle = styled.div`
  .manage-tab {
    margin: 0.5rem;
    padding-bottom: 0.2rem;
  }
`;

export function InventoryView(props: { routeKey?: string }) {
  return (
    <InventoryStyle>
      <TabComponent selectedKey={props.routeKey}>
        <TabItem key="group" title="Grupy">
          <div className="manage-tab">
            <ManageGroups />
          </div>
        </TabItem>
        <TabItem key="product" title="Produkty">
          <div className="manage-tab">
            <ManageProducts />
          </div>
        </TabItem>
        <TabItem key="ingredient" title="Składniki">
          <div className="manage-tab">
            <ManageIngredients />
          </div>
        </TabItem>
        <TabItem key="unit" title="Jednostki">
          <div className="manage-tab">
            <ManageUnits />
          </div>
        </TabItem>
        <TabItem key="orderOption" title="Opcje">
          <div className="manage-tab">
            <ManageOrderOptions />
          </div>
        </TabItem>
      </TabComponent>
    </InventoryStyle>
  );
}
