const SvgOffline = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="100px"
      width="100px"
      viewBox="0 0 100 100"
      {...props}
    >
      <g xmlns="http://www.w3.org/2000/svg" id="g6">
        <path
          id="path8"
          d="M 84.5 16.6 L 73.7 27.4 C 50.8 18.2 23.6 22.9 5.1 41.4 l 6.4 6.4 C 26.4 33 47.8 28.5 66.6 34.5 l -7.4 7.4 c -14.4 -3 -30 1.1 -41.2 12.4 l 6.4 6.4 c 7.3 -7.3 17 -10.9 26.7 -10.6 l -11 11 c -3.4 1.3 -6.5 3.3 -9.3 6.1 l 1.6 1.6 l -11.6 11.4 l 3.5 3.5 L 88 20.1 L 84.5 16.6 Z"
        />
        <path
          id="path10"
          d="M 43.6 80 l 6.4 6.4 l 6.4 -6.4 c -3.5 -3.6 -9.3 -3.6 -12.8 0 z"
        />
        <path
          id="path12"
          d="M 46.6 68.6 c 5.7 -1.1 11.9 0.6 16.3 5 l 6.4 -6.4 C 65.4 63.3 60.5 60.8 55.5 59.8 l -8.9 8.8 z"
        />
        <path
          id="path14"
          d="M 62.8 52.4 c 4.7 1.8 9.1 4.5 12.9 8.3 l 6.4 -6.4 C 78.4 50.6 74.2 47.6 69.7 45.5 l -6.9 6.9 z"
        />
        <path
          id="path16"
          d="M 83.1 32.1 l -6.6 6.6 c 4.3 2.4 8.4 5.4 12.1 9.1 L 95 41.4 C 91.3 37.8 87.3 34.7 83.1 32.1 Z"
        />
      </g>
    </svg>
  );
};

export default SvgOffline;
