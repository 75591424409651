import { Loader } from "../components/common/Loader";
import { useEffect, useState } from "react";
import { Form, FieldType, FormModel } from "../components/common/Form";
import { Breakpoints } from "../styles/breakpoints";
import styled from "styled-components";
import { Link, Navigate } from "react-router-dom";
import useDispatcher from "../hooks/useDispatcher";
import { LoginRequest } from "../models/dispatcher/dispatcher-requests";
import { User } from "../models/api/user";
import useDispatcherState from "../hooks/useDispatcherState";
import { StoreKeysEnum } from "../store/storeKeysEnum";
import { APP_VERSION } from "../app-version";

const LoginContainer = styled.div`
  .app-version {
    display: flex;
    justify-content: right;
    color: var(--col-fg-secondary);
  }
  .login-page {
    width: 25rem;
    margin: auto;
    @media screen and (max-width: ${Breakpoints.Phone}px) {
      width: 100%;
    }
    &__form {
    }
    &__login-result {
      padding-top: 1rem;
      color: var(--col-fg-alert);
    }
    &__register {
      padding-top: 1rem;
      a {
        color: var(--col-fg-secondary);
      }
    }
  }
`;
export function LogInView() {
  const [loggingIn, setLoggingIn] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const { Dispatcher } = useDispatcher();
  const [user] = useDispatcherState<User>(StoreKeysEnum.User);

  const FormData = [
    {
      label: "username",
      displayLabel: "Adres e-mail / login",
      type: FieldType.Text,
      required: true,
    },
    {
      label: "password",
      displayLabel: "Hasło",
      type: FieldType.Password,
      required: true,
    },
  ];

  function tryToLogin(form: FormModel) {
    setLoggingIn(true);
    setLoginFailed(false);
    Dispatcher.sendRequest(
      new LoginRequest({
        username: form.model[0].value as string,
        password: form.model[1].value as string,
      })
    ).then((response: any) => {
      setLoggingIn(false);
      const resp = response.response as User;
      if (!resp.authenticated) {
        setLoginFailed(true);
      } else {
        setAuthenticated(true);
      }
    });
  }

  useEffect(() => {
    if (user?.authenticated) {
      setAuthenticated(true);
    }
  }, [user]);

  return (
    <LoginContainer>
      <div className="login-page">
        <Loader loaderVisible={loggingIn}></Loader>
        <div className="login-page__form">
          <Form
            title={"Zaloguj się"}
            labels={FormData}
            actionTitle={"Zatwierdź"}
            onSubmit={(model: any) => {
              tryToLogin(model);
            }}
          ></Form>
        </div>
        {loginFailed ? (
          <div className="login-page__login-result">{"Coś poszło nie tak"}</div>
        ) : null}
        {authenticated ? <Navigate to="/home" /> : null}
        <div className="login-page__register">
          <Link to="/register">Zarejestruj się</Link>
        </div>
      </div>
      <p className="app-version">v.{APP_VERSION}</p>
    </LoginContainer>
  );
}
