import styled from "styled-components";
import { List } from "../common/List";
import { ProductModel } from "../../models/api/product";
import { VariantModel } from "../../models/api/variant";
import { IngredientModel } from "../../models/api/ingredient";
import { formatNumber } from "../../helpers/formatter";
import useProduct from "../../hooks/useProduct";

const ReportSalesStyle = styled.section`
  white-space: nowrap;
  .sales-report {
    &__summary {
      font-size: 1.5rem;
      text-align: right;
      margin-top: 1rem;
      white-space: break-spaces;
    }
    &__products {
      border: var(--border-width) solid var(--col-fg-secondary);
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin-top: 0.5rem;
      .products {
        &__summary {
          text-align: right;
          padding: 0.2rem;
        }
      }
    }
  }
  .products-list {
    display: flex;
    flex-direction: column;
    padding: 0.2rem;
    .list {
      border-bottom: var(--border-width) solid var(--col-bg-secondary);
      flex-wrap: nowrap;
      &:last-child {
        border-bottom: unset;
      }
    }
    .description {
      white-space: break-spaces;
      text-align: left;
    }
  }
`;

interface IReportSales {
  ingredientsCost: number;
  labourCost: number;
  cashVolumeFromSales: number;
  soldProducts: [{ itemsSold: number; productId: number }];
  soldVariants: [{ itemsSold: number; variantId: number }];
  ingredientsUsed: [{ amount: number; ingredient: IngredientModel }];
}

export function ReportSales(props: IReportSales) {
  const { getProduct } = useProduct();

  const variantsExtended = props.soldVariants?.map((el) => {
    let _variant: VariantModel | undefined;
    props.soldProducts?.forEach((p) => {
      const _product = getProduct(p.productId);
      _product?.variants?.forEach((v) => {
        if (v.id === el.variantId) _variant = v;
      });
    });
    const _product = props.soldProducts?.find((p) =>
      getProduct(p.productId)?.variants?.find((v) => v.id === el.variantId)
    );
    return {
      product: getProduct(_product?.productId ?? -1),
      variant: _variant,
      itemsSold: el.itemsSold,
    };
  });

  return (
    <ReportSalesStyle>
      <div className="sales-report"></div>
      <div className="sales-report__products">
        <div>Sprzedaż produktów:</div>
        <List list={props.soldProducts} className="products-list">
          {(product: { productId: number; itemsSold: number }) => (
            <>
              <div className="description">
                {getProduct(product.productId)?.description}
              </div>
              <div className="amount">{product.itemsSold} szt.</div>
            </>
          )}
        </List>
      </div>
      <div className="sales-report__products">
        <div>Sprzedaż według wariantów:</div>
        <List list={variantsExtended} className="products-list">
          {(variant: {
            product: ProductModel;
            variant: VariantModel;
            itemsSold: number;
          }) => (
            <>
              <div className="description">
                {variant.product?.description} {variant.variant?.description}
              </div>
              <div className="amount">{variant.itemsSold} szt.</div>
            </>
          )}
        </List>
      </div>
      <div className="sales-report__products">
        <div>Zużycie produktów:</div>
        <List list={props.ingredientsUsed} className="products-list">
          {(item: { amount: number; ingredient: IngredientModel }) => (
            <>
              <div className="description">{item.ingredient.description}</div>
              <div className="amount">
                {formatNumber(item.amount)} {item.ingredient.unit?.description}
              </div>
            </>
          )}
        </List>
        <div className="products__summary">
          Łączny koszt produktów: {formatNumber(props.ingredientsCost)} zł
        </div>
        <div className="products__summary">
          Łączny koszt siły roboczej: {formatNumber(props.labourCost)} zł
        </div>
      </div>
      <div className="sales-report__summary">
        Łączna suma sprzedaży: {formatNumber(props.cashVolumeFromSales)} zł
      </div>
      <div className="sales-report__summary">
        Bilans:{" "}
        {formatNumber(
          props.cashVolumeFromSales - props.ingredientsCost - props.labourCost
        )}{" "}
        zł
      </div>
    </ReportSalesStyle>
  );
}
