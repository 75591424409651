import { Link } from "react-router-dom";
import styled from "styled-components";
import { ActionButton } from "../components/common/ActionButton";
import SvgOffline from "../components/icons/SvgOffline";
import useOnline from "../hooks/useOnline";

const OfflineStyle = styled.div`
  padding: 0.5rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  background-color: var(--col-bg-primary);
  text-align: left;
  h5 {
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .suggestions-list {
    margin: 2rem;
    font-size: 0.8rem;
  }
  .actions {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    justify-content: center;
    .action {
      justify-content: right;
      button {
        width: 100%;
        max-width: 30rem;
        a {
          width: 100%;
        }
      }
    }
  }
`;

export function OfflineView() {
  const online = useOnline();
  return (
    <OfflineStyle>
      <div className="flex-center">
        <SvgOffline></SvgOffline>
      </div>
      <h5>Wygląda na to, że nie masz dostępu do internetu</h5>
      <div className="suggestions-list">
        <p>Wypróbuj te rozwiązania:</p>
        <ul>
          <li>Ponownie połącz się z Wi-Fi</li>
          <li>Sprawdź czy masz aktywny pakiet internetowy</li>
          <li>Sprawdź kable sieciowe, modem i router</li>
        </ul>
      </div>
      <section className="actions">
        {online && (
          <ActionButton sticky={false} className="btn btn--active action">
            <Link to={"/home"}> Spróbuj ponownie</Link>
          </ActionButton>
        )}
      </section>
    </OfflineStyle>
  );
}
