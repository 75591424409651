import { useState } from "react";
import { Input } from "../common/Input";
import styled from "styled-components";
import { IngredientModel } from "../../models/api/ingredient";
import { StockModel } from "../../models/api/stock";
import { DropDown } from "../common/DropDown";
import { ConfirmButton } from "../common/ConfirmButton";

const NewStockStyle = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: left;
  white-space: nowrap;
  width: min(89.5vw, 30rem);
  .selector {
    width: 100%;
    input,
    .stock-dropdown {
      padding-left: 0.5rem;
      flex-basis: 50%;
    }
    padding: 0.2rem;
    padding-bottom: 1rem;
    border: var(--border-width) solid (var(--col-fg-active));
    font-size: 1rem;
  }
  .actions {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`;

export interface INewStock {
  stock?: StockModel;
  ingredients: IngredientModel[];
  getUnitName: (ingrediendId: number) => string;
  onStockModified: (stock: StockModel) => void;
}

export function ManageStockNewStock(props: INewStock) {
  const [updatedStock, setUpdatedStock] = useState<StockModel>(
    props.stock ? props.stock : {}
  );

  return (
    <NewStockStyle>
      <div>
        <div className="flex-center-justify selector">
          Składnik:
          <div className="stock-dropdown">
            <DropDown
              selectedIndex={props.ingredients?.findIndex(
                (el) => el.id === updatedStock.ingredientId
              )}
              items={
                props.ingredients
                  ? props.ingredients?.map((el) => {
                      return { key: el.id, value: el.description };
                    })
                  : []
              }
              onItemSelected={(e) =>
                setUpdatedStock((s) => {
                  return { ...s, ingredientId: e?.key };
                })
              }
            ></DropDown>
          </div>
        </div>
        <div className="flex-center-justify selector form-field">
          <label>
            Dostarczono ({props.getUnitName(updatedStock.ingredientId ?? 0)}):
          </label>
          <Input
            defaultValue={updatedStock.initialAmount ?? 0}
            type="number"
            min="0"
            onChange={(e) => {
              setUpdatedStock((s) => {
                return { ...s, initialAmount: Number(e) };
              });
            }}
          ></Input>
        </div>
        <div className="flex-center-justify selector form-field">
          <label>Cena (zł):</label>
          <Input
            defaultValue={updatedStock.price}
            type="number"
            min="0"
            onChange={(e) => {
              setUpdatedStock((s) => {
                return { ...s, price: Number(e) };
              });
            }}
          ></Input>
        </div>
        <div className="flex-center-justify selector form-field">
          <label>Opis:</label>
          <Input
            defaultValue={updatedStock.description}
            onChange={(e) => {
              setUpdatedStock((s) => {
                return {
                  ...s,
                  description: String(e),
                };
              });
            }}
          ></Input>
        </div>
      </div>
      {updatedStock.ingredientId && (updatedStock?.initialAmount ?? 0) > 0 && (
        <section className="actions flex-center-justify">
          <div></div>
          <ConfirmButton
            label="Zatwierdź zmiany"
            confirmLabel="Zatwierdzić zmiany?"
            action={() => props.onStockModified(updatedStock)}
          ></ConfirmButton>
        </section>
      )}
    </NewStockStyle>
  );
}
