import useDispatcher from "./useDispatcher";
import { updateActiveOrders } from "../helpers/updateActiveOrders";
import { useEffect } from "react";
import useDispatcherState from "./useDispatcherState";
import { ProductModel } from "../models/api/product";
import { StoreKeysEnum } from "../store/storeKeysEnum";

export default function useActiveOrders() {
  const dispatcher = useDispatcher();
  const [products] = useDispatcherState<ProductModel[]>(StoreKeysEnum.Products);
  const _action = () => updateActiveOrders(dispatcher.Dispatcher);
  useEffect(() => {
    _action();
  }, [products]);
  return _action;
}
