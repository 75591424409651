import styled from "styled-components";
import { useState } from "react";
import { TimeConstrainedPromo } from "../../models/api/timeConstrainedPromo";
import { RecurringPromo } from "../../models/api/recurringPromo";
import { PromoTypeEnum } from "../../models/enums/promoTypeEnum";
import { ModifiedInventoryModel } from "../../models/api/modifiedInventory";
import { BasePromo } from "../../models/api/basePromo";
import { ModifyRecurringPromo } from "./ModifyRecurringPromo";
import { ModifyTimeConstrainedPromo } from "./ModifyTimeConstrainedPromo";
import { ActionEnum } from "../../models/enums/actionEnum";
import { ConfirmButton } from "../common/ConfirmButton";
import { ProductVariantSelector } from "./ProductVariantSelector";
import { Input } from "../common/Input";

const ModifyPromoStyle = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: left;
  .actions {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .promo {
    &__selector {
      padding: 0.2rem;
      width: 100%;
      .dropdown {
        margin-left: 1rem;
        flex-basis: 100%;
      }
    }
    &__inputs {
      padding-top: 2rem;
      width: 100%;
    }
  }
  input,
  textarea {
    width: 50%;
    border: var(--border-width) solid (var(--col-fg-active));
    font-size: 1rem;
  }
`;

export interface IModifyPromoComponent {
  promo?: TimeConstrainedPromo | RecurringPromo;
  promoType: PromoTypeEnum;
  onPromoModified: (
    promo: ModifiedInventoryModel<TimeConstrainedPromo | RecurringPromo>
  ) => void;
}

export function ModifyPromo(props: IModifyPromoComponent) {
  const [updatedPromo, setUpdatedPromo] = useState<
    TimeConstrainedPromo | RecurringPromo | BasePromo
  >(
    props.promo
      ? props.promo
      : {
          minQuantity: 1,
          maxQuantity: 1,
          percentageDiscountPerItem: 0,
          description: "Nowa promocja",
        }
  );

  function onApply(promo: RecurringPromo | TimeConstrainedPromo) {
    const action: ActionEnum = promo.id ? ActionEnum.Update : ActionEnum.Create;
    if (promo.variantId) promo.productId = undefined;
    const modifiedPromo = { item: promo, action: action };
    props.onPromoModified(modifiedPromo);
  }

  function onDelete(promo: RecurringPromo | TimeConstrainedPromo) {
    if (promo.variantId) promo.productId = undefined;
    const modifiedPromo = { item: promo, action: ActionEnum.Delete };
    props.onPromoModified(modifiedPromo);
  }

  return (
    <ModifyPromoStyle>
      <section>
        <div className="promo__selector">
          <ProductVariantSelector
            productId={props.promo?.productId}
            variantId={props.promo?.variantId}
            onSelection={(product, variant) =>
              setUpdatedPromo((old) => {
                return {
                  ...old,
                  productId: product?.id,
                  variantId: variant?.id,
                };
              })
            }
          ></ProductVariantSelector>
        </div>
        <section className="promo__inputs">
          <div className="form-field flex-center-justify">
            <label htmlFor="description">Opis:</label>
            <textarea
              id="description"
              value={updatedPromo.description}
              onChange={(e) =>
                setUpdatedPromo((old) => {
                  return { ...old, description: e.target.value };
                })
              }
            ></textarea>
          </div>
          <div className="form-field flex-center-justify">
            <label htmlFor="minQty">Minimalna ilość:</label>
            <Input
              id="minQty"
              type="number"
              min={1}
              value={updatedPromo.minQuantity}
              onChange={(e) =>
                setUpdatedPromo((old) => {
                  const value = Number(e);
                  const maxQty =
                    Number(old.maxQuantity) > value ? old.maxQuantity : value;
                  return {
                    ...old,
                    minQuantity: value,
                    maxQuantity: maxQty,
                  };
                })
              }
            ></Input>
          </div>
          <div className="form-field flex-center-justify">
            <label htmlFor="maxQty">Maksymalna ilość:</label>
            <Input
              id="maxQty"
              type="number"
              min={updatedPromo.minQuantity}
              value={updatedPromo.maxQuantity}
              onChange={(e) =>
                setUpdatedPromo((old) => {
                  const value = Number(e);
                  return { ...old, maxQuantity: value };
                })
              }
            ></Input>
          </div>
          <div className="form-field flex-center-justify">
            <label htmlFor="discount">Procentowa zniżka na el:</label>
            <Input
              id="discount"
              type="number"
              min={0}
              max={100}
              value={updatedPromo.percentageDiscountPerItem}
              onChange={(e) =>
                setUpdatedPromo((old) => {
                  return {
                    ...old,
                    percentageDiscountPerItem: Number(e),
                  };
                })
              }
            ></Input>
          </div>
          <section className="form-field flex-center-justify">
            {props.promoType === PromoTypeEnum.RecurringPromo ? (
              <ModifyRecurringPromo
                promo={updatedPromo as RecurringPromo}
                onPromoModified={(promo) => setUpdatedPromo(promo)}
              />
            ) : (
              <ModifyTimeConstrainedPromo
                promo={updatedPromo as TimeConstrainedPromo}
                onPromoModified={(promo) => setUpdatedPromo(promo)}
              />
            )}
          </section>
          <div className="form-field flex-center-justify">
            <label htmlFor="promoCode">Kod promocyjny:</label>
            <Input
              id="promoCode"
              type="string"
              min={0}
              max={100}
              value={updatedPromo.promoCode}
              onChange={(e) =>
                setUpdatedPromo((old) => {
                  return {
                    ...old,
                    promoCode: e !== "" ? String(e) : undefined,
                  };
                })
              }
            ></Input>
          </div>
        </section>
      </section>
      {(updatedPromo.productId || updatedPromo.variantId) && (
        <section className="actions flex-center-justify">
          <ConfirmButton
            label="Usuń promocję"
            confirmLabel="Usunąć promocję?"
            action={() =>
              onDelete(updatedPromo as TimeConstrainedPromo | RecurringPromo)
            }
          ></ConfirmButton>
          <ConfirmButton
            label="Zatwierdź zmiany"
            confirmLabel="Zatwierdzić zmiany?"
            action={() =>
              onApply(updatedPromo as TimeConstrainedPromo | RecurringPromo)
            }
          ></ConfirmButton>
        </section>
      )}
    </ModifyPromoStyle>
  );
}
