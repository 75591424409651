import useDispatcher from "../../hooks/useDispatcher";
import { LogoutRequest } from "../../models/dispatcher/dispatcher-requests";

export function LogoutRoute() {
  const dispatcher = useDispatcher();
  dispatcher.Dispatcher.sendRequest(new LogoutRequest()).then(
    (r) => r.isExecutedSuccesfully && window.location.reload()
  );
  return <></>;
}
