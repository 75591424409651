import styled from "styled-components";
import { OrderModel } from "../../models/api/order";
import React, { useEffect, useState, useRef } from "react";
import { RestrictedElement } from "../common/RestrictedElement";
import { Role, User } from "../../models/api/user";
import { OrderStateEnum } from "../../models/enums/orderStateEnum";
import useDispatcherState from "../../hooks/useDispatcherState";
import { StoreKeysEnum } from "../../store/storeKeysEnum";
import useSide from "../../hooks/useSide";
import useProduct from "../../hooks/useProduct";
import useVariant from "../../hooks/useVariant";

const OrderStyle = styled.section`
  width: 100%;
  background-color: var(--col-bg-focus);
  max-width: 30rem;
  min-width: 15rem;
  position: relative;
  .order-card {
    &__header {
      display: flex;
      justify-content: space-between;
    }
    &__btn {
      width: 100%;
    }
    &__elapsedTime {
      text-align: left;
    }
    &__productName {
      display: flex;
      gap: 0.5rem;
      justify-content: center;
      width: 80%;
      margin: 0 auto;
      .productName {
        &__sequence {
          text-align: left;
        }
      }
    }
    &__variant {
      display: flex;
      justify-content: center;
    }
    &__remarks {
    }
    &__cancel {
      cursor: pointer;
      font-size: 2rem;
      position: absolute;
      top: -0.65rem;
      left: -1.15rem;
      margin: 1rem;
      line-height: 0.5rem;
    }
    &__sides {
      .side {
        display: flex;
        margin-left: 1rem;
        gap: 1rem;
        &::before {
          content: " + ";
        }
      }
    }
  }
  .confirmation-dialog {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white !important;
    &__actions {
      display: flex;
      opacity: 1;
      display: flex;
      gap: 1rem;
      height: auto;
      width: 100%;
      margin: 0.3rem;
    }
  }
`;

export interface IOrder {
  order: OrderModel;
  onOrderFinalized: (order: OrderModel) => void;
  isAwaitingOrder?: boolean;
}

export function Order(props: IOrder) {
  const [isSubmited, setIsSubmited] = useState(false);
  const [user] = useDispatcherState<User>(StoreKeysEnum.User);
  const [confirmDialog, setConfirmDialog] =
    useState<React.ReactElement | null>();
  const cardRef = useRef<HTMLDivElement>(null);

  const { getSide } = useSide();
  const { product } = useProduct(props.order.productId);
  const { variant } = useVariant(props.order.variantId);

  const sides = props.order?.sideIds?.map((sideId) => getSide(sideId));

  function finalizeOrder(order: OrderModel) {
    setConfirmDialog(null);
    setIsSubmited(true);
    props.onOrderFinalized({ ...order, state: OrderStateEnum.Completed });
  }

  function cancelOrder(order: OrderModel) {
    setConfirmDialog(null);
    setIsSubmited(true);
    props.onOrderFinalized({ ...order, state: OrderStateEnum.Cancelled });
  }

  function onLongPress(e?: Event) {
    e?.preventDefault();
    const allowed = [Role.Admin, Role.Manager];
    user &&
      user.role &&
      allowed.includes(user.role) &&
      !props.isAwaitingOrder &&
      showConfirmationDialog("Anulować zamówienie?", () =>
        cancelOrder(props.order)
      );
  }

  function showConfirmationDialog(title: string, action: () => void) {
    const _dialog = (
      <div className="confirmation-dialog">
        <div>{title}</div>
        <span className="confirmation-dialog__actions">
          <button
            className="order-card__btn btn--active"
            onClick={() => setConfirmDialog(null)}
          >
            Nie
          </button>
          <button className="order-card__btn btn--alert" onClick={action}>
            Tak
          </button>
        </span>
      </div>
    );
    setConfirmDialog(_dialog);
  }

  useEffect(() => {
    setIsSubmited(
      props.order.state === OrderStateEnum.Completed ? true : false
    );
  }, [props.order]);

  useEffect(() => {
    cardRef.current?.addEventListener("contextmenu", onLongPress);
    return () => {
      cardRef.current?.removeEventListener("contextmenu", onLongPress);
    };
  }, [props.order]);

  return (
    <OrderStyle>
      <div className="order-card" ref={cardRef}>
        <span>
          <section className="order-card order-card__productName">
            {props.order.product?.displaySequence && (
              <span className="product-name__sequence">
                {product?.displaySequence}
              </span>
            )}
            <span className="product-name__description">
              {product?.description}
            </span>
          </section>
          <section className="order-card order-card__variant">
            {variant?.description}
          </section>
          {sides && (
            <section className="order-card order-card__sides">
              {sides?.map((side, i) => (
                <span className="side" key={String(side?.id) + i}>
                  {side?.description}
                </span>
              ))}
            </section>
          )}
          <section className="order-card order-card__remarks">
            {props.order.remarks}
          </section>
        </span>
        {!props.isAwaitingOrder && (
          <RestrictedElement restrictions={[Role.User]}>
            <span className="flex-center-justify">
              <button
                className={`order-card__btn btn--active ${
                  isSubmited ? "btn--disabled" : "btn--active"
                }`}
                onClick={() =>
                  showConfirmationDialog("Zrealizować zamówienie?", () =>
                    finalizeOrder(props.order)
                  )
                }
              >
                {isSubmited ? "Zrealizowano" : "Zrealizuj zamówienie"}
              </button>
            </span>
          </RestrictedElement>
        )}
        {!props.isAwaitingOrder && (
          <RestrictedElement restrictions={[Role.User, Role.Employee]}>
            <button
              className="btn--text-only order-card__cancel"
              onClick={() => onLongPress()}
            >
              &times;
            </button>
          </RestrictedElement>
        )}
      </div>
      {confirmDialog && <>{confirmDialog}</>}
    </OrderStyle>
  );
}
