import styled from "styled-components";
import { OrderModel } from "../../models/api/order";
import { useMemo } from "react";
import { Table } from "../common/Table";
import useProduct from "../../hooks/useProduct";
import useVariant from "../../hooks/useVariant";
import useSide from "../../hooks/useSide";
import { formatDateToLocaleString } from "../../helpers/formatter";
import intervalToDuration from "date-fns/intervalToDuration";
import { Row } from "react-table";
import { User } from "../../models/api/user";
import { OrderStateEnum } from "../../models/enums/orderStateEnum";

const ReportSalesStyle = styled.section`
  .sales-report {
    &__grid {
      border: var(--border-width) solid var(--col-fg-secondary);
      margin-top: 0.5rem;
    }
  }
`;

interface IReportSales {
  orders: OrderModel[];
  users: User[];
}

export function ReportSalesGrid(props: IReportSales) {
  const { getProduct } = useProduct();
  const { getVariant } = useVariant();
  const { getSide } = useSide();
  const { users } = { ...props };
  const getDuration = (row: OrderModel) => {
    const duration = row.completionDate
      ? intervalToDuration({
          start: new Date(row.creationDate ?? 0),
          end: new Date(row.completionDate ?? 0),
        })
      : null;
    if (duration) {
      return duration.hours ? 999 : duration.minutes;
    } else return -1;
  };

  const columns = useMemo(
    () => [
      {
        Header: "Produkt",
        accessor: "fkProductId",
        Cell: (props: { value: number; row: Row }) =>
          getProduct(props.value)?.description ?? null,
      },
      {
        Header: "Wariant",
        accessor: "fkVariantId",
        Cell: (props: { value: number; row: Row }) =>
          getVariant(props.value)?.description ?? null,
      },
      {
        Header: "Zamówiono",
        accessor: "creationDate",
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          const _first = new Date(rowA.values[id]).getTime();
          const _second = new Date(rowB.values[id]).getTime();
          if (_first > _second) return 1;
          if (_second > _first) return -1;
          return 0;
        },
        Cell: (props: { value: string; row: Row }) =>
          formatDateToLocaleString(props.value),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (props: { value: OrderStateEnum; row: Row }) => {
          const state = props.value;
          switch (state) {
            case OrderStateEnum.Cancelled:
              return "Anulowane";
            case OrderStateEnum.Awaiting:
              return "Oczekujące";
            case OrderStateEnum.InProgress:
              return "W realizacji";
            case OrderStateEnum.Completed:
              return "Zakończone";
            default:
              return "Nieznany";
          }
        },
      },
      {
        Header: "Czas realizacji",
        accessor: "duration",
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          const _first = Number(rowA.values[id]);
          const _second = Number(rowB.values[id]);
          if (_first > _second) return 1;
          if (_second > _first) return -1;
          return 0;
        },
        Cell: (props: { value: number; row: any }) =>
          props.value > -1 && props.value < 60
            ? props.value + " min"
            : props.value > 60
            ? ">1h"
            : "brak",
      },
      {
        Header: "Zamówił",
        accessor: "fkUserIdSeller",
        Cell: (props: { value: number }) => {
          const user = users?.find((e) => e.id === props.value);
          return user ? user.firstName + " " + user.lastName : "brak";
        },
      },
      {
        Header: "Zrealizował",
        accessor: "fkUserIdMaker",
        Cell: (props: { value: number }) => {
          const user = users?.find((e) => e.id === props.value);
          return user ? user.firstName + " " + user.lastName : "brak";
        },
      },
      {
        Header: "Cena",
        accessor: "price",
      },
      {
        Header: "Dodatki",
        accessor: "sidesIds",
        Cell: (props: { value?: number[]; row: any }) =>
          props.value
            ? props.value.map((e) => getSide(e)?.description).join(", ")
            : "nie",
      },
      {
        Header: "Promocja",
        accessor: "fkPromoId",
        Cell: (props: { value?: number; row: any }) =>
          props.value ? "tak" : "nie",
      },
    ],
    [users]
  );
  return (
    <ReportSalesStyle>
      <div className="sales-report__products">
        <div>{`Zamówienia (${props.orders.length} elementów):`}</div>
        <div className="sales-report__grid">
          <Table
            data={props.orders.map((o) => {
              return { ...o, duration: getDuration(o) };
            })}
            columns={columns}
          ></Table>
        </div>
      </div>
    </ReportSalesStyle>
  );
}
