import { getSide } from "../helpers/getSide";
import useDispatcher from "./useDispatcher";

export default function useSide(sideId?: number) {
  const dispatcher = useDispatcher();

  const getSideFn = (id: number) => {
    return getSide(id, dispatcher.Dispatcher);
  };

  return {
    side: getSide(sideId ?? NaN, dispatcher.Dispatcher),
    getSide: getSideFn,
  };
}
