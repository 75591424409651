import styled from "styled-components";
import { useEffect, useState, useMemo } from "react";
import { ProductModel } from "../../models/api/product";
import { SideModel } from "../../models/api/side";
import CheckboxFilter from "../common/CheckboxFilter";

const SidesSelectorStyle = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  .selector {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0.2rem;
    .label {
      flex-basis: 20%;
      text-align: left;
    }
    .dropdown {
      flex-basis: 80%;
    }
  }
`;

export interface ISideSelector {
  product: ProductModel;
  onSelectionChanged: (sides: SideModel[]) => void;
  className?: string;
}

export function SideSelector(props: ISideSelector) {
  const [sides, setSides] = useState<{ key: any; value: any }[]>();

  useEffect(() => {
    sides &&
      props.product.sides &&
      props.onSelectionChanged(
        sides.map((e) => props.product.sides?.find((s) => s.id === e.key)!)
      );
  }, [sides]);

  const checkobox = useMemo(
    () => (
      <SidesSelectorStyle>
        <section className={"selector flex-center-justify " + props.className}>
          <CheckboxFilter
            onSelectionChanged={(e) => setSides(e)}
            multiSelectMode={true}
            items={
              props.product?.sides?.map((e) => {
                return { key: e.id, value: e.description, selected: false };
              }) ?? []
            }
          ></CheckboxFilter>
        </section>
      </SidesSelectorStyle>
    ),
    [props.product?.sides]
  );

  return <>{checkobox}</>;
}
