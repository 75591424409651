export enum Event {
  // ui events
  ShowModal = "showModal",
  ShowLoader = "showLoader",
  CloseLoader = "closeLoader",
  ResetApp = "resetApp",
  // gestures events
  SwipeUp = "swipeUp",
  SwipeDown = "swipeDown",
  SwipeLeft = "swipeLeft",
  SwipeRight = "swipeRight",
  // app events
  AppLoaded = "appLoaded"
}
