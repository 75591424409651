import useDispatcherState from "../../hooks/useDispatcherState";
import { User } from "../../models/api/user";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { StoreKeysEnum } from "../../store/storeKeysEnum";

export function HomeRoute() {
  const [user] = useDispatcherState<User>(StoreKeysEnum.User);
  return (
    <>
      {user ? (
        <Navigate to={"/order"}></Navigate>
      ) : (
        <Navigate to={"/login"}></Navigate>
      )}
    </>
  );
}
