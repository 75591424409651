export const getDefaultFontSize = (actual = false) => {
  if (!actual) {
    let isMobile = window.matchMedia("(any-pointer:coarse)").matches;
    return isMobile ? 12 : 16;
  }
  const element = document.createElement("div");
  element.style.width = "1rem";
  element.style.display = "none";
  document.body.append(element);

  const widthMatch = window
    .getComputedStyle(element)
    .getPropertyValue("width")
    .match(/\d+/);

  element.remove();

  if (!widthMatch || widthMatch.length < 1) {
    return null;
  }

  const result = Number(widthMatch[0]);
  return !isNaN(result) ? result : 12;
};
