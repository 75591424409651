import useDispatcherState from "../hooks/useDispatcherState";
import { ProductModel } from "../models/api/product";
import { AsyncComponent } from "../components/common/AsyncComponent";
import { List } from "../components/common/List";
import { Product } from "../components/feature/Product";
import { GenericView } from "../components/common/GenericView";
import { SelectVariantView } from "./SelectVariantView";
import { useEffect, useMemo, useState } from "react";
import { IOrder } from "../models/app/IOrder";
import styled from "styled-components";
import { ProductStateEnum } from "../models/enums/productStateEnum";
import { StoreKeysEnum } from "../store/storeKeysEnum";
import { ActiveOrderModel } from "../models/api/activeOrderModel";
import { AppSettingModel } from "../models/api/appSettingModel";
import { AppSettingsKeys } from "../models/app/appSettingsKeys";
import { OrderStateEnum } from "../models/enums/orderStateEnum";
import { GroupModel } from "../models/api/group";
import SvgAngleDown from "../components/icons/SvgAngleDown";
import SvgAngleRight from "../components/icons/SvgAngleRight";
import getCssVariableValue from "../helpers/getCssVariableValue";
import { hslToRGB, hexToRGB } from "../helpers/hexToRgba";

const MakeOrderStyle = styled.div`
  .order-list {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 33%;
    flex-grow: 1;
    justify-content: space-between;
  }
  .groups {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
    & .group__content {
      flex-basis: 100%;
      padding-left: 1rem;
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
      margin-top: -0.5rem;
      background: var(--expanded-background);
    }
    & .expanded {
      flex-grow: 1000;
      display: flex;
      flex-direction: column;
      &::after {
        content: "";
        height: 0.25rem;
        width: 100%;
        background-color: var(--expanded-background);
      }
    }
    & .collapsed {
      flex-grow: 1;
    }
    .group {
      display: flex;
      &__dropdown {
        flex-grow: 1;
        .btn--big {
          white-space: break-spaces !important;
          text-align: left;
        }
        .dropdown {
          display: flex;
          gap: 1rem;
          width: 100%;
          svg {
            margin-bottom: 0.5rem;
            path {
              fill: var(--col-bg-primary);
            }
          }
        }
      }
    }
  }
`;

export function MakeOrderView() {
  const [order, setOrder] = useDispatcherState<ActiveOrderModel>(
    StoreKeysEnum.Order
  );
  const [groups] = useDispatcherState<GroupModel[]>(StoreKeysEnum.Groups);
  const [expandedGroupId, setExpandedGroupId] = useState<number | undefined>();
  const [expandedElement, setExpandedElement] = useState<any>();
  const [notGrouped, setNotGrouped] = useState<ProductModel[] | undefined>();
  const [variantView, setVariantView] = useState<any>();
  const [serverSettings] = useDispatcherState<AppSettingModel[]>(
    StoreKeysEnum.ServerSettings
  );
  const defaultOrderState =
    (serverSettings?.find((e) => e.key === AppSettingsKeys.DefaultNewOrderState)
      ?.value as OrderStateEnum) ?? OrderStateEnum.InProgress;

  const onProductAddedToOrder = (order: IOrder) => {
    const {
      product,
      variant,
      sides,
      remarks,
      state = defaultOrderState,
    } = order;
    setOrder((old) => {
      const order = old?.orders
        ? [...old.orders, { product, variant, sides, remarks, state }]
        : [{ product, variant, sides, remarks, state }];
      return { orders: order };
    });
    onProductSelected(product, true);
  };

  const onProductSelected = (product: ProductModel, viewClosed?: boolean) => {
    setVariantView(
      <GenericView
        viewTitle={product?.description || ""}
        closed={viewClosed}
        onClose={() => {
          setVariantView(null);
        }}
      >
        <SelectVariantView
          onProductAddedToOrder={onProductAddedToOrder}
          product={product}
        ></SelectVariantView>
      </GenericView>
    );
  };

  useEffect(() => {
    const notGrouped = groups
      ?.filter((e) => !e.id)
      ?.map((e) => e.products!)
      ?.flat();
    setNotGrouped(notGrouped);
  }, [groups]);

  useEffect(() => {
    const el = document.querySelector(`.group-id-${expandedGroupId}`);
    const group = groups?.find((e) => e.id === expandedGroupId);
    if (el && group) {
      const color = getCssVariableValue(group.colorCode ?? "--col-fg-active");
      const background = color.startsWith("#")
        ? hexToRGB(color).replace("rgb(", "").replace(")", "")
        : hslToRGB(color).replace("rgb(", "").replace(")", "");
      const target = `rgba(${background}, 0.5)`;
      document.documentElement.style.setProperty(
        "--expanded-background",
        target
      );
      el.getBoundingClientRect().top <= 0 && el.scrollIntoView();
    }
  }, [expandedGroupId, groups]);

  const productList = useMemo(
    () => (
      <AsyncComponent
        data={groups}
        isLoading={groups ? false : true}
        error={false}
      >
        {(groups: GroupModel[]) => {
          return (
            <>
              <div className="groups" key={expandedGroupId}>
                {groups
                  .filter((e) => e.id)
                  .map((e) => (
                    <>
                      <div
                        onClick={() => {
                          expandedGroupId === e.id
                            ? setExpandedGroupId(undefined)
                            : setExpandedGroupId(e.id);
                        }}
                        className={`group ${
                          expandedGroupId === e.id ? "expanded" : "collapsed"
                        }`}
                      >
                        <div
                          className={`group__dropdown ${
                            expandedGroupId === e.id ? "expanded" : "collapsed"
                          } group-id-${e.id}`}
                        >
                          <button
                            className="dropdown btn btn--active btn--big product__btn"
                            style={{
                              backgroundColor: `var(${e.colorCode})`,
                            }}
                          >
                            <div className="dropdown__icon">
                              {expandedGroupId === e.id ? (
                                <SvgAngleDown
                                  height="1.8rem"
                                  width="1.8rem"
                                  viewBox="0 0 350 500"
                                />
                              ) : (
                                <SvgAngleRight
                                  height="1.8rem"
                                  width="1.8rem"
                                  viewBox="0 0 350 500"
                                />
                              )}
                            </div>
                            <div className="dropdown__description">
                              {e.description}
                            </div>
                            <div></div>
                          </button>
                        </div>
                      </div>
                      {expandedGroupId === e.id && (
                        <>
                          <div className="group__content">
                            <List list={e.products}>
                              {(product: ProductModel) => (
                                <>
                                  {product.state ===
                                    ProductStateEnum.Active && (
                                    <Product
                                      product={product}
                                      onProductSelected={onProductSelected}
                                    ></Product>
                                  )}
                                </>
                              )}
                            </List>
                          </div>
                        </>
                      )}
                    </>
                  ))}
              </div>
              {notGrouped && (
                <List list={notGrouped}>
                  {(product: ProductModel) => (
                    <>
                      {product.state === ProductStateEnum.Active && (
                        <Product
                          product={product}
                          onProductSelected={onProductSelected}
                        ></Product>
                      )}
                    </>
                  )}
                </List>
              )}
            </>
          );
        }}
      </AsyncComponent>
    ),
    [groups, notGrouped, expandedGroupId, expandedElement]
  );

  return (
    <MakeOrderStyle>
      {productList}
      {variantView}
    </MakeOrderStyle>
  );
}
