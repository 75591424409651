import styled from "styled-components";
import { ActionButton } from "../common/ActionButton";
import { useState } from "react";
import useDispatcherState from "../../hooks/useDispatcherState";
import { StoreKeysEnum } from "../../store/storeKeysEnum";
import { OptionModel } from "../../models/api/optionModel";
import { AwaitingOrderModel } from "../../models/api/awaitingOrderModel";
import { stringToHexColor } from "../../helpers/stringToHexColor";
import { DateTimeComponent } from "../common/DateTimeComponent";
import { OrderOptions } from "./OrderOptions";

const AwaitingOrdersChangeOrderStyle = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
  .order-edits {
  }
  .order-inputs {
    .input {
      margin-top: 0.5rem;
      .label {
        margin-left: 0.5rem;
      }
    }
  }
`;

export interface IAwaitingOrdersChangeOrder {
  order: AwaitingOrderModel;
  onOrderModified: (order: AwaitingOrderModel) => void;
}

export const AwaitingOrdersChangeOrder = (
  props: IAwaitingOrdersChangeOrder
) => {
  const [modifiedOrder, setModifiedOrder] = useState(props.order);
  const [availableOptions] = useDispatcherState<OptionModel[]>(
    StoreKeysEnum.AvailableOrderOptions
  );

  return (
    <AwaitingOrdersChangeOrderStyle>
      <section className="order-edits">
        <section className="order-inputs">
          <div className="form-field flex-center-justify margin-y">
            <label>Kod zamówienia:</label>
            <section className="flex">
              <div
                style={{
                  width: "0.5rem",
                  height: "1rem",
                  backgroundColor: `${stringToHexColor(props.order.orderId)}`,
                }}
              ></div>
              {stringToHexColor(props.order.orderId)}
            </section>
          </div>
          <div className="form-field flex-center-justify margin-y">
            <label>Data złożenia:</label>
            <section className="text-right">
              <DateTimeComponent date={props.order.creationDate!} />
            </section>
          </div>
          <hr />
          <OrderOptions
            order={modifiedOrder}
            onOrderModified={(order) =>
              setModifiedOrder(order as AwaitingOrderModel)
            }
            showDatePickers={true}
            showAttachmentPicker={true}
          ></OrderOptions>
        </section>
      </section>
      <div className="order__actions flex-center-justify">
        <div></div>
        <ActionButton
          label="Zatwierdź zmiany"
          fitToContent={true}
          sticky={false}
          onClick={() => {
            props.onOrderModified(modifiedOrder);
          }}
        ></ActionButton>
      </div>
    </AwaitingOrdersChangeOrderStyle>
  );
};
