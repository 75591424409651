import styled from "styled-components";
import { ReactElement, useState } from "react";
import { GenericView } from "../common/GenericView";
import { ActiveOrderModel } from "../../models/api/activeOrderModel";
import { DateTimeComponent } from "../common/DateTimeComponent";
import { OrderStateEnum } from "../../models/enums/orderStateEnum";
import { OrderOptionsContent } from "./CreateOrderOptionsContent";
import useIsOrderValid from "../../hooks/useIsOrderValid";

const CreateOrderStyle = styled.section`
  .invalid {
    border: 1px solid var(--col-fg-alert);
    border-radius: var(--border-radius, 0.1rem);
  }
  display: flex;
  justify-content: space-between;

  gap: 1rem;
  font-size: 0.8rem;
  .order-options {
    padding: 0.5rem;
    text-align: left;
    flex-wrap: wrap;
    gap: 2rem;
    &__option {
      gap: 0.5rem;
      > div {
      }
    }
  }
`;

export interface IOrderOptions {
  order: ActiveOrderModel;
  onOrderModified: (order: ActiveOrderModel) => void;
}

export function CreateOrderOptions(props: IOrderOptions) {
  const [view, setView] = useState<ReactElement>();
  const isOrderValid = useIsOrderValid(props.order ?? undefined);

  const showOrderOptions = (order: ActiveOrderModel, viewClosed?: boolean) => {
    setView(
      <GenericView
        viewTitle={"Zmień opcje zamówienia"}
        closed={viewClosed}
        onClose={() => setView(undefined)}
        fitToContent={false}
      >
        <OrderOptionsContent
          order={order}
          onOrderModified={(e) => {
            props.onOrderModified(e);
            showOrderOptions(e, true);
          }}
        ></OrderOptionsContent>
      </GenericView>
    );
  };

  return (
    <>
      <CreateOrderStyle>
        <section className={`order-options flex ${!isOrderValid && "invalid"}`}>
          <section className="order-options__option">
            <div>Rozpocznij: </div>
            <div>
              {props.order.expectedStartingDate ? (
                <DateTimeComponent
                  date={props.order.expectedStartingDate}
                ></DateTimeComponent>
              ) : props!.order!.orders![0].state === OrderStateEnum.Awaiting ? (
                "nie określono"
              ) : (
                "natychmiast"
              )}
            </div>
          </section>
          <section className="order-options__option">
            <div>Zakończ: </div>
            <div>
              {props.order.expectedCompletionDate ? (
                <DateTimeComponent
                  date={props.order.expectedCompletionDate}
                ></DateTimeComponent>
              ) : (
                "tak szybko jak to możliwe"
              )}
            </div>
          </section>
          <section className="order-options__option">
            <div>Uwagi: </div>
            <div>
              {props.order.description
                ? props.order.description !== ""
                  ? props.order.description
                  : "brak"
                : "brak"}
            </div>
          </section>
          <section className="order-options__option">
            <div>Załączniki: </div>
            <div>
              {props.order.attachments?.length
                ? props.order.attachments.map((e) => e.fileName).join(", ")
                : "brak"}
            </div>
          </section>
          <section className="order-options__option">
            <div>Opcje: </div>
            <div>
              {props.order.options?.length
                ? props.order.options.map((e) => e.value).join(", ")
                : "brak"}
            </div>
          </section>
        </section>

        <div className="action flex flex-right">
          <button
            className="btn btn--active"
            onClick={() => showOrderOptions(props.order)}
          >
            Zmień
          </button>
        </div>
      </CreateOrderStyle>
      {view}
    </>
  );
}
