import { IDispatcher } from "../models/dispatcher/dispatcher-interfaces";
import { StoreKeysEnum } from "../store/storeKeysEnum";
import { AppSettings } from "../models/app/app-settings";
import { INotificationService } from "../models/app/INotifcationService";

export class NotificationService implements INotificationService {
  private _dispatcher: IDispatcher;

  constructor(dispatcher: IDispatcher) {
    this._dispatcher = dispatcher;
  }

  public notify(message?: string) {
    const settings: AppSettings = this._dispatcher.state.getState(
      StoreKeysEnum.AppSettings
    );
    const audio = new Audio(
      new URL(window.location.href).origin + "/notify.mp3"
    );
    if (settings.notificationsEnabled) {
      audio.play();
    }
  }
}
