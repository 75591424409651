import "./styles/global.scss";
import { Routes, Route } from "react-router";
import { Layout } from "./components/Layout";
import { LoginRoute } from "./routes/login/Login";
import { OfflineRoute } from "./routes/offline/Offline";
import { HomeRoute } from "./routes/home/Home";
import { Modal } from "./components/common/Modal";
import { RegisterRoute } from "./routes/register/Register";
import { Role, User } from "./models/api/user";
import useDispatcherState from "./hooks/useDispatcherState";
import useDispatcher from "./hooks/useDispatcher";
import { RestrictedRoutes } from "./routes/restrictedRoutes";
import { Toast } from "./components/common/Toast";
import { StoreKeysEnum } from "./store/storeKeysEnum";
import { GetCurrentUserRequest } from "./models/dispatcher/dispatcher-requests";
import { useEffect } from "react";
import { Loader } from "./components/common/Loader";
import {
  AuthenticationUrl,
  GeneralUrl,
  InventoryUrl,
  OrdersUrl,
} from "./config";
import { productsOrdered } from "./helpers/productsOrdered";
import axios, { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import useOnline from "./hooks/useOnline";

export default function App() {
  const dispatcher = useDispatcher();
  const [user] = useDispatcherState<User>(StoreKeysEnum.User);
  const navigate = useNavigate();
  const online = useOnline();

  const getContentAreaHeight = () => {
    const el = document.querySelector(".main-container");
    return el ? globalThis.getComputedStyle(el).height : "";
  };

  //add variable to accomodate full screen height for android
  document.documentElement.style.setProperty(
    "--vh",
    `${window.innerHeight * 0.01}px`
  );

  //add variable to reference content area height
  document.documentElement.style.setProperty("--cth", getContentAreaHeight());

  window.addEventListener("resize", () => {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight * 0.01}px`
    );
    document.documentElement.style.setProperty("--cth", getContentAreaHeight());
  });

  useEffect(() => {
    dispatcher.Dispatcher.sendRequest(new GetCurrentUserRequest());
  }, [online]);

  useEffect(() => {
    if (user?.authenticated) {
      // populate store
      // populate groups, populate derived state "products" from "groups"
      dispatcher.Dispatcher.state.getState(StoreKeysEnum.Groups, () =>
        axios.get(InventoryUrl + "/group").then((response: AxiosResponse) => {
          const products = productsOrdered(
            response.data.flatMap((p: any) => p.products)
          );
          dispatcher.Dispatcher.state.saveState(
            StoreKeysEnum.Products,
            products
          );
          return response.data;
        })
      );

      dispatcher.Dispatcher.state.getState(StoreKeysEnum.Units, () =>
        axios
          .get(InventoryUrl + "/unit")
          .then((response: AxiosResponse) => response.data)
      );

      dispatcher.Dispatcher.state.getState(StoreKeysEnum.Ingredients, () =>
        axios
          .get(InventoryUrl + "/ingridient")
          .then((response: AxiosResponse) => response.data)
      );

      dispatcher.Dispatcher.state.getState(
        StoreKeysEnum.AvailableOrderOptions,
        () =>
          axios
            .get(OrdersUrl + "/getAvailableOptions")
            .then((response: AxiosResponse) => response.data)
      );

      dispatcher.Dispatcher.state.getState(
        StoreKeysEnum.ActiveOrdersCount,
        () =>
          axios
            .get(OrdersUrl + "/getActiveOrders/count")
            .then((response: AxiosResponse) => response.data)
      );

      dispatcher.Dispatcher.state.getState(
        StoreKeysEnum.AwaitingOrdersCount,
        () =>
          axios
            .get(OrdersUrl + "/getAwaitingOrders/count")
            .then((response: AxiosResponse) => response.data)
      );

      [Role.Admin, Role.Manager].includes(user.role!) &&
        dispatcher.Dispatcher.state.getState(StoreKeysEnum.Users, () =>
          axios
            .get(AuthenticationUrl)
            .then((response: AxiosResponse) => response.data)
        );

      dispatcher.Dispatcher.state.getState(StoreKeysEnum.ServerSettings, () =>
        axios
          .get(GeneralUrl + "/settings")
          .then((response: AxiosResponse) => response.data)
      );
    }
  }, [user]);

  // online/offline behaviour
  useEffect(() => {
    !online && navigate("/offline");
  }, [online]);

  return (
    <Layout>
      <Modal></Modal>
      <Toast></Toast>
      {user ? (
        <Routes>
          {/* restricted routes */}
          {user.authenticated &&
            RestrictedRoutes.map((route) => {
              return (
                user.role &&
                route.roles.includes(user.role) && (
                  <Route
                    key={"route" + route.to}
                    path={route.to}
                    element={<route.component />}
                  >
                    {route?.childRoutes?.map((route) => (
                      <Route
                        key={"childRoute" + route.to}
                        path={route.to}
                      ></Route>
                    ))}
                  </Route>
                )
              );
            })}
          {/* public routes */}
          <Route path="/login" element={<LoginRoute />} />
          <Route path="/register" element={<RegisterRoute />} />
          <Route path="/home" element={<HomeRoute />} />
          <Route path="/offline" element={<OfflineRoute />} />
          <Route path="/" element={<HomeRoute />} />
        </Routes>
      ) : (
        <Loader loaderVisible={true}></Loader>
      )}
    </Layout>
  );
}
