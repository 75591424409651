const baseUrl = new URL(window.location.href).origin;

export const AuthenticationUrl = `${baseUrl}/api/users`;
export const GeneralUrl = `${baseUrl}/api/general`;
export const InventoryUrl = `${baseUrl}/api/inventory`;
export const AttachmentsUrl = `${baseUrl}/api/attachments`;
export const OrderHubUrl = `${baseUrl}/hubs/order`;
export const OrdersUrl = `${baseUrl}/api/orders`;
export const ReportsUrl = `${baseUrl}/api/reports`;
export const PromotionsUrl = `${baseUrl}/api/promotions`;
export const PriceUrl = `${baseUrl}/api/price`;
export const LogInUrl = `${AuthenticationUrl}/authenticate`;
export const RegisterUrl = `${AuthenticationUrl}/register`;
export const LocalStorageKey = "order_maker_settings";

export const RegexPasswordPattern = "^(?=.*[@$!%*#?&])[A-Za-zd@$!%*#?&].{7,}$";