import { ProductModel } from "../models/api/product";
import { IDispatcher } from "../models/dispatcher/dispatcher-interfaces";
import { StoreKeysEnum } from "../store/storeKeysEnum";

export const getProduct = (id: number, dispatcher: IDispatcher) => {
  const products = dispatcher.state.getState(
    StoreKeysEnum.Products
  ) as ProductModel[];

  return !isNaN(id) ? products?.find((p) => p.id === id) ?? null : null;
};
