import styled from "styled-components";
import { TabComponent, TabItem } from "../components/common/TabComponent";
import { AdminAppInfo } from "../components/feature/AdminAppInfo";
import { AdminConnectedClients } from "../components/feature/AdminConnectedClients";
import { AdminServerLogs } from "../components/feature/AdminServerLogs";
import { AdminAppSettings } from "../components/feature/AdminAppSettings";

const AdminStyle = styled.div`
  .admin-tab {
    padding: 0.5rem;
    p {
      display: flex;
    }
    .admin-table {
      margin-bottom: 1rem;
    }
  }
`;

export function AdminView() {
  return (
    <AdminStyle>
      <TabComponent>
        <TabItem key="application" title="Aplikacja" className="admin-tab">
          <AdminAppInfo />
        </TabItem>
        <TabItem
          key="connections"
          title="Aktywne połączenia"
          className="admin-tab"
        >
          <AdminConnectedClients />
        </TabItem>
        <TabItem key="logs" title="Logi serwera" className="admin-tab">
          <AdminServerLogs />
        </TabItem>
        <TabItem key="settings" title="Ustawienia" className="admin-tab">
          <AdminAppSettings />
        </TabItem>
      </TabComponent>
    </AdminStyle>
  );
}
