import { Breakpoints } from "./breakpoints";

export const aRotate = `{
    @keyframes a-rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(180deg);
      }
    }
  }`;

export const aRotateReverse = `{
    @keyframes a-rotate-reverse {
        0% {
          transform: rotate(180deg);
        }
        100% {
          transform: rotate(0deg);
        }
      }
    }`;

export const aTransitionLeft = `{
  @keyframes a-transition-left {
      0% {
        transform: none;
        opacity: 1;
      }
      100% {
        transform: translateX(-50%);
        opacity: 0;
      }
    }
  }`;

export const aTransitionRight = `{
  @keyframes a-transition-right {
      0% {
        transform: none;
        opacity: 1;
      }
      100% {
        transform: translateX(50%);
        opacity: 0;
      }
    }
  }`;

export const aFadeInLeft = `{
    @keyframes a-transition-fade-in-left {
        0% {
          transform: translateX(-10%);
          opacity: 0;
        }
        100% {
          transform: none;
          opacity: 1;
        }
      }
    }`;

export const aFadeOutRight = `{
      @keyframes a-transition-fade-out-right {
          0% {
            transform: none;
            opacity: 1;
          }
          100% {
            transform: translateX(50%);
            opacity: 0;
          }
        }
      }`;

export const aFadeIn = `{
    @keyframes a-fade-in {
        from {
          opacity: 0;
          visibility: hidden;
        }
        to {
          opacity: 1;
          visibility: visible;
        }
      }
    }`;

export const aFadeOut = `{
    @keyframes a-fade-out {
        from {
          opacity: 1;
          visibility: visible;
        }
        to {
          opacity: 0;
          visibility: hidden;
        }
      }
    }`;

export const vHidden = `
    .hidden {
        animation: a-fade-out 0.1s linear;
        opacity: 0;
        visibility: hidden;
    }`;

export const vVisible = `
    .visible{
        animation: a-fade-in 0.1s linear;
        opacity: 1;
        visibility: visible;
    }`;

export const vTransition = `
    .transition {
        &--none{
          transform: none;
        }
        &--right{
          animation: a-transition-right 100ms ease-in; 
        }
        &--left{
          animation: a-transition-left 100ms ease-in;
        }
        &--fade-in{
          animation: a-transition-fade-in-left 150ms ease-in; 
        }
        &--fade-out{
          animation: a-transition-fade-out-right 150ms ease-in;
          animation-fill-mode: forwards;
        }
    }
`;

const _backdrop = `
        background-color: var(--backdrop-translucent);
        z-index: 89;
        display: block;
        position:fixed;
        height:auto;
        bottom:0;
        top:0;
        left:0;
        right:0;`;

export const vBackdrop = `
    .backdrop {
      ${_backdrop}
    }`;

export const vBackdropCustom = (zIndex: number) => {
  return `.backdrop {
      ${_backdrop}
      z-index: ${zIndex};
     
  }`;
};

export const vLoadingBorder = `
    @keyframes a-border-color {
      0% {
        border-color: var(--col-fg-secondary) var(--col-fg-primary) var(--col-fg-secondary) transparent;
        box-shadow: 0rem 0rem 5px var(--col-fg-secondary) inset; 
      }
      12.5% {
        border-color:  transparent var(--col-fg-secondary) var(--col-fg-primary) var(--col-fg-secondary);
        box-shadow: 1px 1px 5px var(--col-fg-secondary) inset; 
      }
      25% {
        border-color:  transparent var(--col-fg-secondary) var(--col-fg-primary) var(--col-fg-secondary);
        box-shadow: 2px 2px 2px var(--col-fg-secondary) inset; 
      }
      37.5% {
        border-color: var(--col-fg-secondary) transparent var(--col-fg-secondary) var(--col-fg-primary);
        box-shadow: 1px 1px 2px var(--col-fg-secondary) inset; 
      }
      50% {
        border-color:  var(--col-fg-secondary) transparent var(--col-fg-secondary) var(--col-fg-primary);
        box-shadow: 0rem 0rem 2px var(--col-fg-secondary) inset; 
      }
      62.5% {
        border-color:  var(--col-fg-primary)  var(--col-fg-secondary)  transparent var(--col-fg-secondary);
        box-shadow: -1px -1px 2px var(--col-fg-secondary) inset; 
      }
      75% {
        border-color:  var(--col-fg-primary)  var(--col-fg-secondary)  transparent var(--col-fg-secondary);
        box-shadow: -2px -2px 2px var(--col-fg-secondary) inset; 
      }
      87.5% {
        border-color:  var(--col-fg-primary)  var(--col-fg-secondary)  transparent var(--col-fg-secondary);
        box-shadow: -1px -1px 2px var(--col-fg-secondary) inset; 
      }
      100% {
        border-color:  var(--col-fg-secondary) var(--col-fg-primary) var(--col-fg-secondary) transparent;
        box-shadow: 0rem 0rem 2px var(--col-fg-secondary) inset; 
      }
    }
      border: 1px solid;
      opacity: 0.1;
      animation: a-border-color 2s linear infinite;
    }
`;

export const mDesktop = (content: string) => {
  let output = `@media only screen and (min-width: ${
    Breakpoints.Tablet + 1
  }px) {
                     ${content};
                  }`;
  return output;
};

export const mNotPhone = (content: string) => {
  let output = `@media only screen and (min-width: ${Breakpoints.Phone + 1}px) {
                     ${content};
                  }`;
  return output;
};

export const mTablet = (content: string) => {
  let output = `@media only screen and (max-width: ${Breakpoints.Tablet}px) {
                    ${content};
                }`;
  return output;
};

export const mPhone = (content: string) => {
  let output = `@media only screen and (max-width: ${Breakpoints.Phone}px) {
                    ${content};
                }`;
  return output;
};
