import { useRef, useEffect } from "react";

export interface ILongPress {
  onLongPress: () => void;
  children: React.ReactNode;
}

export function LongPress(props: ILongPress) {
  const _ref = useRef<HTMLDivElement>(null);
  function onLongPress(e?: Event) {
    e?.preventDefault();
    props.onLongPress();
  }

  useEffect(() => {
    _ref.current?.addEventListener("contextmenu", onLongPress);
    return () => {
      _ref.current?.removeEventListener("contextmenu", onLongPress);
    };
  }, []);
  return <div ref={_ref}>{props.children}</div>;
}
