import useDispatcherState from "../../hooks/useDispatcherState";
import styled from "styled-components";
import { DropDown } from "../common/DropDown";
import { StoreKeysEnum } from "../../store/storeKeysEnum";
import { useEffect, useState } from "react";
import { ProductModel } from "../../models/api/product";
import { VariantModel } from "../../models/api/variant";
import useProduct from "../../hooks/useProduct";
import useVariant from "../../hooks/useVariant";

const ProductVariantSelectorStyle = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .selector {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0.2rem;
    .label {
      flex-basis: 20%;
      text-align: left;
    }
    .dropdown {
      flex-basis: 80%;
    }
  }
`;

export interface IProductVariantSelectorComponent {
  productId?: number;
  variantId?: number;
  onSelection: (
    product: ProductModel | null,
    variant: VariantModel | null
  ) => void;
}

export function ProductVariantSelector(
  props: IProductVariantSelectorComponent
) {
  const { getProduct } = useProduct();
  const { getVariant } = useVariant();
  const [products] = useDispatcherState<ProductModel[]>(StoreKeysEnum.Products);

  const productList = products?.map((el) => {
    return { key: el.id, value: el.description };
  });
  productList?.push({ key: -1, value: "--wszystkie--" });

  const initialProduct = props?.productId
    ? getProduct(props?.productId)
    : getProduct(getVariant(props?.variantId ?? 0)?.productId ?? 0);
  const initialVariant = getVariant(props?.variantId ?? 0);
  const initialVariants = initialProduct?.variants?.map((el) => {
    return { key: el.id, value: el.description };
  });

  const [variantList, setVariantList] = useState<
    { key: number | undefined; value: string | undefined }[]
  >(initialVariants ?? []);

  const [selectedProduct, setSelectedProduct] = useState<ProductModel | null>(
    initialProduct
  );
  const [selectedVariant, setSelectedVariant] = useState<VariantModel | null>(
    initialVariant
  );

  useEffect(() => {
    const variants = selectedProduct?.variants
      ? selectedProduct.variants.map((el) => {
          return { key: el.id, value: el.description };
        })
      : [];
    variants.push({ key: -1, value: "--wszystkie--" });
    setVariantList(variants);
  }, [selectedProduct]);

  useEffect(() => {
    props.onSelection(selectedProduct, selectedVariant);
  }, [selectedProduct, selectedVariant]);

  return (
    <ProductVariantSelectorStyle>
      <span className="selector flex-center-justify">
        <label className="label" htmlFor="product-dropdown">
          Produkt:
        </label>
        <div id="product-dropdown" className="dropdown">
          <DropDown
            items={productList}
            selectedIndex={productList?.findIndex(
              (e) => e?.key === (selectedProduct?.id ?? -1)
            )}
            onItemSelected={(val) => setSelectedProduct(getProduct(val?.key))}
          ></DropDown>
        </div>
      </span>
      <span className="selector flex-center-justify">
        <label className="label" htmlFor="variant-dropdown">
          Wariant:
        </label>
        <div
          id="variant-dropdown"
          className="dropdown"
          key={JSON.stringify(variantList)}
        >
          <DropDown
            items={variantList}
            selectedIndex={variantList.findIndex((e) => {
              return e?.key === (selectedVariant?.id ?? -1);
            })}
            onItemSelected={(val) => setSelectedVariant(getVariant(val?.key))}
          ></DropDown>
        </div>
      </span>
    </ProductVariantSelectorStyle>
  );
}
