import styled from "styled-components";
import { TimeConstrainedPromo } from "../../models/api/timeConstrainedPromo";
import { RangeSelector } from "../common/RangeSelector";

const ModifyPromoStyle = styled.section`
  width: 100%;
  display: flex;
  .date-selector {
    width: 100%;
    .selector {
      display: flex;
      flex: 1;
      justify-content: right;
      gap: 0.5rem;
    }
    gap: 0.5rem;
  }
`;

export interface IModifyPromoComponent {
  promo: TimeConstrainedPromo;
  onPromoModified: (promo: TimeConstrainedPromo) => void;
}

export function ModifyTimeConstrainedPromo(props: IModifyPromoComponent) {
  return (
    <ModifyPromoStyle>
      <div className="flex-center-justify date-selector">
        Data ważności od / do:
        <div className="selector">
          <RangeSelector
            fromDate={
              props.promo?.validFrom
                ? new Date(props.promo?.validFrom)
                : undefined
            }
            toDate={
              props.promo?.validTo ? new Date(props.promo?.validTo) : undefined
            }
            onSelectedRangeChanged={(range) => {
              props.onPromoModified({
                ...props.promo,
                validFrom: range.from?.toISOString() ?? "",
                validTo: range.to?.toISOString() ?? "",
              });
            }}
          ></RangeSelector>
        </div>
      </div>
    </ModifyPromoStyle>
  );
}
