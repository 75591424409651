// settings that are stored on server
export enum AppSettingsKeys {
  RestoreAllNotCompletedOrders = "RestoreAllNotCompletedOrders",
  DefaultNewOrderState = "DefaultNewOrderState",
  DisableAutoSwitchingOrderToActive = "DisableAutoSwitchingOrderToActive",
  DefaultAwaitingOrdersQuery = "DefaultAwaitingOrdersQuery",
  DefaultAwaitingOrdersViewType = "DefaultAwaitingOrdersViewType",
  RequireExpectedStartingDateInNewOrder = "RequireExpectedStartingDateInNewOrder",
  RequireExpectedCompletionDateInNewOrder = "RequireExpectedCompletionDateInNewOrder"
}
