import styled from "styled-components";
import { useApiCall } from "../../hooks/useApiCall";
import { InventoryUrl } from "../../config";
import { AsyncComponent } from "../common/AsyncComponent";
import { ProductModel } from "../../models/api/product";
import { Link } from "react-router-dom";
import { useEffect, useMemo } from "react";
import useDispatcherState from "../../hooks/useDispatcherState";
import { StoreKeysEnum } from "../../store/storeKeysEnum";
import { ActionButton } from "../common/ActionButton";
import { Table } from "../common/Table";
import { GroupModel } from "../../models/api/group";
import { ConfirmButton } from "../common/ConfirmButton";
import { ModifiedInventoryModel } from "../../models/api/modifiedInventory";
import { ActionEnum } from "../../models/enums/actionEnum";

const ManageGroupsStyle = styled.section`
  .manage {
    &__btn {
      a {
        text-decoration: none;
      }
    }
  }
  .products {
    border-bottom: var(--border-width) solid var(--col-bg-secondary);
  }
`;

export function ManageGroups() {
  const [response, isLoading, error, , post, refresh] = useApiCall<
    GroupModel[]
  >({
    url: InventoryUrl + "/group",
    setResponseOnPost: false,
  });

  const [groups, setGroups] = useDispatcherState<GroupModel[] | null>(
    StoreKeysEnum.Groups
  );

  const applyChanges = async (option: ModifiedInventoryModel<GroupModel>) => {
    post(InventoryUrl + "/group/modify", option).then((val: any) => {
      refresh();
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Nazwa grupy",
        accessor: "products",
        isExpander: "true",
        Cell: (props: { value: ProductModel[]; row: any }) => {
          const canExpand = props.value && props.value.length > 0;
          return canExpand ? (
            <span
              className="expander"
              onClick={() => {
                props.row.isExpanded = !props.row.isExpanded;
                props.row.setState((e: any) => {
                  return { ...e };
                });
              }}
            >
              <div className="flex nowrap">
                <div className="icon">{props.row.isExpanded ? "▼" : "▶"}</div>
                <div className="margin-x">
                  {props.row.original.id
                    ? props.row.original.description
                    : "Produkty bez przydzielonej grupy"}
                </div>
              </div>
            </span>
          ) : (
            <div>
              {props.row.original.id
                ? props.row.original.description
                : "Produkty bez przydzielonej grupy"}
            </div>
          );
        },
        ChildComponent: (group: GroupModel, row: any) => (
          <div className="products">
            <ul>
              {group?.products?.map((e) => (
                <li>{e?.description}</li>
              ))}
            </ul>
          </div>
        ),
      },
      {
        id: "Action",
        accessor: "id",
        Cell: (props: { value: number; row: any; preparedRows: any }) => {
          return (
            <>
              {props.value ? (
                <div className="flex flex-right gap">
                  <ConfirmButton
                    className="action flex flex-center-justify btn--active"
                    confirmClassName="action flex flex-center-justify btn--alert"
                    label="Usuń"
                    confirmLabel="Usunąć?"
                    action={() =>
                      applyChanges({
                        action: ActionEnum.Delete,
                        item: { id: props.value },
                      })
                    }
                  ></ConfirmButton>
                  <Link
                    className="flex flex-right no-underline"
                    to={`/inventory/group/modify?id=${
                      (props.row.original as any).id
                    }`}
                  >
                    <button className="btn--active">Edytuj</button>
                  </Link>
                </div>
              ) : (
                <div></div>
              )}
            </>
          );
        },
      },
    ],
    [response]
  );

  useEffect(() => {
    if (response) {
      setGroups(response);
    }
  }, [response]);

  return (
    <ManageGroupsStyle>
      <AsyncComponent data={groups} isLoading={isLoading} error={error}>
        {(products: GroupModel[]) => {
          return <Table columns={columns} data={products}></Table>;
        }}
      </AsyncComponent>
      <ActionButton label="+ Dodaj nową grupę">
        <Link to="/inventory/group/create">+ Dodaj nową grupę</Link>
      </ActionButton>
    </ManageGroupsStyle>
  );
}
