import { useApiCall } from "../../hooks/useApiCall";
import { PromotionsUrl } from "../../config";
import { AsyncComponent } from "../common/AsyncComponent";
import { useMemo, useState } from "react";
import { TimeConstrainedPromo } from "../../models/api/timeConstrainedPromo";
import { Table } from "../common/Table";
import { DayOfWeekEnum } from "../../models/enums/dayOfWeekEnum";
import { dayOfWeek } from "../../helpers/dayOfWeek";
import { BasePromo } from "../../models/api/basePromo";
import useProduct from "../../hooks/useProduct";
import useVariant from "../../hooks/useVariant";
import { Row } from "react-table";
import { GenericView } from "../common/GenericView";
import { ModifyPromo } from "./ModifyPromo";
import { PromoTypeEnum } from "../../models/enums/promoTypeEnum";
import { RecurringPromo } from "../../models/api/recurringPromo";
import { ModifiedInventoryModel } from "../../models/api/modifiedInventory";
import { ActionButton } from "../common/ActionButton";

export function ManageRecurringPromosComponent() {
  const [response, isLoading, error, , post, refresh] = useApiCall<
    RecurringPromo[]
  >({
    url: PromotionsUrl + "/recurring?activeOnly=false",
    setResponseOnPost: false,
  });
  const { getProduct } = useProduct();
  const { getVariant } = useVariant();
  const [edit, setEdit] = useState<React.ReactNode>();
  const invokeEditView = (promo?: RecurringPromo) => {
    setEdit(
      <GenericView
        viewTitle={
          promo ? "Edytuj promocję cykliczną" : "Dodaj nową promocję cykliczną"
        }
        fitToContent={false}
        onClose={() => setEdit(undefined)}
      >
        <ModifyPromo
          promo={promo}
          promoType={PromoTypeEnum.RecurringPromo}
          onPromoModified={(promo) =>
            applyChanges(promo as ModifiedInventoryModel<RecurringPromo>)
          }
        ></ModifyPromo>
      </GenericView>
    );
  };
  const applyChanges = (promo: ModifiedInventoryModel<RecurringPromo>) => {
    setEdit(undefined);
    post(PromotionsUrl + "/recurring/modify", promo).then((val: any) =>
      refresh()
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Produkt",
        accessor: "productId",
        Cell: (props: { value: number; row: Row }) => {
          return (
            <div className="promo__product" style={{ minWidth: "8rem" }}>
              {props.value
                ? getProduct(props.value)?.description
                : getProduct(
                    getVariant((props.row.original as BasePromo).variantId ?? 0)
                      ?.productId ?? 0
                  )?.description}
            </div>
          );
        },
      },
      {
        Header: "Wariant",
        accessor: "variantId",
        Cell: (props: { value: number }) => {
          return (
            <div className="promo__variant">
              {getVariant(props.value)?.description ?? "wszystkie"}
            </div>
          );
        },
      },
      {
        Header: "Opis",
        Cell: (props: { value: string }) => {
          return <div style={{ minWidth: "8rem" }}>{props.value}</div>;
        },
        accessor: "description",
      },
      {
        Header: "Min ilość",
        accessor: "minQuantity",
      },
      {
        Header: "Max ilość",
        accessor: "maxQuantity",
      },
      {
        Header: "Dni tygodnia",
        accessor: "daysOfWeek",
        Cell: (props: { value: DayOfWeekEnum[] }) => (
          <>{props.value.sort().map((v) => dayOfWeek(v) + " ")}</>
        ),
      },
      {
        Header: "Od godziny",
        accessor: "fromTime",
        Cell: (props: { value: string }) => <>{props.value.split(".")?.[0]}</>,
      },
      {
        Header: "Do godziny",
        accessor: "toTime",
        Cell: (props: { value: string }) => <>{props.value.split(".")?.[0]}</>,
      },
      {
        Header: "Kod Promo",
        accessor: "promoCode",
        Cell: (props: { value?: string }) => (
          <>{props.value ? props.value : "--brak--"}</>
        ),
      },
      {
        Header: "%zniżki",
        accessor: "percentageDiscountPerItem",
      },
      {
        id: "Action",
        Cell: (props: { value: string; row: Row }) => (
          <div
            className="promo__action"
            onClick={() => invokeEditView(props.row.original as RecurringPromo)}
          >
            <button className="btn--active">Edytuj</button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <AsyncComponent data={response} isLoading={isLoading} error={error}>
        {(promos: TimeConstrainedPromo[]) => {
          return (
            <>
              <Table
                className="promo-list"
                columns={columns}
                data={promos}
              ></Table>
              <ActionButton
                label="+ Dodaj nową promocję"
                onClick={() => invokeEditView()}
              ></ActionButton>
            </>
          );
        }}
      </AsyncComponent>
      {edit}
    </>
  );
}
