import styled from "styled-components";
import { ProductModel } from "../../models/api/product";
import { IngredientModel } from "../../models/api/ingredient";
import { UnitModel } from "../../models/api/unit";
import { ModifyProduct } from "./ModifyProduct";

const NewProductStyle = styled.section`
  .product-creator {
    h4,
    h5,
    h6 {
      text-align: left;
      margin-top: 1rem;
    }
    &__product {
      .input-field {
        width: 100%;
        margin: 0.2rem;
        display: flex;
        .name {
          flex-basis: 40%;
        }
        .input {
          flex-basis: 40%;
        }
      }
    }
    &__variants {
      justify-content: center;
      .variant-container {
        margin: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 50rem;
        margin: auto;
        .variant {
          width: 100%;
        }
      }
      .creator-actions {
        margin-top: 0.5rem;
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;

export interface INewProductCreator {
  ingridients: IngredientModel[];
  units: UnitModel[];
  onUnitsUpdated?: (units: UnitModel[]) => Promise<{ units: UnitModel[] }>;
  onIngridientsUpdated?: (
    ingridients: IngredientModel[]
  ) => Promise<{ ingridients: IngredientModel[] }>;
  onNewProductCreated?: (product: ProductModel) => void;
}

export function NewProductCreator(props: INewProductCreator) {
  function saveProduct(product: ProductModel) {
    props.onNewProductCreated && props.onNewProductCreated(product);
  }

  return (
    <NewProductStyle>
      <div className="product-creator">
        <h4>Kreator nowego produktu</h4>
      </div>
      <div className={`product-creator product-creator__variants`}>
        <ModifyProduct
          units={props.units}
          ingredients={props.ingridients}
          onProductModified={(product: ProductModel) => saveProduct(product)}
        ></ModifyProduct>
      </div>
    </NewProductStyle>
  );
}
