import styled from "styled-components";
import { ChartComponent } from "../common/ChartComponent";
import { formatNumber } from "../../helpers/formatter";
import { Toggler } from "../common/Toggler";
import { useState } from "react";
import { HeatMap } from "../common/HeatMap";
import {
  SalesByHour,
  SalesHeatMapReportModel,
} from "../../models/api/heatMapModel";

const ReportSalesStyle = styled.section`
  white-space: nowrap;
  .sales-report {
    &__header {
      width: 100%;
      .toggler {
        margin-left: 1rem;
        flex-wrap: wrap;
        &__label {
          margin-right: 0.5rem;
        }
      }
    }
    &__chart {
      border: var(--border-width) solid var(--col-fg-secondary);
      margin-top: 0.5rem;
      .heat-map {
        margin: 0.5rem;
      }
    }
  }
`;

export function ReportSalesHeatMap(props: SalesHeatMapReportModel) {
  const [countToggled, setCountToggled] = useState(false);
  // const [chartData, setChartData] = useState<IHeatMap<SalesByHour>>(props.heatMapData);

  return (
    <ReportSalesStyle>
      <div className="sales-report__products">
        <span className="sales-report__header  flex-center-justify">
          <section>
            {countToggled ? "Zamówienia:" : "Utarg:"}
          </section>
          <section className="flex-right toggler">
            <section className="toggler__label">Przełącz widok</section>
            <Toggler
              toggled={countToggled}
              onToggleChanged={(value) => setCountToggled(value)}
            ></Toggler>
          </section>
        </span>
        <div className="sales-report__chart" key={countToggled.toString()}>
          <div className="heat-map">
            <HeatMap data={props} showCounts={countToggled}></HeatMap>
          </div>
        </div>
      </div>
    </ReportSalesStyle>
  );
}
