import styled from "styled-components";
import { BasePromo } from "../../models/api/basePromo";
import { Table } from "../common/Table";
import { useMemo } from "react";
import { Row } from "react-table";
import useProduct from "../../hooks/useProduct";
import useVariant from "../../hooks/useVariant";

const PromoStyle = styled.section`
  padding: 0.5rem;
  padding-bottom: 0.2rem;
  background-color: var(--col-bg-primary);
`;

export interface IAvailablePromotionsComponent {
  promos: BasePromo[];
  onCreateOrderRequest: (promo: BasePromo) => void;
}

export function AvailablePromotionsComponent(
  props: IAvailablePromotionsComponent
) {
  const { getProduct } = useProduct();
  const { getVariant } = useVariant();

  const columns = useMemo(
    () => [
      {
        Header: "Produkt",
        accessor: "productId",
        Cell: (props: { value: number; row: Row }) => {
          return (
            <div className="promo__product">
              {props.value
                ? getProduct(props.value)?.description
                : getProduct(
                    getVariant((props.row.original as BasePromo).variantId ?? 0)
                      ?.productId ?? 0
                  )?.description}
            </div>
          );
        },
      },
      {
        Header: "Wariant",
        accessor: "variantId",
        Cell: (props: { value: number }) => {
          return (
            <div className="promo__variant">
              {getVariant(props.value)?.description ?? "wszystkie warianty"}
            </div>
          );
        },
      },
      {
        Header: "Opis",
        accessor: "description",
      },
      {
        Header: "Kod Promo",
        accessor: "promoCode",
        Cell: (props: { value?: string }) => (
          <>{props.value ? props.value : "--brak--"}</>
        ),
      },
      {
        id: "Action",
        Cell: (props: { value: string; row: Row }) => (
          <div
            className="promo__action"
            onClick={() => onMakeOrder(props.row.original as BasePromo)}
          >
            <button className="btn--active">Zamów</button>
          </div>
        ),
      },
    ],
    []
  );

  function onMakeOrder(promo: BasePromo) {
    props.onCreateOrderRequest(promo);
  }

  return (
    <PromoStyle>
      <Table
        className="promo-list"
        columns={columns}
        data={props.promos}
        updateMyData={() => {}}
      ></Table>
    </PromoStyle>
  );
}
