import styled from "styled-components";
import { Role, Status, User } from "../../models/api/user";
import { useApiCall } from "../../hooks/useApiCall";
import { AuthenticationUrl } from "../../config";
import { AsyncComponent } from "../common/AsyncComponent";
import { TableCellEditButton } from "../common/TableCellEditButton";
import { Input } from "../common/Input";
import {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import useEditableTable from "../../hooks/useEditableTable";
import { Table } from "../common/Table";
import { durationToText } from "../../helpers/durationToText";
import intervalToDuration from "date-fns/intervalToDuration";
import { DropDown } from "../common/DropDown";
import useDispatcherState from "../../hooks/useDispatcherState";
import { StoreKeysEnum } from "../../store/storeKeysEnum";
import { ConfirmButton } from "../common/ConfirmButton";
import { ActionButton } from "../common/ActionButton";
import { IModalContent, Modal } from "../common/Modal";
import { DispatcherContext } from "../..";
import { Event } from "../../models/dispatcher/dispatcher-events";
import { LongPress } from "../common/hocs/LongPress";

const ManageUsersStyle = styled.section`
  .no-wrap {
    white-space: nowrap;
  }
  .users-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    & .list {
      display: unset;
      padding: 0.5rem;
      border-bottom: var(--border-width) solid var(--col-bg-secondary);
      &:last-child {
        border-bottom: unset;
      }
    }
  }
  .reset {
    &__header {
      margin-bottom: 5rem;
    }
    &__action {
      width: 100%;
      button {
        width: 100% !important;
      }
    }
  }
`;

const ResetPasswordStyle = styled.section`
  .hidden {
    display: none;
  }
  .reset {
    &__header {
      margin-bottom: 2rem;
    }
    &__action {
      width: 100%;
      button {
        width: 100% !important;
      }
    }
    .password {
      padding: 1rem;
      font-size: 1.2rem;
    }
  }
`;

export function ManageUsers() {
  const [response, isLoading, error, get, post] = useApiCall<User[]>({
    url: AuthenticationUrl,
    setResponseOnPost: false,
  });
  const { updateEntry, abortUnfocusedEdits, abortAllEdits } =
    useEditableTable();
  const [users, setUsers] = useDispatcherState<User[] | null>(
    StoreKeysEnum.Users
  );
  const [currentUser] = useDispatcherState<User>(StoreKeysEnum.User);
  const dispatcher = useContext(DispatcherContext);
  const modalRef = useRef<HTMLDivElement>(null);

  const rolesDropdown = [
    { key: Role.User, value: "Użytkownik" },
    { key: Role.Employee, value: "Pracownik" },
    { key: Role.Manager, value: "Menedżer" },
  ];

  const statusDropdown = [
    { key: Status.Unactivated, value: "Nieaktywny" },
    { key: Status.Activated, value: "Aktywny" },
  ];

  const applyChanges = async (user: User) => {
    post(AuthenticationUrl + "/modify", user).then(() =>
      get(AuthenticationUrl)
    );
  };

  function getLastActivityInterval(lastLogInDate?: Date) {
    if (lastLogInDate) {
      return intervalToDuration({
        start: new Date(lastLogInDate),
        end: new Date(Date.now()),
      });
    }
  }

  function onEntryUpdated(row: any) {
    const updated = updateEntry(row);
    applyChanges(updated.item);
  }

  function getModalContent(
    header: string,
    content: ReactElement
  ): IModalContent {
    return {
      header: header,
      content: content,
    };
  }

  const resetPassword = (user?: User) => {
    return (
      <ResetPasswordStyle>
        <div ref={modalRef}>
          <h6 className="reset reset__header" id="info">
            <div> Kliknik "Zresetuj hasło" aby zresetować hasło dla:</div>
            <div>
              {user?.firstName} {user?.lastName}
            </div>
          </h6>
          <h6 className="reset reset__header hidden" id="result">
            <div>
              Nowe hasło dla {user?.firstName} {user?.lastName}:
            </div>
            <div className="password">
              <strong id="password">******</strong>
            </div>
            <div>Poproś użytkownika o zmianę hasła po zalogowaniu.</div>
          </h6>
          <div id="action" className="reset reset__action">
            <ConfirmButton
              label="Zresetuj hasło"
              confirmLabel="Zresetować hasło?"
              fitToContent={true}
              action={() => {
                modalRef.current?.querySelector("#info")?.remove();
                modalRef.current?.querySelector("#action")?.remove();
                modalRef.current
                  ?.querySelector("#result")
                  ?.classList.remove("hidden");
                post(AuthenticationUrl + "/resetPassword", user).then(
                  (response: any) => {
                    const pass = modalRef.current?.querySelector("#password");
                    if (pass) {
                      pass.textContent = response.data;
                    }
                  }
                );
              }}
            ></ConfirmButton>
          </div>
        </div>
      </ResetPasswordStyle>
    );
  };

  useEffect(() => {
    response && setUsers(response);
  }, [response]);

  const columns = useMemo(
    () => [
      {
        Header: "Imię",
        accessor: "firstName",
        Cell: (props: { value: string; row: any }) => {
          return (
            <div>
              {props.row.state.isEditEnabled ? (
                <Input
                  defaultValue={props.value}
                  onChange={(e) => {
                    props.row.state.firstName = e;
                  }}
                ></Input>
              ) : (
                props.value
              )}
            </div>
          );
        },
      },
      {
        Header: "Nazwisko",
        accessor: "lastName",
        Cell: (props: { value: string; row: any }) => {
          return (
            <div>
              {props.row.state.isEditEnabled ? (
                <Input
                  defaultValue={props.value}
                  onChange={(e) => {
                    props.row.state.lastName = e;
                  }}
                ></Input>
              ) : (
                props.value
              )}
            </div>
          );
        },
      },
      {
        Header: "Ostatnio aktywny",
        accessor: "lastLogInDate",
        Cell: (props: { value: string; row: any }) => {
          return (
            <div>
              {durationToText(getLastActivityInterval(new Date(props.value)))}
            </div>
          );
        },
      },
      {
        Header: "Rola",
        accessor: "role",
        Cell: (props: { value: string; row: any }) => {
          return (
            <div style={{ minWidth: "8rem" }}>
              {props.row.state.isEditEnabled ? (
                <DropDown
                  selectedIndex={rolesDropdown?.findIndex(
                    (el) => el.key === props.value
                  )}
                  items={rolesDropdown}
                  onItemSelected={(e) => (props.row.state.role = e?.key)}
                ></DropDown>
              ) : (
                <div>
                  {rolesDropdown?.find((e) => e.key === props.value)?.value}
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (props: { value: string; row: any }) => {
          return (
            <div style={{ minWidth: "8rem" }}>
              {props.row.state.isEditEnabled ? (
                <DropDown
                  selectedIndex={statusDropdown?.findIndex(
                    (el) => el.key === props.value
                  )}
                  items={statusDropdown}
                  onItemSelected={(e) => (props.row.state.status = e?.key)}
                ></DropDown>
              ) : (
                <div>
                  {statusDropdown?.find((e) => e.key === props.value)?.value}
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Stawka godzinowa",
        accessor: "hourlyRate",
        Cell: (props: { value: number; row: any }) => {
          return (
            <div>
              {props.row.state.isEditEnabled ? (
                <Input
                  type="number"
                  defaultValue={props.value}
                  onChange={(e) => {
                    props.row.state.hourlyRate = e;
                  }}
                ></Input>
              ) : (
                props.value
              )}
            </div>
          );
        },
      },
      {
        id: "ActionResetPassword",
        Cell: (props: { value: number; row: any }) => {
          return (
            <div>
              {props.row.state.isEditEnabled && (
                <ActionButton
                  className="btn--alert no-wrap"
                  label="Reset hasła"
                  onClick={() =>
                    dispatcher.dispatch(
                      Event.ShowModal,
                      getModalContent(
                        "Reset hasła",
                        resetPassword(props.row.original)
                      )
                    )
                  }
                ></ActionButton>
              )}
            </div>
          );
        },
      },
      {
        id: "ActionEdit",
        Cell: (props: { row: any; preparedRows: any }) => {
          return (
            <LongPress onLongPress={() => abortAllEdits(props.preparedRows)}>
              <TableCellEditButton
                row={props.row}
                onEntryUpdated={onEntryUpdated}
              ></TableCellEditButton>
            </LongPress>
          );
        },
      },
    ],
    []
  );

  return (
    <ManageUsersStyle>
      <AsyncComponent
        data={users?.filter((u) => u.id !== currentUser.id)}
        isLoading={isLoading}
        error={error}
      >
        {(users: User[]) => {
          return (
            <>
              <Table
                columns={columns}
                data={users}
                onRowClick={abortUnfocusedEdits}
              ></Table>
            </>
          );
        }}
      </AsyncComponent>
    </ManageUsersStyle>
  );
}
