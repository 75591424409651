import { JSXElement } from "@babel/types";
import { ReactElement, ReactNode, useState } from "react";
import styled from "styled-components";
import SvgAngleDown from "../icons/SvgAngleDown";
import SvgAngleRight from "../icons/SvgAngleRight";

const ExpandableContainerStyle = styled.div`
  width: ${(props) =>
    (props as any).theme?.fitToContent ? "fit-content" : "100%"};
  background-color: ${(props) =>
    (props as any).theme?.backgroundColor
      ? props.theme.backgroundColor
      : "unset"};
  .action {
    cursor: pointer;
    display: flex;
  }
  .group {
    &__header {
      width: 100%;

      display: flex;
      gap: 0.5rem;
      align-items: center;
      cursor: pointer;
      &__expand-collapse {
        svg {
          path {
            fill: var(--col-fg-secondary);
          }
        }
      }
      &__title {
        width: 100%;
        text-align: left;
      }
    }
    &__options {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      margin-bottom: 0.5rem;
      margin-left: calc(16px + 0.4rem);
      &__option {
        align-items: center;
      }
      &--hidden {
        display: none;
      }
    }
  }
`;
interface IExpandableContainer {
  header: string | JSX.Element | ((isExpanded: boolean) => string | ReactNode);
  expanded?: boolean;
  className?: string;
  children: ReactElement;
  onExpandCollapse?: (isEpanded: boolean) => void;
  expandCollapseTemplate?: (isExpanded: boolean) => string | JSXElement;
  fitToContent?: boolean;
  backgroundColor?: string;
}

const ExpandableContainer = (props: IExpandableContainer) => {
  const { header, expanded = true, className = "", children } = props;
  const [groupExpanded, setGroupExpanded] = useState(expanded);
  function toggleExpandCollapse(state: boolean) {
    state === true ? setGroupExpanded(true) : setGroupExpanded(false);
    props.onExpandCollapse && props.onExpandCollapse(state);
  }
  return (
    <ExpandableContainerStyle
      theme={{
        fitToContent: props.fitToContent,
        backgroundColor: props.backgroundColor,
      }}
    >
      <section className={className}>
        <div
          className="flex-left group__header"
          onClick={() => {
            toggleExpandCollapse(groupExpanded ? false : true);
          }}
        >
          {typeof header !== "function" && (
            <div className="group__header__expand-collapse action">
              {groupExpanded ? (
                <SvgAngleDown
                  height="1rem"
                  width="1rem"
                  viewBox="0 0 350 500"
                />
              ) : (
                <SvgAngleRight
                  height="1rem"
                  width="1rem"
                  viewBox="0 0 350 500"
                />
              )}
            </div>
          )}
          <div className="group__header__title">
            {typeof header === "function" ? header(groupExpanded) : header}
          </div>
        </div>
        <section
          className={`group__options ${
            !groupExpanded ? "group__options--hidden" : undefined
          }`}
        >
          {children}
        </section>
      </section>
    </ExpandableContainerStyle>
  );
};

export default ExpandableContainer;
