import styled from "styled-components";

const ActionButtonStyle = styled.div`
  ${(props) =>
    props.theme.sticky === true
      ? `position: sticky;
         bottom: 0;
      `
      : ""};

  ${(props) => (props.theme.fitToContent === false ? "width: 100%" : "")};
  .manage {
    &__btn {
      margin: 1rem;
      display: flex;
    }
  }
  a {
    text-decoration: none;
    color: unset;
  }
`;

export interface IActionButton {
  onClick?: (data: unknown) => unknown;
  label?: string;
  sticky?: boolean;
  fitToContent?: boolean;
  className?: string;
  disabled?: boolean;
  children?: React.ReactElement | string;
}

export function ActionButton(props: IActionButton) {
  return (
    <ActionButtonStyle
      theme={{
        sticky: props.sticky ?? true,
        fitToContent: props.fitToContent ?? true,
      }}
    >
      <button
        className={`${props.className ?? "btn btn--active manage__btn"} ${
          props.disabled && "btn--disabled"
        }`}
        onClick={(e) => {
          !props.disabled && props.onClick && props.onClick(e);
        }}
      >
        {props.children ? props.children : props.label ?? "+ Dodaj nowy"}
      </button>
    </ActionButtonStyle>
  );
}
