import { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const NavMenuStyle = styled.nav`
  width: 100%;
  .__nav {
    ::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    display: flex;
    align-items: center;
    list-style: none;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    margin-bottom: unset;
    margin-top: unset;
    padding: unset;
    box-shadow: inset 0 0 15px var(--col-bg-secondary);
    gap: 1rem;
  }
`;

export interface INavMenu {
  children: any;
  className?: string;
  onItemSelected?: (item: unknown) => void;
}

export function Navigation(props: INavMenu) {
  const _child = (
    props.children?.map ? props.children : [props.children]
  ).filter((e: any) => e);

  const [activeTab, setActiveTab] = useState(_child[0]);
  const mainRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLUListElement | null>(null);
  const activeBtnRef = useRef<HTMLButtonElement | null>(null);

  const scrollIntoView = (el: HTMLElement | null) =>
    el &&
    el.scrollIntoView({
      behavior: "smooth",
      inline: "center",
    });

  useEffect(() => {
    scrollIntoView(activeBtnRef.current);
  }, [activeTab]);

  return (
    <NavMenuStyle>
      <div ref={mainRef} className={props.className ?? ""}>
        <ul className={`__nav`} ref={headerRef}>
          {_child.map((item: any, i: number) => (
            <li key={item?.key ?? i} className={`__item`}>
              <div
                className={`__el ${activeTab.key === item.key ? "active" : ""}`}
                onClick={(e: any) => {
                  activeBtnRef.current = e.target;
                  setActiveTab(item);
                }}
              >
                {item}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </NavMenuStyle>
  );
}
