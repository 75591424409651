import useDispatcherState from "../hooks/useDispatcherState";
import { ProductModel } from "../models/api/product";
import { List } from "../components/common/List";
import { ProductVariant } from "../components/feature/ProductVariant";
import { useState } from "react";
import { VariantModel } from "../models/api/variant";
import styled from "styled-components";
import { IOrder } from "../models/app/IOrder";
import { Quantity } from "../components/common/Quantity";
import { User } from "../models/api/user";
import { StoreKeysEnum } from "../store/storeKeysEnum";
import { SideSelector } from "../components/feature/SidesSelector";
import { SideModel } from "../models/api/side";

const SelectVariantStyle = styled.div`
  height: 100%;
  .selected-variant {
    font-size: 1.8rem;
    height: 100%;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1rem;
    &__header {
      display: flex;
      margin-top: 1rem;
      gap: 1rem;
      width: 100%;
      justify-content: space-between;
      text-align: left;
      & .description {
        flex-basis: 70%;
        display: block;
      }
      & .change {
        align-self: center;
        .btn {
          flex-basis: 30%;
          height: fit-content;
        }
      }
    }
    &__quantity {
      display: flex;
      justify-content: space-between;
    }
    &__sides {
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 100%;
      .sides {
        padding-left: 1rem;
      }
    }
    &__remarks {
      width: 100%;
      textarea {
        width: 100%;
      }
    }
    &__actions {
      display: flex;
      justify-content: space-between;

      .quick-order {
        flex-basis: 50%;
      }
      .add-to-order {
        flex-basis: 50%;
        margin-left: 1rem;
        display: flex;
        justify-content: end;
      }
    }
  }
`;

export interface ISelectVariant {
  product: ProductModel;
  variantId?: number;
  onProductAddedToOrder: (order: IOrder) => void;
}

export function SelectVariantView(props: ISelectVariant) {
  const [user] = useDispatcherState<User>(StoreKeysEnum.User);
  const [selectedVariant, setSelectedVariant] = useState<
    VariantModel | undefined
  >(props.product.variants?.find((e) => e.id === props.variantId));
  const [quantity, setQuantity] = useState<number>();
  const [sides, setSides] = useState<SideModel[]>();
  const [remarks, setRemarks] = useState<string>();

  return (
    <SelectVariantStyle>
      {selectedVariant && (
        <div className="selected-variant">
          <div className="selected-variant__header">
            <div className="description">
              <div className="description__product-name">
                {props.product?.description}
              </div>
              <div className="description__variant-name">
                {selectedVariant.description}{" "}
              </div>
            </div>
            <div className="change">
              <button
                className="btn btn--active"
                onClick={() => setSelectedVariant(undefined)}
              >
                Kliknij aby zmienić
              </button>
            </div>
          </div>
          <div className="selected-variant__quantity">
            Ilość:
            <Quantity
              initialValue={1}
              onValueChanged={(value) => setQuantity(value)}
            ></Quantity>
          </div>
          {props.product.sides && props.product.sides.length > 0 && (
            <div className="selected-variant__sides">
              Dodatki:
              <SideSelector
                product={props.product}
                onSelectionChanged={(e) => setSides(e)}
                className="sides"
              />
            </div>
          )}
          <div className="selected-variant__remarks">
            <div> Uwagi do produktu</div>
            <textarea
              name="Uwagi do produktu"
              onChange={(e) => setRemarks(e.target.value)}
            ></textarea>
          </div>
          <div className="selected-variant__actions">
            <div className="quick-order">{/* SPACER */}</div>
            <div className="add-to-order">
              <button
                className="btn btn--alert"
                onClick={() => {
                  // add items as many times as quantity is set
                  [...Array(quantity)].forEach((_, i) =>
                    props.onProductAddedToOrder({
                      product: { ...props.product },
                      variant: selectedVariant,
                      sides: sides,
                      remarks: remarks,
                    })
                  );
                }}
              >
                Dodaj ten produkt do zamówienia
              </button>
            </div>
          </div>
        </div>
      )}
      {!selectedVariant && (
        <List list={props.product?.variants}>
          {(variant) => (
            <ProductVariant
              variant={variant}
              onVariantSelected={() => setSelectedVariant(variant)}
            ></ProductVariant>
          )}
        </List>
      )}
    </SelectVariantStyle>
  );
}
