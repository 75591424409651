import useDispatcherState from "../hooks/useDispatcherState";
import { useApiCall } from "../hooks/useApiCall";
import { List } from "../components/common/List";
import { useNavigate } from "react-router";
import { IOrder } from "../models/app/IOrder";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { Role, User } from "../models/api/user";
import { RestrictedElement } from "../components/common/RestrictedElement";
import { OrdersUrl, PriceUrl } from "../config";
import { OrderModel } from "../models/api/order";
import { ActiveOrderModel } from "../models/api/activeOrderModel";
import { OrderStateEnum } from "../models/enums/orderStateEnum";
import { info, error } from "../components/common/Toast";
import { StoreKeysEnum } from "../store/storeKeysEnum";
import { stringToHexColor } from "../helpers/stringToHexColor";
import { Loader } from "../components/common/Loader";
import { OrderPriceModel } from "../models/api/orderPriceModel";
import useVariant from "../hooks/useVariant";
import { SideModel } from "../models/api/side";
import { CreateOrderOptions } from "../components/feature/CreateOrderOptions";
import SvgTrash from "../components/icons/SvgTrash";
import { Icon } from "../components/common/Icon";
import useIsOrderValid from "../hooks/useIsOrderValid";

const CreateOrderStyle = styled.div`
  .order-list {
    background-color: var(--col-bg-primary);
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 2rem;
    &__order {
      width: 100%;
      border-bottom: var(--border-width) solid var(--col-bg-secondary);
      .side {
        font-size: 0.8rem;
        gap: 0.5rem;
        .price {
          align-items: right;
          padding-right: 4.5rem;
        }
      }
      & li:not(:last-child) {
        border-bottom: var(--border-width) solid var(--col-bg-secondary);
      }
      & li:last-child {
        padding-bottom: unset;
      }
    }
    &__item {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem;
      gap: 0.5rem;
      .description {
        flex-basis: 40%;
        text-align: left;
      }
      .remarks {
        flex-basis: 40%;
        text-align: left;
      }
      .price {
        flex-basis: 40%;
        text-align: right;
        &__value {
          display: flex;
          margin-left: 0.5rem;
          justify-content: right;
          &--old {
            text-decoration: line-through;
            margin-right: 0.5rem;
          }
        }
      }
      .action {
        width: 80px;
      }
    }
  }
  .order-options {
    margin-top: unset;
    margin-bottom: unset;
  }
  .order-action {
    display: flex;
    justify-content: center;
    border-top: var(--border-width) solid var(--col-fg-secondary);
    padding-top: 1rem;
  }
  .applied-promos {
    font-size: 0.7rem;
    font-style: italic;
    text-align: left;
  }
  .available-codes {
    padding-bottom: 1rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    .promo-code {
      gap: 1rem;
    }
  }
  .break-words {
    white-space: break-spaces !important;
  }
`;

export function CreateOrderView() {
  const [order, setOrder] = useDispatcherState<ActiveOrderModel | null>(
    StoreKeysEnum.Order
  );
  const [promoCodes, setPromoCodes] = useState<string[]>([]);
  const { getVariant } = useVariant();

  const [orderWithPrice, setOrderWithPrice] = useState<OrderPriceModel>();

  const [isPending, setIsPending] = useState(false);
  const [user] = useDispatcherState<User>(StoreKeysEnum.User);
  const navigate = useNavigate();
  const url = OrdersUrl;
  const [, , , , post] = useApiCall<OrderModel[]>({});
  const isOrderValid = useIsOrderValid(order ?? undefined);

  function mapOrders(
    order: OrderModel[],
    price?: OrderPriceModel,
    state?: OrderStateEnum
  ) {
    const orderList: OrderModel[] = order.map((el, i) => {
      return {
        productId: el?.product?.id,
        variantId: el?.variant?.id,
        quantity: 1,
        remarks: el?.remarks,
        price: el?.variant?.price,
        state: state ?? el.state ?? OrderStateEnum.InProgress,
        sideIds: el.sides?.map((e) => e.id!),
        sellerId: user.id,
        promoId: price?.appliedPromos.find(
          (e) => e.id === price.order[i].promoId
        )?.id,
      };
    });
    return orderList;
  }

  function sendOrder(order: ActiveOrderModel, price?: OrderPriceModel) {
    const state =
      order?.orders![0].state === OrderStateEnum.Awaiting
        ? OrderStateEnum.Awaiting
        : OrderStateEnum.InProgress;
    const orderList = mapOrders(order.orders!, price, state);
    const newOrder: ActiveOrderModel = {
      ...order,
      orders: orderList,
    };
    setIsPending(true);
    post(url, newOrder).then(
      (response: any) => {
        const orderId = stringToHexColor(response.data.message);
        info(
          <div style={{ borderBottom: `1rem solid ${orderId}` }}>
            {"Zamówienie zostało złożone. Numer zamówienia: " + orderId}
          </div>
        );
        setOrder(null);
        setIsPending(false);
        navigate("/");
      },
      () => {
        error("Nie udało się złożyć zamówienia");
        setIsPending(false);
        navigate("/");
      }
    );
  }

  function deleteItem(index: number) {
    setOrder((old) => {
      const _order = {
        ...old,
        orders: (() => {
          if (old?.orders?.length) {
            old.orders.splice(index, 1);
            old.orders?.length && getOrderPrice(old.orders, promoCodes);
            return old.orders.length ? old.orders : undefined;
          }
        })(),
      };
      return _order.orders?.length ? _order : null;
    });
    setOrderWithPrice(undefined);
  }

  function getSidesPrice(sides?: SideModel[]) {
    return (
      sides
        ?.map((s) => s.price ?? 0)
        .reduce((prev: number, curr: number) => prev + curr, 0) ?? 0
    );
  }

  function getPrice(order: IOrder, price: number) {
    const sides = order.sides;
    const sidesPrice = getSidesPrice(sides);
    const variantPrice = getVariant(order.variant.id!)?.price ?? 0;
    const originalPrice = variantPrice + sidesPrice;
    const priceWithDiscount = price;
    return originalPrice === priceWithDiscount ? (
      <div className="price__value">
        <div className="price__value--current">{priceWithDiscount}zł</div>
      </div>
    ) : (
      <div className="price__value">
        <div className="price__value--old">{originalPrice}zł</div>
        <div className="price__value--current">{priceWithDiscount}zł</div>
      </div>
    );
  }

  const price = (local: OrderModel[], server?: OrderPriceModel) => {
    return server
      ? server.totalPrice
      : local &&
          local
            .map((o) => {
              const sidesPrice = getSidesPrice(o.sides);
              const productPrice = o.variant?.price ?? 0;
              return sidesPrice + productPrice;
            })
            .reduce((prev: any, curr: any) => prev + curr, 0)
            ?.toFixed(2);
  };

  useEffect(() => {
    !order && navigate("/");
  }, [order]);

  useEffect(() => {
    order && getOrderPrice(order.orders!, promoCodes);
  }, []);

  function getOrderPrice(order: OrderModel[], promoCodes: string[]) {
    setIsPending(true);
    post(PriceUrl + "/orderPrice", {
      orders: mapOrders(order ?? []),
      promoCodes: promoCodes,
    }).then(
      (response: any) => {
        const price: OrderPriceModel = response.data;
        const mapped = [...order].map((o, i) => {
          return {
            ...o,
            variant: { ...o.variant, price: price.order[i].price },
          };
        });
        setOrder((old) => {
          return mapped.length ? { ...old, orders: mapped } : null;
        });
        setOrderWithPrice(price);
        setIsPending(false);
      },
      () => {
        error("Nie udało się pobrać aktualnej ceny dla zamówienia");
        setIsPending(false);
      }
    );
  }

  return (
    <>
      {order && (
        <CreateOrderStyle>
          <section className="order-list order-options">
            <CreateOrderOptions
              order={order}
              onOrderModified={(order) => setOrder(order)}
            />
          </section>

          <List list={order.orders} className="order-list">
            {(el: IOrder, index: number) => (
              <section className="order-list__order">
                <div className="order-list__item">
                  <div className="description">
                    {el.product.description} {el.variant.description}
                  </div>
                  <div className="remarks break-words">{el.remarks}</div>
                  <div className="price">
                    <span className="flex flex-right">
                      Suma: {getPrice(el, el.variant.price ?? 0)}
                    </span>
                  </div>
                  <div className="action flex flex-right">
                    <Icon onClick={() => deleteItem(index)}>
                      <SvgTrash fill={"#e4004c"} />
                    </Icon>
                  </div>
                </div>
                {el.sides && el.sides.length > 0 && (
                  <ul>
                    {el.sides.map((e, i) => (
                      <li
                        key={String(e.id) + i}
                        className="order-list__item side"
                      >
                        <div className="description">+{e.description}</div>
                        <div className="price">{e.price}zł</div>
                      </li>
                    ))}
                  </ul>
                )}
              </section>
            )}
          </List>
          {orderWithPrice && orderWithPrice?.availableCodes?.length > 0 && (
            <div className="available-codes">
              <p>Dostępne kody (kliknij aby zastosować): </p>
              <section className="flex promo-code">
                {orderWithPrice.availableCodes.map((p, i) => (
                  <button
                    className={"btn btn--active"}
                    key={p + i}
                    onClick={(e) =>
                      setPromoCodes((old) => {
                        !old.includes(p) && old.push(p);
                        getOrderPrice(order.orders!, old);
                        return [...old];
                      })
                    }
                  >
                    {p}
                  </button>
                ))}
              </section>
            </div>
          )}
          {orderWithPrice && orderWithPrice?.appliedPromos?.length > 0 && (
            <div className="applied-promos">
              <p>Zastosowane promocje: </p>
              <ul>
                {orderWithPrice.appliedPromos.map((e, i) => (
                  <li key={e.variantId ?? i}>{e.description}</li>
                ))}
              </ul>
            </div>
          )}
          <div className="order-action">
            {orderWithPrice && (
              <RestrictedElement
                restrictions={[Role.User]}
                fallback={<>Suma: {price(order.orders!, orderWithPrice)} zł</>}
              >
                <button
                  disabled={!isOrderValid}
                  className={"btn btn--active btn--big break-words"}
                  onClick={() => sendOrder(order, orderWithPrice)}
                >
                  Złóż zamówienie: {price(order.orders!, orderWithPrice)} zł
                </button>
              </RestrictedElement>
            )}
          </div>
        </CreateOrderStyle>
      )}
      <Loader loaderVisible={isPending}></Loader>
    </>
  );
}
