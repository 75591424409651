import { daysOfWeek } from "./WeekDaySelector";
export interface IDateTimeParser {
  date?: string | Date | null;
  displayDate?: boolean;
  displayTime?: boolean;
  displayDayOfWeek?: boolean;
  className?: string;
}

export function DateTimeComponent(props: IDateTimeParser) {
  const {
    date,
    displayDate = true,
    displayTime = true,
    displayDayOfWeek = false,
    className = "",
  } = props;
  const _date = date && new Date(date);
  return (
    <section className={className}>
      {_date ? (
        <>
          {displayDayOfWeek &&
            daysOfWeek.find((e) => e.key === _date.getDay())?.value}{" "}
          {displayDate && _date.toLocaleDateString()}{" "}
          {displayTime && _date.toLocaleTimeString()}
        </>
      ) : null}
    </section>
  );
}
