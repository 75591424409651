import { format } from "date-fns";

export function getW3CString(date?: Date): string {
  if (!date) date = new Date(Date.now());
  const days = format(date, "yyyy-MM-dd");
  const hours = format(date, "HH:mm");
  const _date = `${days}T${hours}`;
  return _date;
}

export function getDateFromW3CString(date: string): Date {
  return new Date(Date.parse(date));
}
