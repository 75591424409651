import { getIngredient } from "../helpers/getIngredient";
import useDispatcher from "./useDispatcher";

export default function useIngredient(ingredientId?: number) {
  const dispatcher = useDispatcher();

  const getIngredientFn = (id: number) => {
    return getIngredient(id, dispatcher.Dispatcher);
  };

  return {
    ingredient: getIngredient(ingredientId ?? NaN, dispatcher.Dispatcher),
    getIngredient: getIngredientFn,
  };
}
