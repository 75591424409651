import { getVariant } from "../helpers/getVariant";
import useDispatcher from "./useDispatcher";

export default function useVariant(variantId?: number) {
  const dispatcher = useDispatcher();

  const getVariantFn = (id: number) => {
    return getVariant(id, dispatcher.Dispatcher);
  };

  return {
    variant: getVariant(variantId ?? NaN, dispatcher.Dispatcher),
    getVariant: getVariantFn,
  };
}
