import { useApiCall } from "../../hooks/useApiCall";
import { InventoryUrl } from "../../config";
import { AsyncComponent } from "../common/AsyncComponent";
import { useEffect, useMemo, useState } from "react";
import { Table } from "../common/Table";
import { UnitModel } from "../../models/api/unit";
import { IngredientModel } from "../../models/api/ingredient";
import { ModifiedInventoryModel } from "../../models/api/modifiedInventory";
import useEditableTable from "../../hooks/useEditableTable";
import { TableCellEditButton } from "../common/TableCellEditButton";
import { ActionButton } from "../common/ActionButton";
import { Input } from "../common/Input";
import { StockModel } from "../../models/api/stock";
import { DropDown } from "../common/DropDown";
import { ActionEnum } from "../../models/enums/actionEnum";
import { DateTimeComponent } from "../common/DateTimeComponent";
import { SimpleDatePicker } from "../common/SimpleDatePicker";
import styled from "styled-components";
import { GenericView } from "../common/GenericView";
import { IQuery } from "../common/Filters";
import { StockFilters } from "./ManageStockFilters";
import { FilterPillButton } from "../common/FilterPill";
import { LinearProgressBar } from "../common/LinearProgressBar";
import { ViewMoreButton } from "../common/ViewMoreButton";
import { ManageStockNewStock } from "./ManageStockNewStock";
import useDispatcherState from "../../hooks/useDispatcherState";
import { StoreKeysEnum } from "../../store/storeKeysEnum";
import { LongPress } from "../common/hocs/LongPress";

const StockStyle = styled.section`
  .stock-selections {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: var(--border-width) solid var(--col-bg-secondary);
    display: flex;
    &__selections {
      display: flex;
      flex-wrap: wrap;
      gap: 0.2rem;
    }
  }
  .stock-table {
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
  }
`;

export function ManageStock() {
  const [response, isLoading, error, get, post] = useApiCall<StockModel[]>({
    setResponseOnPost: false,
  });
  const [units] = useDispatcherState<UnitModel[]>(StoreKeysEnum.Units);
  const [ingredients] = useDispatcherState<IngredientModel[]>(
    StoreKeysEnum.Ingredients
  );
  const [aggregated, setAggregated] = useState(true);
  const [stock, setStock] = useState<StockModel[]>();
  const { updateEntry, abortUnfocusedEdits, abortAllEdits } =
    useEditableTable();
  const [view, setView] = useState<React.ReactNode>();
  const [query, setQuery] = useState<IQuery[]>([
    {
      key: "aggregateIngredients",
      value: true,
      label: "widok uproszczony",
    },
    {
      key: "activeOnly",
      value: true,
      label: "na stanie",
    },
  ]);

  const invokeFiltersView = (
    ingredients: IngredientModel[],
    viewClosed?: boolean
  ) => {
    setView(
      <GenericView
        viewTitle={"Filtruj wyniki"}
        closed={viewClosed}
        onClose={() => setView(undefined)}
        fitToContent={true}
      >
        <StockFilters
          ingredients={ingredients}
          query={query}
          onApply={(filters) => {
            setQuery(filters);
            invokeFiltersView(ingredients, true);
          }}
        ></StockFilters>
      </GenericView>
    );
  };

  const invokeAddNewStockView = (
    ingredients: IngredientModel[],
    viewClosed?: boolean
  ) => {
    setView(
      <GenericView
        viewTitle={"Dodaj nową dostawę"}
        closed={viewClosed}
        onClose={() => setView(undefined)}
        fitToContent={true}
      >
        <ManageStockNewStock
          ingredients={ingredients}
          getUnitName={getUnitName}
          onStockModified={(stock) => {
            applyChanges({ item: stock, action: ActionEnum.Create });
            invokeAddNewStockView(ingredients, true);
          }}
        ></ManageStockNewStock>
      </GenericView>
    );
  };

  const applyChanges = async (stock: ModifiedInventoryModel<StockModel>) => {
    const endpoint = stock.action === ActionEnum.Update ? "modify" : "add";
    post(InventoryUrl + "/stock/" + endpoint, stock.item).then((val: any) => {
      setQuery((old) => [...old]);
    });
  };

  function getUnitName(ingredientId: number) {
    return (
      units?.find(
        (e) =>
          e.id === ingredients?.find((el) => el.id === ingredientId)?.fkUnitId
      )?.description ?? ""
    );
  }

  function formatValue(value?: number, ingredientId?: number) {
    return value !== undefined
      ? value + " " + getUnitName(ingredientId ?? -1)
      : "";
  }

  function clearSelection(key: string) {
    setQuery((old) => {
      const _filtered = old.filter((e) => e.key !== key);
      return [..._filtered];
    });
  }

  useEffect(() => {
    let url = "?";
    query.forEach((q) => (url += q.key + "=" + q.value + "&"));
    setAggregated(query?.find((e) => e.key === "aggregateIngredients")?.value);
    get(InventoryUrl + "/stock" + url);
  }, [query]);

  useEffect(() => {
    response && setStock(response);
  }, [response]);

  const columns = useMemo(
    () =>
      [
        {
          Header: "Składnik",
          accessor: "ingredientId",
          Cell: (props: { value: number; row: any }) => {
            return (
              <>
                {props.row.state.isEditEnabled ? (
                  <DropDown
                    selectedIndex={ingredients?.findIndex(
                      (el) => el.id === props.value
                    )}
                    items={
                      ingredients
                        ? ingredients?.map((el) => {
                            return { key: el.id, value: el.description };
                          })
                        : []
                    }
                    onItemSelected={(e) =>
                      (props.row.state.ingredientId = e?.key)
                    }
                  ></DropDown>
                ) : (
                  ingredients?.find((el) => el.id === props.value)?.description
                )}
              </>
            );
          },
        },
        {
          Header: "Dostarczono",
          accessor: "initialAmount",
          Cell: (props: { value: number; row: any }) => {
            return (
              <div>
                {props.row.state.isEditEnabled ? (
                  <Input
                    defaultValue={props.value}
                    type="number"
                    onChange={(e) => {
                      props.row.state.initialAmount = e;
                    }}
                  ></Input>
                ) : (
                  formatValue(props.value, props.row.original.ingredientId)
                )}
              </div>
            );
          },
        },
        {
          Header: "Pozostało",
          accessor: "actualAmount",
          sortType: (rowA: any, rowB: any, id: any, desc: any) => {
            const _first = rowA.values.actualAmount / rowA.values.initialAmount;
            const _second =
              rowB.values.actualAmount / rowB.values.initialAmount;
            if (_first > _second) return 1;
            if (_second > _first) return -1;
            return 0;
          },
          Cell: (props: { value: number; row: any }) => {
            return (
              <div>
                {props.row.state.isEditEnabled ? (
                  <Input
                    defaultValue={props.value}
                    type="number"
                    onChange={(e) => {
                      props.row.state.actualAmount = e;
                    }}
                  ></Input>
                ) : (
                  <>
                    {props.value !== null && (
                      <LinearProgressBar
                        maxValue={props.row.original.initialAmount}
                        currentValue={props.value}
                        title={formatValue(
                          props.value,
                          props.row.original.ingredientId
                        )}
                      ></LinearProgressBar>
                    )}
                  </>
                )}
              </div>
            );
          },
        },
        !aggregated && {
          Header: "Cena",
          accessor: "price",
          Cell: (props: { value: number; row: any }) => {
            return (
              <div>
                {props.row.state.isEditEnabled ? (
                  <Input
                    defaultValue={props.value}
                    type="number"
                    onChange={(e) => {
                      props.row.state.price = e;
                    }}
                  ></Input>
                ) : (
                  props.value && props.value + " zł"
                )}
              </div>
            );
          },
        },
        !aggregated && {
          Header: "Data zamówienia",
          accessor: "orderDate",
          Cell: (props: { value: string; row: any }) => (
            <>
              {props.row.state.isEditEnabled && props.value ? (
                <SimpleDatePicker
                  selectedDate={new Date(props.value)}
                  customHour={{
                    hours: 0,
                    min: 0,
                    sec: 0,
                    ms: 0,
                  }}
                  onSelectedDateChanged={(e) =>
                    (props.row.state.orderDate = e.value)
                  }
                ></SimpleDatePicker>
              ) : (
                <DateTimeComponent
                  date={props.value ?? new Date(Date.now()).toISOString()}
                  displayTime={false}
                ></DateTimeComponent>
              )}
            </>
          ),
        },
        !aggregated && {
          Header: "Uwagi",
          accessor: "description",
          Cell: (props: { value: string; row: any }) => (
            <>
              {props.row.state.isEditEnabled ? (
                <Input
                  defaultValue={props.value}
                  onChange={(e) => {
                    props.row.state.description = e;
                  }}
                ></Input>
              ) : (
                props.value
              )}
            </>
          ),
        },
        !aggregated && {
          id: "Action",
          Cell: (props: { row: any; preparedRows: any }) => {
            return (
              <LongPress onLongPress={() => abortAllEdits(props.preparedRows)}>
                <TableCellEditButton
                  row={props.row}
                  onEntryUpdated={(row) => applyChanges(updateEntry(row))}
                ></TableCellEditButton>
              </LongPress>
            );
          },
        },
      ].filter((e) => e),
    [stock, aggregated, ingredients, units]
  );

  return (
    <StockStyle>
      <AsyncComponent data={stock} isLoading={isLoading} error={error}>
        {(stock: StockModel[]) => {
          return (
            <>
              <div className="stock-selections flex-center-justify">
                <div className="stock-selections__selections">
                  {query.map((q, i) => (
                    <FilterPillButton
                      key={i}
                      onClick={() => clearSelection(q.key)}
                    >
                      <>{q.label}</>
                    </FilterPillButton>
                  ))}
                </div>
                <ViewMoreButton
                  onClick={() => invokeFiltersView(ingredients ?? [])}
                ></ViewMoreButton>
              </div>
              <section className="stock-table">
                <Table
                  columns={columns}
                  data={stock ?? []}
                  onRowClick={abortUnfocusedEdits}
                ></Table>
              </section>
            </>
          );
        }}
      </AsyncComponent>
      <ActionButton
        label=" + Nowa dostawa"
        onClick={() => invokeAddNewStockView(ingredients ?? [])}
      ></ActionButton>
      {view}
    </StockStyle>
  );
}
