import { useEffect, useState } from "react";
import { IActionButton, ActionButton } from "./ActionButton";

export interface IConfirmBtn extends IActionButton {
  label: string;
  confirmLabel: string;
  action: (args?: any) => any;
  className?: string;
  confirmClassName?: string;
  cancelTimeOut?: number;
}

export function ConfirmButton(props: IConfirmBtn) {
  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(true);
  };

  useEffect(() => {
    const deleteTimeout = setTimeout(() => {
      setConfirm(false);
    }, props.cancelTimeOut ?? 3000);
    return () => clearTimeout(deleteTimeout);
  }, [confirm]);

  if (confirm) {
    return (
      <ActionButton
        {...props}
        onClick={() => {
          props.action();
          setConfirm(false);
        }}
        className={props.confirmClassName ?? "btn btn--active btn--alert"}
        aria-label="Confirm Action"
      >
        {props.confirmLabel}
      </ActionButton>
    );
  } else {
    return (
      <ActionButton
        {...props}
        onClick={handleConfirm}
        className={props.className ?? "btn btn--active"}
        aria-label="Action"
      >
        {props.label}
      </ActionButton>
    );
  }
}
