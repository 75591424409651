import { useContext, useEffect, useState } from "react";
import { DispatcherContext } from "../index";
import {
  IDispatcher,
  IWatchable,
} from "../models/dispatcher/dispatcher-interfaces";

export default function useDispatcherState<T>(
  key: string,
  fallback?: (args: any) => Promise<T>
): [T, (value: T | ((oldValue: T) => T)) => void] {
  const Dispatcher: IDispatcher = useContext(DispatcherContext);
  const initialValue = Dispatcher.state.getState(key, fallback);

  const [state, setState] = useState(initialValue as T);

  const updateState = (value: T | ((oldValue: T) => T)) => {
    function getDestructured(obj: any) {
      return Array.isArray(obj) ? [...obj] : { ...obj };
    }

    if (value instanceof Function) {
      const _previous = Dispatcher.state.getState(key);
      Dispatcher.state.saveState(
        key,
        value(_previous ? getDestructured(_previous) : _previous)
      );
    } else {
      Dispatcher.state.saveState(key, value);
    }
  };

  useEffect(() => {
    const _state = Dispatcher.on(key, (value: IWatchable<T>) => {
      setState(value.currentValue as T);
    });
    return () => {
      Dispatcher.off(_state);
    };
  }, [Dispatcher, key]);


  return [state, updateState];
}
