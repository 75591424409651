/* eslint-disable eqeqeq */
import { AuthenticationUrl } from "../config";
import Axios from "axios";
import {
  IDispatcher,
  IRequest,
  IResponse,
} from "../models/dispatcher/dispatcher-interfaces";
import { Event } from "../models/dispatcher/dispatcher-events";
import { Request } from "../models/dispatcher/dispatcher-requests";
import { Credentials, User } from "../models/api/user";
import { State } from "../models/dispatcher/dispatcher-states";
import { IModalContent } from "../components/common/Modal";

function getModalContent(content: string): IModalContent {
  return {
    actionName: "OK",
    header: "Error",
    content: content,
  };
}

export default function configureAuthentication(
  dispatcher: IDispatcher,
  history: any
) {
  // configure axios
  Axios.interceptors.request.use(function (config: any) {
    var cookie = getCookie("order_maker_token");
    if (cookie) {
      config.headers.Authorization = "Bearer " + cookie;
    }
    return config;
  });

  Axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (
        401 === error.response?.status &&
        window.location.pathname !== "/login"
      ) {
        history.push("/login");
        dispatcher.state.saveState(State.User, null);
        window.location.reload();
      } else if (
        error.response?.status === 400 ||
        error.response?.status === 401
      ) {
        return Promise.reject(error);
      } else {
        if (error != "Cancel") {
          dispatcher.dispatch(Event.ShowModal, getModalContent(error));
        }
        return Promise.reject(error);
      }
    }
  );

  function getCookie(cookieName: string) {
    var name = cookieName + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  dispatcher.on(Request.LogIn, (request: IRequest<Credentials, User>) => {
    const loginFailed = () => {
      const user: User = { authenticated: false };
      const dispatcherReponse: IResponse<User> = {
        response: user,
        requestID: request.requestID,
        isExecutedSuccesfully: false,
      };
      request.requestCompleted(dispatcherReponse);
    };
    const credentials: Credentials = request.requestData;
    Axios({
      method: "post",
      url: AuthenticationUrl + "/authenticate",
      data: credentials,
    })
      .then(function (response) {
        if (response.status === 200) {
          let d = new Date();
          d.setTime(d.getTime() + 1 * 12 * 60 * 60 * 1000);
          let expires = "expires=" + d.toUTCString();
          document.cookie =
            "order_maker_token=" +
            response.data.token +
            ";" +
            expires +
            ";path=/;samesite=Lax";
          const user: User = {
            authenticated: true,
            role: response.data.role,
            id: response.data.id,
            accessToken: response.data.token,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            registerDate: new Date(response.data.registerDate),
            lastLogInDate: new Date(response.data.lastLogInDate),
            status: response.data.status,
          };
          const dispatcherReponse: IResponse<User> = {
            response: user,
            requestID: request.requestID,
            isExecutedSuccesfully: true,
          };
          dispatcher.state.saveState(State.User, user);
          request.requestCompleted(dispatcherReponse);
        } else {
          loginFailed();
        }
      })
      .catch(function () {
        loginFailed();
      });
  });

  dispatcher.on(Request.GetCurrentUser, (request: IRequest<null, User>) => {
    Axios({
      method: "get",
      url: AuthenticationUrl + "/getCurrentUser",
    })
      .then(function (response) {
        if (response.status === 200) {
          const user: User = {
            authenticated: true,
            role: response.data.role,
            id: response.data.id,
            accessToken: response.data.token,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            registerDate: new Date(response.data.registerDate),
            lastLogInDate: new Date(response.data.lastLogInDate),
            status: response.data.status,
          };
          const dispatcherReponse: IResponse<User> = {
            response: user,
            requestID: request.requestID,
            isExecutedSuccesfully: true,
          };
          dispatcher.state.saveState(State.User, user);
          request.requestCompleted(dispatcherReponse);
        } else {
          return { authenticated: false };
        }
      })
      .catch(function () {
        const user = { authenticated: false };
        const dispatcherReponse: IResponse<User> = {
          response: user,
          requestID: request.requestID,
          isExecutedSuccesfully: false,
        };
        dispatcher.state.saveState(State.User, user);
        request.requestCompleted(dispatcherReponse);
        return user;
      });
  });

  dispatcher.on(Request.LogOut, (request: IRequest<null, string>) => {
    document.cookie = "order_maker_token='' ;path=/";
    dispatcher.state.saveState(State.User, { authenticated: false });
    const dispatcherReponse: IResponse<string> = {
      response: "Command executed succesfully",
      requestID: request.requestID,
      isExecutedSuccesfully: true,
    };
    request.requestCompleted(dispatcherReponse);
  });
}
