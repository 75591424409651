import { AsyncComponent } from "../common/AsyncComponent";
import { useApiCall } from "../../hooks/useApiCall";
import { GeneralUrl } from "../../config";
import { useEffect, useState } from "react";
import { ConfirmButton } from "../common/ConfirmButton";
import { Toggler } from "../common/Toggler";
import { AppSettingsKeys } from "../../models/app/appSettingsKeys";
import { AppSettingModel } from "../../models/api/appSettingModel";
import { OrderStateEnum } from "../../models/enums/orderStateEnum";
import useDispatcherState from "../../hooks/useDispatcherState";
import { StoreKeysEnum } from "../../store/storeKeysEnum";
import { ViewTypeEnum } from "../../models/enums/viewTypeEnum";

export function AdminAppSettings() {
  const [serverSettings, setServerSettings] = useDispatcherState<
    AppSettingModel[]
  >(StoreKeysEnum.ServerSettings);
  const [response, isLoading, error, , post, refresh] = useApiCall<
    Array<AppSettingModel>
  >({
    url: GeneralUrl + "/settings",
    setResponseOnPost: false,
  });

  const [settings, setSettings] = useState<AppSettingModel[]>(serverSettings);

  useEffect(() => {
    if (response) {
      setServerSettings(response);
      setSettings(response);
    }
  }, [response]);

  return (
    <AsyncComponent data={response} isLoading={isLoading} error={error}>
      {(data: any) => {
        return (
          <>
            <section className="app-settings">
              <div className="flex flex-center-justify">
                <div className="name flex-left text-left">
                  Pokaż wszystkie niezrealizowane zamówienia:
                </div>
                <div
                  style={{ ["--mr" as any]: "0rem" }}
                  className="input flex-right margin-x"
                >
                  <Toggler
                    toggled={
                      settings
                        .find(
                          (e) =>
                            e.key ===
                            AppSettingsKeys.RestoreAllNotCompletedOrders
                        )!
                        .value?.toLowerCase() === "true"
                    }
                    onToggleChanged={(value) =>
                      setSettings((old: AppSettingModel[]) => {
                        const _setting = old?.find(
                          (e) =>
                            e.key ===
                            AppSettingsKeys.RestoreAllNotCompletedOrders
                        );
                        _setting!.value = String(value);
                        return [...old];
                      })
                    }
                  ></Toggler>
                </div>
              </div>
              <hr
                style={{
                  color: "var(--col-bg-secondary)",
                  marginTop: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              ></hr>
              <div className="flex flex-center-justify">
                <div className="name flex-left text-left">
                  Wyłącz automatyczną aktywację oczekujących zamówień:
                </div>
                <div
                  style={{ ["--mr" as any]: "0rem" }}
                  className="input flex-right margin-x"
                >
                  <Toggler
                    toggled={
                      settings
                        .find(
                          (e) =>
                            e.key ===
                            AppSettingsKeys.DisableAutoSwitchingOrderToActive
                        )!
                        .value?.toLowerCase() === "true"
                    }
                    onToggleChanged={(value) =>
                      setSettings((old: AppSettingModel[]) => {
                        const _setting = old?.find(
                          (e) =>
                            e.key ===
                            AppSettingsKeys.DisableAutoSwitchingOrderToActive
                        );
                        _setting!.value = String(value);
                        return [...old];
                      })
                    }
                  ></Toggler>
                </div>
              </div>
              <hr
                style={{ color: "var(--col-bg-secondary)", margin: "0.5rem" }}
              ></hr>
              <div className="flex flex-center-justify">
                <div className="name flex-left text-left">
                  Nowe zamówienie domyślnie jako "Oczekujące":
                </div>
                <div
                  style={{ ["--mr" as any]: "0rem" }}
                  className="input flex-right margin-x"
                >
                  <Toggler
                    toggled={
                      settings?.find(
                        (e) => e.key === AppSettingsKeys.DefaultNewOrderState
                      )!.value === OrderStateEnum.Awaiting
                    }
                    onToggleChanged={(value) =>
                      setSettings((old: AppSettingModel[]) => {
                        const _setting = old?.find(
                          (e) => e.key === AppSettingsKeys.DefaultNewOrderState
                        );
                        _setting!.value = value
                          ? OrderStateEnum.Awaiting
                          : OrderStateEnum.InProgress;
                        return [...old];
                      })
                    }
                  ></Toggler>
                </div>
              </div>
              <hr
                style={{ color: "var(--col-bg-secondary)", margin: "0.5rem" }}
              ></hr>
              <div className="flex flex-center-justify">
                <div className="name flex-left text-left">
                  Widok listy jako domyślny dla zamówień oczekujących:
                </div>
                <div
                  style={{ ["--mr" as any]: "0rem" }}
                  className="input flex-right margin-x"
                >
                  <Toggler
                    toggled={
                      settings?.find(
                        (e) =>
                          e.key ===
                          AppSettingsKeys.DefaultAwaitingOrdersViewType
                      )!.value === ViewTypeEnum.List
                    }
                    onToggleChanged={(value) =>
                      setSettings((old: AppSettingModel[]) => {
                        const _setting = old?.find(
                          (e) =>
                            e.key ===
                            AppSettingsKeys.DefaultAwaitingOrdersViewType
                        );
                        _setting!.value = value
                          ? ViewTypeEnum.List
                          : ViewTypeEnum.Table;
                        return [...old];
                      })
                    }
                  ></Toggler>
                </div>
              </div>
              <hr
                style={{ color: "var(--col-bg-secondary)", margin: "0.5rem" }}
              ></hr>
              <div className="flex flex-center-justify">
                <div className="name flex-left text-left">
                  Data rozpoczęcia wymagana dla oczekującego zamówienia:
                </div>
                <div
                  style={{ ["--mr" as any]: "0rem" }}
                  className="input flex-right margin-x"
                >
                  <Toggler
                     toggled={
                      settings
                        .find(
                          (e) =>
                            e.key ===
                            AppSettingsKeys.RequireExpectedStartingDateInNewOrder
                        )!
                        .value?.toLowerCase() === "true"
                    }
                    onToggleChanged={(value) =>
                      setSettings((old: AppSettingModel[]) => {
                        const _setting = old?.find(
                          (e) =>
                            e.key ===
                            AppSettingsKeys.RequireExpectedStartingDateInNewOrder
                        );
                        _setting!.value = String(value);
                        return [...old];
                      })
                    }
                  ></Toggler>
                </div>
              </div>
              <hr
                style={{ color: "var(--col-bg-secondary)", margin: "0.5rem" }}
              ></hr>
              <div className="flex flex-center-justify">
                <div className="name flex-left text-left">
                  Data zakończenia wymagana dla oczekującego zamówienia:
                </div>
                <div
                  style={{ ["--mr" as any]: "0rem" }}
                  className="input flex-right margin-x"
                >
                  <Toggler
                     toggled={
                      settings
                        .find(
                          (e) =>
                            e.key ===
                            AppSettingsKeys.RequireExpectedCompletionDateInNewOrder
                        )!
                        .value?.toLowerCase() === "true"
                    }
                    onToggleChanged={(value) =>
                      setSettings((old: AppSettingModel[]) => {
                        const _setting = old?.find(
                          (e) =>
                            e.key ===
                            AppSettingsKeys.RequireExpectedCompletionDateInNewOrder
                        );
                        _setting!.value = String(value);
                        return [...old];
                      })
                    }
                  ></Toggler>
                </div>
              </div>
            </section>
            <hr />
            <ConfirmButton
              label="Zapisz ustawienia"
              confirmLabel="Kliknij aby zapisać ustawienia"
              action={() => {
                post(GeneralUrl + "/settings/set", settings).then(() =>
                  refresh()
                );
              }}
            ></ConfirmButton>
          </>
        );
      }}
    </AsyncComponent>
  );
}
