import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Input } from "./Input";

const FormStyle = styled.div`
  .form-container {
    display: block;
    color: var(--col-fg-primary);
    & .form-header {
      color: var(--col-fg-active);
    }
    & .form-field {
      display: flex;
      flex-direction: column;
      justify-content: cen1er;
      align-items: center;
      & label {
        color: var(--col-fg-primary);
        display: block;
        margin-right: unset !important;
      }
      padding-bottom: 0.5rem !important;
    }
    & .form-action {
      margin: auto;
      display: block;
    }
  }
`;
export enum FieldType {
  Text = "text",
  Password = "password",
  Email = "email",
}

export interface FormField {
  label: string;
  displayLabel: string;
  type: FieldType;
  required: boolean;
  pattern?: string;
  title?: string;
}

export interface FormModel {
  model: {
    field: FormField;
    value: string | null;
  }[];
}

export interface IForm {
  title: string;
  labels: FormField[];
  actionTitle: string;
  onSubmit: (model: FormModel) => void;
}

export function Form(props: IForm) {
  let mutableFormModel = props.labels.map((field) => {
    return { field: field, value: null as any };
  });

  const [formModel, setFormModel] = useState(mutableFormModel);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const formState = useRef(null);
  if (formState.current !== null) {
    mutableFormModel = formState.current as any;
  } else {
    (formState.current as any) = mutableFormModel;
  }

  function requiredFieldsCheck(formModel: any): boolean {
    let allRequiredSet = true;
    formModel.model.forEach(
      (field: { field: { required: any }; value: string | null }) => {
        if (
          field.field.required &&
          (field.value === null || field.value === "")
        ) {
          allRequiredSet = false;
        }
      }
    );
    if (allRequiredSet && formRef.current) {
      return formRef.current.querySelectorAll("input:invalid").length === 0
        ? true
        : false;
    }
    return false;
  }

  function handleChange(field: FormField, value: string) {
    let elem = mutableFormModel.find((el) => el.field.label === field.label);
    if (elem) {
      value !== "" ? (elem.value = value as any) : (elem.value = null);
    }
    let submitEnabled = requiredFieldsCheck({ model: mutableFormModel });
    setFormModel(mutableFormModel);
    setButtonEnabled(submitEnabled);
  }

  useEffect(() => {
    let form = formRef.current;
    mutableFormModel.forEach((field) => {
      field.value = (
        document.getElementsByClassName(
          field.field.label
        )[0] as HTMLInputElement
      ).value;
      field.value = (
        form?.getElementsByClassName(field.field.label)[0] as HTMLInputElement
      ).value;
    });
    setFormModel(mutableFormModel);
    setButtonEnabled(requiredFieldsCheck({ model: mutableFormModel }));
  }, [formRef, mutableFormModel]);

  function onSubmit(event: { preventDefault: () => void }) {
    event.preventDefault();
    props.onSubmit({ model: formModel });
  }

  function handleKeyDown(event: any) {
    if (event.key === "Enter" && buttonEnabled) {
      props.onSubmit({ model: formModel });
    }
  }

  return (
    <FormStyle>
      <div className="form-container" onKeyDown={handleKeyDown}>
        <h2 className="form-header">{props.title}</h2>
        <form ref={formRef} className="form-content">
          <div className="form-group">
            {props.labels.map((field) => {
              return (
                <div className="form-field" key={field.label}>
                  <label htmlFor={field.label}>{field.displayLabel}</label>
                  <Input
                    id={field.label}
                    className={field.label}
                    type={field.type}
                    pattern={field.pattern}
                    title={field.title}
                    onChange={(e) => handleChange(field, String(e))}
                  ></Input>
                </div>
              );
            })}
          </div>
        </form>
        {buttonEnabled ? (
          <button className={"form-action btn btn--active"} onClick={onSubmit}>
            {" "}
            {props.actionTitle}{" "}
          </button>
        ) : (
          <button
            disabled
            className={"form-action btn btn--disabled"}
            onClick={onSubmit}
          >
            {" "}
            {props.actionTitle}{" "}
          </button>
        )}
      </div>
    </FormStyle>
  );
}
