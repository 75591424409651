import { useState, useEffect } from "react";
import useMounted from "./useMounted";

export default function useOnline() {
  const mounted = useMounted();
  const [online, setOnline] = useState(navigator.onLine);

  // online/offline behaviour
  useEffect(() => {
    const _offline = () => setOnline(false);
    const _online = () => setOnline(true);
    globalThis.addEventListener("offline", _offline);
    globalThis.addEventListener("online", _online);
    return () => {
      globalThis.removeEventListener("offline", _offline);
      globalThis.removeEventListener("online", _online);
    };
  }, [mounted]);

  return online;
}
