import { ProductModel } from "../models/api/product";
import { IDispatcher } from "../models/dispatcher/dispatcher-interfaces";
import { StoreKeysEnum } from "../store/storeKeysEnum";

export const getSide = (id: number, dispatcher: IDispatcher) => {
  const products = dispatcher.state.getState(
    StoreKeysEnum.Products
  ) as ProductModel[];

  const findSide = (product: ProductModel, sideId: number) => {
    return product.sides?.find((el) => el.id === sideId);
  };
  if (!isNaN(id) && products) {
    for (const product of products) {
      const _side = findSide(product, id);
      if (_side) return _side;
    }
  }
  return null;
};
