import { useRef } from "react";
import styled from "styled-components";
import { OrderModel } from "../../models/api/order";
import { ActiveOrderModel } from "../../models/api/activeOrderModel";
import { useEffect, useState } from "react";
import { Role, User } from "../../models/api/user";
import { stringToHexColor } from "../../helpers/stringToHexColor";
import { hexToRGB } from "../../helpers/hexToRgba";
import { List } from "../common/List";
import useDispatcherState from "../../hooks/useDispatcherState";
import { StoreKeysEnum } from "../../store/storeKeysEnum";
import { useApiCall } from "../..//hooks/useApiCall";
import { OrdersUrl } from "../../config";
import { Order } from "./Order";
import { error } from "../common/Toast";
import { OrderStateEnum } from "../../models/enums/orderStateEnum";
import { AxiosResponse } from "axios";
import { RestrictedElement } from "../common/RestrictedElement";
import { DateTimeComponent } from "../common/DateTimeComponent";
import { AwaitingOrderModel } from "../../models/api/awaitingOrderModel";
interface StyleProps {
  borderColor: string;
}

const OrderListStyle = styled.section<StyleProps>`
  border: 3px solid ${(props: StyleProps) => props.borderColor};
  background-color: ${(props: StyleProps) => hexToRGB(props.borderColor, 0.2)};
  flex-grow: 1;
  width: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    padding: 0.3rem;
    height: 2rem;
  }
  .details {
    font-size: 0.8rem;
    display: flex;
    justify-content: space-between;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
  &__elapsedTime {
    text-align: left;
  }
  &__orderId {
    text-align: right;
    user-select: none;
  }
  .order-list {
    width: 100%;
    &__list {
      display: flex;
    }
  }
`;

export interface IOrderList {
  order: ActiveOrderModel | AwaitingOrderModel;
  isAwaitingOrder?: boolean;
  onOrderFinalized?: (order: ActiveOrderModel | AwaitingOrderModel) => void;
}

export function OrderList(props: IOrderList) {
  const [elapsedTime, setElapsedTime] = useState<string>("...");
  const [user] = useDispatcherState<User>(StoreKeysEnum.User);
  const [orders, setOrders] = useState(props.order.orders);
  const [finalizeAll, setFinalizeAll] = useState(false);
  const idRef = useRef<HTMLSpanElement>(null);
  const [, , , , post] = useApiCall({});
  const url = OrdersUrl + "/finalize";
  function onOrderFinalized(order: OrderModel) {
    post(url, { ...order, makerId: user.id }).then(
      (r: AxiosResponse<{ id: number }>) => {
        setOrders((old) => {
          return [...old!].filter((e) => e.id !== r.data.id);
        });
      },
      () => {
        error("Nie udało się zrealizowac zamówienia");
        setOrders((old) => {
          const _index = old!.findIndex((e) => e.id === order.id);
          const _old = [...old!];
          if (_index > -1)
            _old[_index] = { ...order, state: OrderStateEnum.InProgress };
          return _old;
        });
      }
    );
  }

  function updateElapsedTime(order: OrderModel[]) {
    const date = new Date(Date.now());
    const _startingDate =
      (props.order as any).startingDate ?? props.order.expectedStartingDate;
    const startingDate = _startingDate ? new Date(_startingDate) : null;
    if (startingDate) {
      const diff = new Date(
        Math.abs(startingDate.getTime() - date.getTime())
      ).toISOString();
      setElapsedTime(diff.substring(11, 19));
    }
  }

  function onLongPress(e?: Event) {
    e?.preventDefault();
    const allowed = [Role.Admin, Role.Manager, Role.Employee];
    user && user.role && allowed.includes(user.role) && setFinalizeAll(true);
  }

  function finalizeAllOrders() {
    if (props.onOrderFinalized) {
      props.onOrderFinalized(props.order);
      return;
    }
    // default behavior
    orders!.forEach((o) => {
      onOrderFinalized({ ...o, state: OrderStateEnum.Completed });
    });
  }

  useEffect(() => {
    let interval: any = null;
    if (!props.isAwaitingOrder) {
      interval = setInterval(() => updateElapsedTime(orders!), 1000);
    }
    idRef.current?.addEventListener("contextmenu", onLongPress);
    return () => {
      idRef.current?.removeEventListener("contextmenu", onLongPress);
      !props.isAwaitingOrder && clearInterval(interval);
    };
  }, [orders]);

  useEffect(() => {
    setOrders(props.order.orders);
  }, [props.order]);

  return (
    <OrderListStyle borderColor={stringToHexColor(String(orders![0]?.orderId))}>
      <section className="header" onClick={() => setFinalizeAll(false)}>
        <div className="header__elapsedTime">
          {!props.isAwaitingOrder ? (
            elapsedTime
          ) : (
            <DateTimeComponent
              date={(props.order as AwaitingOrderModel).creationDate}
            ></DateTimeComponent>
          )}
        </div>
        <div className="header__orderId">
          <RestrictedElement
            restrictions={[Role.User]}
            fallback={<>{stringToHexColor(String(orders![0]?.orderId))}</>}
          >
            <span ref={idRef}>
              {finalizeAll ? (
                <button
                  className="btn--alert"
                  onClick={() => finalizeAllOrders()}
                >
                  {!props.isAwaitingOrder ? "Zrealizuj wszystkie" : "Aktywuj"}
                </button>
              ) : (
                <>{stringToHexColor(String(orders![0]?.orderId))}</>
              )}
            </span>
          </RestrictedElement>
        </div>
      </section>
      <section className="details">
        <div className="details__description text-left empty-hidden">
          {props.order.description}
        </div>
      </section>
      <section className="details">
        <div className="details__description text-left empty-hidden">
          {props.order.options?.length
            ? props.order.options.map((e) => e.value).join(", ")
            : null}
        </div>
      </section>
      <section className="details">
        <div className="details__expectedCompletionDate text-left empty-hidden">
          {props.order.expectedCompletionDate && (
            <div className="flex gap">
              {"Zakończ do:"}
              <DateTimeComponent
                date={props.order.expectedCompletionDate}
                displayDayOfWeek={true}
              />
            </div>
          )}
        </div>
      </section>
      <section className="order-list">
        <List list={orders} className={"order-list order-list__list"}>
          {(item: OrderModel) => (
            <div className="item">
              <Order
                order={item}
                isAwaitingOrder={props.isAwaitingOrder}
                onOrderFinalized={onOrderFinalized}
              ></Order>
            </div>
          )}
        </List>
      </section>
    </OrderListStyle>
  );
}
