import { FC, ReactElement } from "react";
import { Container } from "reactstrap";
import { NavMenu } from "./NavMenu";
import styled from "styled-components";

const ContainerStyle = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100 - 2 * var(--spacing-v));
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .main-navigation {
    flex-basis: 10%;
  }
  .main-container {
    flex-basis: 100%;
    overflow: auto;
  }
`;

export const Layout: FC<{ children: ReactElement[] }> = ({ children }) => {
  return (
    <ContainerStyle>
      <NavMenu className="main-navigation" />
      <Container className="main-container">{children}</Container>
    </ContainerStyle>
  );
};
