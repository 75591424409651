import { useApiCall } from "../../hooks/useApiCall";
import { InventoryUrl } from "../../config";
import { AsyncComponent } from "../common/AsyncComponent";
import { useEffect, useMemo } from "react";
import { Table } from "../common/Table";
import { UnitModel } from "../../models/api/unit";
import { IngredientModel } from "../../models/api/ingredient";
import { ModifiedInventoryModel } from "../../models/api/modifiedInventory";
import useEditableTable from "../../hooks/useEditableTable";
import { TableCellEditButton } from "../common/TableCellEditButton";
import { ActionButton } from "../common/ActionButton";
import { Input } from "../common/Input";
import { StoreKeysEnum } from "../../store/storeKeysEnum";
import useDispatcherState from "../../hooks/useDispatcherState";
import { LongPress } from "../common/hocs/LongPress";

export function ManageUnits() {
  const [response, isLoading, error, get, post] = useApiCall<UnitModel[]>({
    url: InventoryUrl + "/unit",
    setResponseOnPost: false,
  });
  const [units, setUnits] = useDispatcherState<UnitModel[] | null>(
    StoreKeysEnum.Units
  );
  const { updateEntry, abortUnfocusedEdits, abortAllEdits } =
    useEditableTable();

  const applyChanges = async (
    ingredient: ModifiedInventoryModel<UnitModel>
  ) => {
    post(InventoryUrl + "/unit/modify", ingredient).then((val: any) => {
      get(InventoryUrl + "/unit");
    });
  };

  function addNewItem() {
    setUnits((old) => {
      const _old = old ? [...old] : [];
      _old.push({
        description: "Nowa jednostka",
      });
      return _old;
    });
  }

  useEffect(() => {
    response && setUnits(response);
  }, [response]);

  const columns = useMemo(
    () => [
      {
        Header: "Nazwa jednostki",
        accessor: "description",
        Cell: (props: { value: string; row: any }) => {
          return (
            <div>
              {props.row.state.isEditEnabled ? (
                <Input
                  defaultValue={props.value}
                  onChange={(e) => {
                    props.row.state.description = e;
                  }}
                ></Input>
              ) : (
                props.value
              )}
            </div>
          );
        },
      },
      {
        id: "Action",
        Cell: (props: { row: any; preparedRows: any }) => {
          return (
            <LongPress onLongPress={() => abortAllEdits(props.preparedRows)}>
              <TableCellEditButton
                row={props.row}
                onEntryUpdated={(row) => applyChanges(updateEntry(row))}
              ></TableCellEditButton>
            </LongPress>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <AsyncComponent data={units} isLoading={isLoading} error={error}>
        {(ingredients: IngredientModel[]) => {
          return (
            <Table
              columns={columns}
              data={ingredients}
              onRowClick={abortUnfocusedEdits}
            ></Table>
          );
        }}
      </AsyncComponent>
      <ActionButton
        label=" + Dodaj nową jednostkę"
        onClick={() => addNewItem()}
      ></ActionButton>
    </>
  );
}
