import { AttachmentsUrl } from "../../config";
import styled from "styled-components";
import { AttachmentModel } from "../../models/api/attachmentModel";
import { useState } from "react";
import axios from "axios";

export interface AttachmentsProps {
  attachments?: AttachmentModel[];
  className?: string;
}

function AttachmentsUnstyled(props: AttachmentsProps) {
  const [image, setImage] = useState<string>();

  const downloadFile = (url: string, fileName: string) =>
    axios({
      url: url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });

  return (
    <div className={`${props.className} attachments`}>
      <p className="attachments__header">Załączniki:</p>
      <section className="attachments__images">
        {props.attachments
          ?.filter((e) => e.contentType?.includes("image"))
          .map((i) => (
            <img
              alt={i.fileName}
              className="image"
              src={`${AttachmentsUrl}?id=${i.id}`}
              onClick={() => setImage(`${AttachmentsUrl}?id=${i.id}`)}
            ></img>
          ))}
        {image && (
          <section
            onClick={() => setImage(undefined)}
            className="image__fullscreen"
          >
            <img alt="attachment" src={image}></img>
          </section>
        )}
      </section>
      <details>
        <summary>Pokaż wszystkie załączniki</summary>
        <section className="attachments__file-list">
          <ul>
            {props.attachments?.map((i) => (
              <li>
                <span className="file">
                  <div>{i.fileName}</div>
                  <button
                    className="btn--active"
                    onClick={() =>
                      downloadFile(
                        `${AttachmentsUrl}?id=${i.id}`,
                        i.fileName ?? ""
                      )
                    }
                  >
                    Pobierz
                  </button>
                </span>
              </li>
            ))}
          </ul>
        </section>
      </details>
    </div>
  );
}

export const Attachments = styled(AttachmentsUnstyled)`
  background-color: var(--col-bg-tertiary);
  padding: 0.5rem;
  .attachments__file-list {
    .file {
      display: flex;
      justify-content: space-between;
      margin: 0.5rem;
    }
  }
  .image__fullscreen {
    position: absolute;
    z-index: 99;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: var(--backdrop-translucent);
    display: flex;
    justify-content: center;
    cursor: pointer;
    img {
      max-width: 95vw;
      max-height: 95vh;
      display: flex;
      margin: auto;
    }
    overflow: auto;
  }
  .attachments__header {
    padding-top: 1rem;
  }
  .attachment__images {
    display: flex;
  }
  .image {
    height: 10rem;
    margin: 0.5rem;
    cursor: pointer;
  }
`;
