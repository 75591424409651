import styled from "styled-components";
import { ConfirmButton } from "../common/ConfirmButton";
import { Input } from "../common/Input";
import { ColorSelector } from "../common/ColorSelector";
import { GroupModel } from "../../models/api/group";
import { useEffect, useMemo, useState } from "react";
import { ProductModel } from "../../models/api/product";
import ExpandableContainer from "../common/ExpandableContainer";
import CheckboxFilter from "../common/CheckboxFilter";
import { FilterPillButton } from "../common/FilterPill";
import SvgAngleDown from "../icons/SvgAngleDown";
import SvgAngleRight from "../icons/SvgAngleRight";

const ModifyProductStyle = styled.section`
  --gap: 0.2rem;
  --mr: 0.2rem;
  --mt: 1rem;
  input {
    max-width: 20rem;
    border: var(--border-width) solid (var(--col-fg-active));
  }
  .filter-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .input {
      margin-left: auto;
      width: 15rem;
    }
    svg {
      margin-bottom: 0.2rem;
      path {
        fill: var(--col-fg-primary);
      }
    }
    &__title {
      width: 100%;
      text-align: left;
    }
  }
  .product-creator {
    h4,
    h5,
    h6 {
      text-align: left;
      margin-top: 1rem;
    }
    &__product {
      max-width: 50rem;
      margin: auto;
      .input-field {
        width: 100%;
        margin: 0.2rem;
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        min-height: 2rem;
        .name {
          flex-basis: 40%;
          text-align: left;
        }
        .group-name {
          margin: 0.2rem;
        }
        .input {
          flex-basis: 60%;
          margin-left: auto;
        }
      }
    }
  }
  .creator-actions {
    margin-top: 0.5rem;
    position: relative;
  }
`;

export interface IModifyGroupComponent {
  group?: GroupModel;
  allProducts?: ProductModel[];
  onGroupModified?: (group: GroupModel) => void;
}

export function ModifyGroup(props: IModifyGroupComponent) {
  const [updatedGroup, setUpdatedGroup] = useState<GroupModel>(
    props.group ?? {}
  );
  const [selectedProducts, setSelectedProducts] = useState<Set<ProductModel>>(
    new Set(props.group?.products ?? [])
  );
  const [appliedFilter, setAppliedFilter] = useState<string>();
  const [checkboxItems, setCheckboxItems] = useState<ProductModel[]>([]);

  useEffect(() => {
    const items = (props.allProducts ?? [])
      ?.filter((e) =>
        appliedFilter
          ? e.description
              ?.toLocaleLowerCase()
              .includes(appliedFilter.toLocaleLowerCase())
          : e
      )
      .sort((a, b) => (a.displaySequence ?? NaN) - (b.displaySequence ?? NaN));
    setCheckboxItems(items);
  }, [appliedFilter, props]);

  const ProductsHeaderInput = useMemo(() => {
    return (
      <div className="filter-header">
        <Input
          onClick={(e: any) => {
            e.stopPropagation();
          }}
          className="input"
          value={appliedFilter}
          onChange={(e) => setAppliedFilter(String(e))}
        ></Input>
      </div>
    );
  }, [appliedFilter]);

  const ProductsHeaderTemplate = (isExpanded: boolean) => {
    !isExpanded && setAppliedFilter("");
    return (
      <span className="filter-header">
        <div>
          {isExpanded ? (
            <SvgAngleDown
              height="1.2rem"
              width="1.2rem"
              viewBox="0 0 350 500"
            />
          ) : (
            <SvgAngleRight
              height="1.2rem"
              width="1.2rem"
              viewBox="0 0 350 500"
            />
          )}
        </div>
        {isExpanded ? (
          ProductsHeaderInput
        ) : (
          <div className="filter-header__title">Wybierz produkty</div>
        )}
      </span>
    );
  };

  return (
    <ModifyProductStyle>
      <div className="product-creator__product">
        <div className="input-field">
          <div className="name">Nazwa grupy:</div>{" "}
          <Input
            className="input group-name"
            value={updatedGroup.description ?? ""}
            onChange={(e) =>
              setUpdatedGroup((old: any) => {
                return {
                  ...old,
                  description: e,
                };
              })
            }
          ></Input>
        </div>
        <div className="input-field">
          <div className="name">Kolor kafelka:</div>
          <div className="input">
            <ColorSelector
              selectedColor={updatedGroup?.colorCode!}
              onSelectedColorChanged={(value) =>
                setUpdatedGroup((old: any) => {
                  return {
                    ...old,
                    colorCode: value,
                  };
                })
              }
            ></ColorSelector>
          </div>
        </div>
        <div className="input-field ">
          <div className="name">Produkty w grupie:</div>
        </div>
        <div className="flex-wrap flex-left gap margin-right">
          {Array.from(selectedProducts).map((e) => (
            <FilterPillButton
              label={e.description}
              onClick={() =>
                setSelectedProducts((old) => {
                  old.delete(e);
                  return new Set(old);
                })
              }
            ></FilterPillButton>
          ))}
        </div>
        <div className="products-selector margin-y">
          <ExpandableContainer
            header={(isExpanded) => ProductsHeaderTemplate(isExpanded)}
            className="filter"
            expanded={false}
          >
            <CheckboxFilter
              onSelectionChanged={(e) => {
                setSelectedProducts((old) => {
                  const currentSelections: Set<ProductModel> = new Set(
                    e.map((e: any) => e.product)
                  );
                  const selectedButFilteredOut = Array.from(old).filter(
                    (e) => !checkboxItems.find((u) => u.id === e.id)
                  );
                  const union = new Set([
                    ...selectedButFilteredOut,
                    ...currentSelections,
                  ]);
                  return union;
                });
              }}
              multiSelectMode={true}
              items={checkboxItems.map((p) => {
                return {
                  key: p.id,
                  value: p.description,
                  product: p,
                  selected: Array.from(selectedProducts).find(
                    (e) => e.id === p.id
                  )
                    ? true
                    : false,
                };
              })}
            ></CheckboxFilter>
          </ExpandableContainer>
        </div>
      </div>
      <div className="creator-actions flex-right">
        <ConfirmButton
          label={props.group ? "Zapisz zmiany" : "Zapisz tę grupę"}
          confirmLabel={props.group ? "Zapisać zmiany?" : "Zapisać tę grupę?"}
          className="btn btn--active"
          action={() => {
            props.onGroupModified &&
              props.onGroupModified({
                ...updatedGroup,
                products: Array.from(selectedProducts),
                productsIds: Array.from(selectedProducts).map((e) => e.id!),
              });
          }}
        ></ConfirmButton>
      </div>
    </ModifyProductStyle>
  );
}
