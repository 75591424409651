import React, { useState } from "react";
import styled from "styled-components";
import { Navigation } from "./Navigation";

const TabComponentStyle = styled.div`
  .tabs {
    &__header {
      margin-bottom: 0.5rem;
    }
    &__content {
      width: 100%;
      background-color: var(--col-bg-primary);
      h3 {
        font-family: inherit;
        font-size: 1.5rem;
      }
    }
    &__button {
      background: rgba(0, 0, 0, 0);
      padding: 4px;
      color: var(--col-fg-primary);
      box-shadow: none;
      outline: none;
      border: none;
      cursor: pointer;
      font-size: 1rem;
      display: block;
      position: relative;
      font-family: inherit;
      font-weight: 400;
      margin-right: 1rem;
    }
  }
  .tabs__button:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 2px;
    background: var(--col-fg-active);
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s, opacity 0.4s ease-in-out;
  }
  .tabs__button.active::after {
    opacity: 1;
    visibility: visible;
    transition: visibility 0s, opacity 0.4s ease-in-out;
  }
  .tabs__item:not(:last-child) {
    margin-right: 1rem;
  }
`;

export interface ITabComponent {
  children: any;
  onItemSelected?: (item: ITab) => void;
  selectedKey?: string;
}

export interface ITab {
  key: string;
  title: string;
  className?: string;
  children: React.ReactNode;
}

export function TabComponent(props: ITabComponent) {
  const _child = props.children.map ? props.children : [props.children];
  const _selected = props.selectedKey
    ? _child.find((e: any) => e.props.key === props.selectedKey)
    : _child[0];
  const [activeTab, setActiveTab] = useState(_selected);
  return (
    <TabComponentStyle>
      <div className="tabs">
        <Navigation className="tabs__header">
          {_child.map((item: any, i: number) => (
            <button
              className={`tabs__button ${
                activeTab.key === item.key ? "active" : ""
              }`}
              onClick={(e: any) => {
                setActiveTab(item);
              }}
              key={i + item.props.title}
            >
              {item.props.title}
            </button>
          ))}
        </Navigation>
        <div className={`tabs__content`}>{activeTab}</div>
      </div>
    </TabComponentStyle>
  );
}

export function TabItem(props: ITab) {
  return (
    <div className={props.className ? props.className : undefined}>
      {props.children}
    </div>
  );
}
