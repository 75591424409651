import { useTable, useSortBy, useRowState, useExpanded } from "react-table";
import { useRef, useState, useEffect } from "react";
import styled from "styled-components";

const TableStyle = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  thead th {
    position: sticky;
    top: 0;
  }
  .r-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    &__header {
      .content {
        display: inline-flex;
      }
    }
    .table > :not(:last-child) > :last-child > * {
      border: unset;
    }
    color: var(--col-fg-primary);
    th {
      white-space: nowrap;
      text-align: left;
      background-color: var(--col-bg-primary);
    }
    tr,
    th {
      border-bottom: var(--border-width) solid var(--col-bg-secondary);
      padding: 0.4rem;
      font-weight: unset;
      &.sort {
        ::after {
          font-size: 0.6rem;
          margin-left: 0.1rem;
          margin-right: 0.1rem;
          content: "▲";
          color: transparent;
        }
        &--asc {
          ::after {
            content: "▲";
            color: unset;
            vertical-align: text-top;
          }
        }
        &--desc {
          ::after {
            content: "▼";
            color: unset;
          }
        }
      }
    }
    tr[expanded="true"] {
      border-bottom: none;
    }
    tr[role="childrow"] {
      padding-left: 1rem;
      border: none;
      + tr[role="row"] {
        border-top: var(--border-width) solid var(--col-bg-secondary);
      }
    }
    td {
      padding: 0.3rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    &--no-data {
      margin-top: 2rem;
      display: flex;
      justify-content: center;
      position: relative;
      height: 2rem;
      & .content {
        position: fixed;
      }
    }
  }
  ${(props) => props.theme};
`;

export function Table({
  columns,
  data,
  noDataLabel = "Brak elementów do wyświetlenia",
  onRowClick = (row, preparedRows) => {},
  updateMyData = () => {},
  className = "",
  additionalStyle = "",
  ...rest
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state: { expanded },
    onExpandedChange: setExpanded,
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      ...rest,
    },
    useSortBy,
    useRowState,
    useExpanded,
    (hooks) => {
      hooks.visibleColumns.push((cols) => [...cols]);
    }
  );

  const [allExpanded, setAllExpanded] = useState(false);

  const preparedRows = useRef([]);

  const getChildRender = (row) => {
    const data = row.cells.find((e) => e.column.id === "expander");
    return data?.column?.Child(data.row.original);
  };

  const getChildComponent = (row) => {
    const data = row.cells.find((e) => e.column.isExpander);
    return data?.column?.ChildComponent(data.row.original, row);
  };

  useEffect(() => {
    setAllExpanded(false);
  }, [data]);

  useEffect(() => {
    preparedRows.current?.forEach((row) => row.toggleRowExpanded(allExpanded));
  }, [allExpanded, preparedRows]);

  return (
    <TableStyle theme={additionalStyle}>
      <table className={`r-table ${className}`} {...getTableProps()}>
        <thead className="r-table__header">
          {headerGroups.map((headerGroup, i) => (
            <tr key={i + "tablerow"} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => {
                return (
                  <th
                    key={i + "tablecolumn"}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`${
                      column.isSorted
                        ? column.isSortedDesc
                          ? "sort sort--desc"
                          : "sort sort--asc"
                        : "sort"
                    }`}
                  >
                    <span className="r-table__header content">
                      <>
                        {column.isExpander === "true" &&
                          column.Expander &&
                          column.render("Expander", {
                            allExpanded,
                            setAllExpanded,
                          })}
                        {column.render("Header")}
                      </>
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            preparedRows.current[i]
              ? (preparedRows.current[i] = row)
              : preparedRows.current.push(row);
            return (
              <>
                <tr
                  key={row}
                  index={i}
                  onClick={(e) =>
                    onRowClick && onRowClick(row, preparedRows.current)
                  }
                  expanded={row.isExpanded?.toString()}
                  role={row.depth ? "childrow" : "row"}
                  {...row.getRowProps()}
                >
                  {row.depth ? (
                    <td colSpan={row.cells.length}>{getChildRender(row)}</td>
                  ) : (
                    <>
                      {row.cells.map((cell, i) => {
                        return (
                          <td key={i + "tabledata"} {...cell.getCellProps()}>
                            {cell.render("Cell", {
                              preparedRows: preparedRows.current,
                            })}
                          </td>
                        );
                      })}
                    </>
                  )}
                </tr>
                {row.isExpanded && (
                  <td colSpan={row.cells.length}>{getChildComponent(row)}</td>
                )}
              </>
            );
          })}
        </tbody>
      </table>
      {rows.length === 0 && (
        <section className="r-table--no-data">
          <section className="content">{noDataLabel}</section>
        </section>
      )}
    </TableStyle>
  );
}
