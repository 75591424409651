import styled from "styled-components";
import ExpandableContainer from "../common/ExpandableContainer";
import CheckboxFilter from "../common/CheckboxFilter";
import { useEffect, useState } from "react";
import { IRange, RangeSelector } from "../common/RangeSelector";
import { Filters, IQuery } from "../common/Filters";
import { OptionModel } from "../../models/api/optionModel";
const ReportsFiltersStyle = styled.section`
  height: 100%;
`;

export interface IAwaitingOrdersFilters {
  options: OptionModel[];
  onApply: (query: IQuery[]) => void;
  query?: IQuery[];
  children?: any;
}

export function AwaitingOrdersFilters(props: IAwaitingOrdersFilters) {
  const [query, setQuery] = useState<IQuery[]>(props.query ?? []);

  const [options, setOptions] = useState<number[]>(
    props.query?.filter((e) => e.key === "options")?.map((v) => v.value)[0] ??
      []
  );

  const [creationDate, setCreationDate] = useState<IRange>(
    getRangeFromQuery(props.query, "fromCreationDate", "toCreationDate")
  );

  const [expectedStartingDate, setExpectedStartingDate] = useState<IRange>(
    getRangeFromQuery(
      props.query,
      "fromExpectedStartingDate",
      "toExpectedStartingDate"
    )
  );

  const [expectedCompletionDate, setExpectedCompletionDate] = useState<IRange>(
    getRangeFromQuery(
      props.query,
      "fromExpectedCompletionDate",
      "toExpectedCompletionDate"
    )
  );

  function getRangeFromQuery(
    query?: IQuery[],
    fromKey?: string,
    toKey?: string
  ): IRange {
    const _from = new Date(query?.find((e) => e.key === fromKey)?.value);
    const _to = new Date(query?.find((e) => e.key === toKey)?.value);
    return {
      from: !isNaN(_from?.getTime()) ? _from : null,
      to: !isNaN(_to?.getTime()) ? _to : null,
    };
  }

  useEffect(() => {
    const query = [
      {
        key: "options",
        value: options,
        label: options
          .map((e) => props.options.find((i) => i.id === e)?.value)
          .join(", "),
      },
      {
        key: "fromCreationDate",
        value: creationDate?.from?.toISOString(),
        label: "utworzono od: " + creationDate?.from?.toLocaleDateString(),
      },
      {
        key: "toCreationDate",
        value: creationDate?.to?.toISOString(),
        label: "utworzono do: " + creationDate?.to?.toLocaleDateString(),
      },
      {
        key: "fromExpectedStartingDate",
        value: expectedStartingDate?.from?.toISOString(),
        label:
          "rozpocznij od: " + expectedStartingDate?.from?.toLocaleDateString(),
      },
      {
        key: "toExpectedStartingDate",
        value: expectedStartingDate?.to?.toISOString(),
        label:
          "rozpocznij do: " + expectedStartingDate?.to?.toLocaleDateString(),
      },
      {
        key: "fromExpectedCompletionDate",
        value: expectedCompletionDate?.from?.toISOString(),
        label:
          "zakończ od: " + expectedCompletionDate?.from?.toLocaleDateString(),
      },
      {
        key: "toExpectedCompletionDate",
        value: expectedCompletionDate?.to?.toISOString(),
        label:
          "zakończ do: " + expectedCompletionDate?.to?.toLocaleDateString(),
      },
    ];
    const isValid = (e: IQuery) => {
      if (e.value === null || e.value === undefined) return false;
      if (Array.isArray(e.value) && e.value.length === 0) return false;
      return true;
    };
    setQuery(query.filter((e) => isValid(e)) as IQuery[]);
  }, [
    props.options,
    options,
    creationDate,
    expectedStartingDate,
    expectedCompletionDate,
  ]);

  return (
    <ReportsFiltersStyle>
      <Filters query={query} setQuery={setQuery} onApply={props.onApply}>
        <section className="item filter filter__inline">
          <div className="filter-label text-left">Utworzono</div>
          <div className="filter-value">
            <RangeSelector
              fromDate={creationDate.from ?? undefined}
              toDate={creationDate.to ?? undefined}
              onSelectedRangeChanged={(date) => {
                setCreationDate({ from: date.from, to: date.to });
              }}
            />
          </div>
        </section>
        <section className="item filter filter__inline">
          <div className="filter-label text-left">Rozpocznij</div>
          <div className="filter-value">
            <RangeSelector
              fromDate={expectedStartingDate.from ?? undefined}
              toDate={expectedStartingDate.to ?? undefined}
              onSelectedRangeChanged={(date) => {
                setExpectedStartingDate({ from: date.from, to: date.to });
              }}
            />
          </div>
        </section>
        <section className="item filter filter__inline">
          <div className="filter-label text-left">Zakończ</div>
          <div className="filter-value">
            <RangeSelector
              fromDate={expectedCompletionDate.from ?? undefined}
              toDate={expectedCompletionDate.to ?? undefined}
              onSelectedRangeChanged={(date) => {
                setExpectedCompletionDate({ from: date.from, to: date.to });
              }}
            />
          </div>
        </section>
        <section className="item filter filter__inline">
          <ExpandableContainer header={"Opcje"} expanded={options.length > 0}>
            <CheckboxFilter
              multiSelectMode={true}
              onSelectionChanged={(e) => {
                setOptions(e.map((i) => i.key));
              }}
              items={props.options?.map((p) => {
                return {
                  key: p.id,
                  value: p.value,
                  selected: query
                    ?.find((e) => e.key === "options")
                    ?.value.includes(p.id),
                };
              })}
            ></CheckboxFilter>
          </ExpandableContainer>
        </section>
        {props.children && (
          <section className="item filter filter__inline">
            {props.children}
          </section>
        )}
      </Filters>
    </ReportsFiltersStyle>
  );
}
