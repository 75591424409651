import { useState } from "react";
import styled from "styled-components";

const LinearProgressStyle = styled.div`
  display: flex;
  width: 100%;
  height: 2rem;
  border: var(--border-width) solid var(--col-bg-focus);
  position: relative;
  &:hover {
    &:after {
      opacity: 0;
    }
  }
  .linear-progress {
    padding: 0.2rem;
    background-color: ${(props) =>
      props.theme.isThreshold
        ? "var(--col-fg-alert)"
        : "var(--col-fg-active-darker)"};
    width: ${(props) => props.theme.width};
    border-radius: calc(1 / 2 * (var(--border-radius)));
  }
  .progress-value {
    display: flex;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
  }
`;

export interface ILinearProgressBar {
  maxValue: number;
  currentValue: number;
  percentageThreshold?: number;
  title?: number | string;
  className?: string;
  disableToggleOnClick?: boolean;
}

export function LinearProgressBar(props: ILinearProgressBar) {
  const [toggled, setToggled] = useState(false);
  const width = Math.floor((props.currentValue / props.maxValue) * 100);
  const threshold = width <= (props.percentageThreshold ?? 10);
  return (
    <LinearProgressStyle theme={{ width: width + "%", isThreshold: threshold }}>
      <div className={"linear-progress " + props.className ?? ""}></div>
      <div
        title={String(props.title ?? width + "%")}
        className="progress-value"
        onClick={() => !props.disableToggleOnClick && setToggled(!toggled)}
      >
        {toggled ? props.title : (width > 0 ? width : "0") + "%"}
      </div>
    </LinearProgressStyle>
  );
}
