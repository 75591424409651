import { useState } from "react";
import styled from "styled-components";
import { SimpleDatePicker } from "./SimpleDatePicker";
import { useEffect } from "react";

const RangeSelectorStyle = styled.div`
  .range-selector {
    display: flex;
    justify-content: space-around;
    height: auto;
    max-width: 20rem;
    gap: 0.2rem;
    min-width: fit-content;
    &__clear__btn.btn,
    &__ok__btn.btn {
      height: 2rem !important;
      padding: 0 !important;
    }
    &__clear__btn .close-icon {
      font-size: 2rem;
      margin-right: 0.2rem;
    }
    &__ok__btn.btn {
      width: 2rem;
    }
    &__from {
      width: 50%;
    }
    &__to {
      width: 50%;
    }
  }
`;

export interface IRange {
  from: Date | null;
  to: Date | null;
}

export interface IRangeSelector {
  onSelectedRangeChanged?: (change: IRange) => void;
  fromDate?: Date;
  toDate?: Date;
  onClearRange?: () => void;
  forceZeroUTCOffset?: boolean;
  fromPlaceholder?: string;
  toPlaceholder?: string;
  fromCustomHour?: {
    hours: number;
    min?: number | undefined;
    sec?: number | undefined;
    ms?: number | undefined;
  };
  toCustomHour?: {
    hours: number;
    min?: number | undefined;
    sec?: number | undefined;
    ms?: number | undefined;
  };
  requireManualApply?: boolean;
  showClearButton?: boolean;
}

export function RangeSelector(props: IRangeSelector) {
  const [fromDate, setFromDate] = useState<Date | undefined>(props.fromDate);
  const [toDate, setToDate] = useState<Date | undefined>(props.toDate);
  const [rangeValid, setRangeValid] = useState(false);
  const [updateKey, setUpdateKey] = useState(Math.floor(Math.random() * 1000));

  useEffect(() => {
    setRangeValid(isRangeValid(fromDate, toDate));
  }, [fromDate, toDate]);

  function pickerValueChanged(date: Date, position: string) {
    position === "from" ? setFromDate(date) : setToDate(date);
  }

  function isRangeValid(from?: Date, to?: Date) {
    if (from && to) {
      return from < to ? true : false;
    }
    return true;
  }

  function clearRange() {
    setUpdateKey(Math.floor(Math.random() * 1000));
    setFromDate(undefined);
    setToDate(undefined);
    props.onClearRange && props.onClearRange();
  }

  function confirmRange() {
    rangeValid &&
      fromDate &&
      toDate &&
      props.onSelectedRangeChanged &&
      props.onSelectedRangeChanged({ from: fromDate, to: toDate });
  }

  useEffect(() => {
    const apply = () => {
      if (isRangeValid(fromDate, toDate)) {
        props.onSelectedRangeChanged &&
          props.onSelectedRangeChanged({
            from: fromDate ?? null,
            to: toDate ?? null,
          });
      }
    };
    !props.requireManualApply && apply();
  }, [fromDate, toDate]);

  return (
    <RangeSelectorStyle>
      <span className="range-selector">
        {props.showClearButton && (
          <div className="range-selector__clear">
            <button
              type="button"
              className="btn btn--untoggled range-selector__clear__btn"
              onClick={() => clearRange()}
              aria-label="Close"
            >
              <span className="close-icon" aria-hidden="true">
                &times;
              </span>
            </button>
          </div>
        )}
        <div className="range-selector__from">
          <SimpleDatePicker
            key={updateKey}
            selectedDate={fromDate}
            onSelectedDateChanged={(change: {
              key: string | null;
              value: Date | null;
            }) => change.value && pickerValueChanged(change.value, "from")}
            placeholder={props.fromPlaceholder}
            customHour={
              props.fromCustomHour ?? { hours: 0, min: 0, sec: 0, ms: 0 }
            }
            maxValue={toDate}
          ></SimpleDatePicker>
        </div>
        <div className="range-selector__to">
          <SimpleDatePicker
            key={updateKey}
            selectedDate={toDate}
            onSelectedDateChanged={(change: {
              key: string | null;
              value: Date | null;
            }) => change.value && pickerValueChanged(change.value, "to")}
            placeholder={props.toPlaceholder}
            customHour={
              props.toCustomHour ?? { hours: 23, min: 59, sec: 59, ms: 999 }
            }
            minValue={fromDate}
          ></SimpleDatePicker>
        </div>
        {[
          props.requireManualApply && (
            <div className="range-selector__ok">
              <button
                type="button"
                className={`btn range-selector__ok__btn ${
                  rangeValid ? "btn--active" : "btn--disabled"
                }`}
                onClick={() => confirmRange()}
                aria-label="Ok"
              >
                <span className="ok-icon" aria-hidden="true">
                  ✓
                </span>
              </button>
            </div>
          ),
        ]}
      </span>
    </RangeSelectorStyle>
  );
}
