import styled from "styled-components";

const ListStyle = styled.section`
  width: 100%;
  .list {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: space-between;
    gap: 0.5rem;
    &__item {
      height: 100%;
      &:empty {
        display: none;
      }
    }
  }
`;
export interface IList {
  list: any[] | null | undefined;
  children: (element: any, index: number) => React.ReactNode;
  className?: string;
}

export function List(props: IList) {
  return (
    <ListStyle>
      <div className={`${props.className ? props.className : "list"}`}>
        {props.list?.map((item, i) => (
          <div key={"generic-list-item-" + i} className="list list__item">
            {props.children(item, i)}
          </div>
        ))}
      </div>
    </ListStyle>
  );
}
