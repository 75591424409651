import { useState, useEffect, HTMLInputTypeAttribute } from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { getW3CString } from "../../helpers/dateToW3C";

const DatePickerStyle = styled.div`
  .date-picker {
    display: flex;
    &__label {
      color: var(--col-fg-primary);
      margin-right: 0.5rem;
      align-self: center;
    }
    &__picker {
      width: 100%;
    }
    &__input {
      box-sizing: border-box;
      border: var(--border-width) solid var(--col-fg-secondary);
      background-color: var(--col-bg-primary);
      color: var(--col-fg-primary);
      height: 2rem;
      font-size: 1rem;
      padding: unset;
      padding-left: 0.5rem;
      width: 100%;
      min-width: 9rem;
      &[type="datetime-local"] {
        min-width: 12rem;
      }
    }
    &:focus {
      outline: var(--border-width) solid var(--col-fg-active);
    }
  }
`;
export interface IDatePicker {
  pickerKey?: string;
  label?: string;
  placeholder?: string;
  selectedDate?: Date;
  customHour?: {
    hours: number;
    min?: number | undefined;
    sec?: number | undefined;
    ms?: number | undefined;
  };
  onSelectedDateChanged?: (change: {
    key: string | null;
    value: Date | null;
  }) => void;
  maxValue?: Date;
  minValue?: Date;
  useLocalOffset?: boolean;
  type?: HTMLInputTypeAttribute;
}

export function SimpleDatePicker(props: IDatePicker) {
  const [pickedDate, setPickedDate] = useState<string>("");
  const useLocalOffset = props.useLocalOffset ?? true;

  useEffect(() => {
    const type: HTMLInputTypeAttribute = props.type ?? "date";
    switch (type) {
      case "date":
        props.selectedDate && setPickedDate(props.selectedDate.toISOString());
        break;
      case "datetime-local":
        props.selectedDate && setPickedDate(getW3CString(props.selectedDate));
        break;
      default:
        break;
    }
  }, [props]);

  function pickerValueChanged(date: string) {
    if (props.onSelectedDateChanged)
      props.onSelectedDateChanged({
        key: props.pickerKey ?? null,
        value: new Date(date),
      });
    const type: HTMLInputTypeAttribute = props.type ?? "date";
    switch (type) {
      case "date":
        setPickedDate(date);
        break;
      case "datetime-local":
        setPickedDate(getW3CString(new Date(date)));
        break;
      default:
    }
  }

  const parseToISO = (value: string | Date) => {
    const _date = new Date(value);
    if (props.customHour) {
      const { hours, min, sec, ms } = props.customHour;
      useLocalOffset === false
        ? _date.setUTCHours(hours, min, sec, ms)
        : _date.setHours(hours, min, sec, ms);
    }
    return _date.toISOString();
  };

  const displayValue = (pickedValue: string) => {
    const type: HTMLInputTypeAttribute = props.type ?? "date";
    switch (type) {
      case "date":
        return format(new Date(pickedValue), "yyyy-MM-dd");
      case "datetime-local":
        return pickedValue;
      default:
        return "";
    }
  };

  const formatMinMax = (date: string) => {
    const type: HTMLInputTypeAttribute = props.type ?? "date";
    switch (type) {
      case "date":
        return format(new Date(date), "yyyy-MM-dd");
      case "datetime-local":
        return format(new Date(date), "yyyy-MM-dd'T'HH:mm");
      default:
        return "";
    }
  };

  return (
    <DatePickerStyle>
      <span className="date-picker">
        {props.label && <div>{props.label}</div>}
        <span className="date-picker__picker">
          {props.label && (
            <label className="date-picker__label" htmlFor={props.label}>
              {props.label}
            </label>
          )}
          <input
            className="date-picker__input"
            placeholder={props.placeholder}
            type={props.type ? props.type : "date"}
            id={props.label}
            name={props.label}
            onChange={(e) =>
              e.target.value && pickerValueChanged(parseToISO(e.target.value))
            }
            max={
              props.maxValue
                ? formatMinMax(props.maxValue.toISOString())
                : undefined
            }
            min={
              props.minValue
                ? formatMinMax(props.minValue.toISOString())
                : undefined
            }
            value={pickedDate && displayValue(pickedDate)}
          ></input>
        </span>
      </span>
    </DatePickerStyle>
  );
}
