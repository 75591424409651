import { useState, useEffect, ReactNode, useRef } from "react";
import styled from "styled-components";
import * as mixin from "../../styles/mixins";
import { Breakpoints } from "../../styles/breakpoints";
import useBackButton from "../../hooks/useBackButton";
import { Icon } from "./Icon";
import SvgClose from "../icons/SvgClose";

const GenericViewStyle = styled.div`
  ${mixin.aFadeInLeft}
  ${mixin.aFadeOutRight}
${mixin.vTransition}
${mixin.vBackdropCustom(1)}
.generic-view {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0%;
    right: 0%;
    top: 0%;
    z-index: 2;
    color: var(--col-fg-primary);
    background-color: var(--col-bg-secondary);
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    grid-template-rows: 5rem 1fr;
    padding: var(--spacing-v) var(--spacing-h);
    padding-top: unset;
    cursor: auto;
    box-shadow: -2px 0px 20px 0px rgba(0, 0, 0, 0.2);
    .btn--untoggled {
      padding: unset;
      margin: unset;
    }
    &__header {
      display: flex;
      margin-top: 0.5rem;
      flex-basis: 4rem;
      &__back-arrow {
        height: 10px;
        justify-self: start;
        & svg {
          height: 2em;
          width: 2em;
          fill: var(--col-fg-primary);
          margin: 0 var(--spacing-h) 0 var(--spacing-h);
        }
      }
      &__title {
        margin: auto;
        flex-basis: 100%;
        justify-content: center;
      }
      &__spacer {
        flex-basis: 4rem;
      }
    }
    &__content {
      height: 100%;
      margin: 0 var(--spacing-v);
      overflow: auto;
    }
    ${(props) => (props as any).theme};
  }
`;
export interface IGenericView {
  viewTitle?: string;
  onClose?: () => void;
  children?: ReactNode;
  fitToContent?: boolean;
  keepInitialSize?: boolean;
  style?: string;
  closed?: boolean;
}

export function GenericView(props: IGenericView) {
  const [visible, setVisible] = useState(true);
  const backPressed = useBackButton();
  const windowRef = useRef<HTMLDivElement>(null);
  const [initialSize, setInitialSize] = useState<number>();
  const keepInitialSize = props.keepInitialSize ?? true;

  const notPhonePopUpStyle = mixin.mNotPhone(`
    width: ${Breakpoints.Phone}px;
    max-width: ${keepInitialSize ? initialSize + "px" : "unset"};
    margin-right: 0;
  `);

  const PopUpStyle = `
    .title {
      font-size: 1.8rem;
    }
    ${notPhonePopUpStyle}
`;

  const FitContentStyle = `
    .title {
      font-size: 1.8rem;
    }
    width: fit-content;
    min-width: min(100vw, 30rem);
    max-width: ${keepInitialSize ? initialSize + "px" : "unset"};
    margin-right: 0;
    overflow: auto;
`;

  function closeView(a: React.AnimationEvent<HTMLDivElement>) {
    if (
      props.onClose &&
      ["a-transition-fade-out-right"].includes(a.animationName)
    ) {
      props.onClose();
    }
  }

  useEffect(() => {
    props.closed && setVisible(false);
  }, [props.closed]);

  useEffect(() => {
    if (windowRef.current) {
      const initialWidth = windowRef.current.offsetWidth;
      setInitialSize(initialWidth);
    }
  }, []);

  useEffect(() => {
    backPressed && setVisible(false);
  }, [backPressed]);

  return (
    <GenericViewStyle
      theme={
        props.style
          ? props.style
          : props.fitToContent
          ? FitContentStyle
          : PopUpStyle
      }
    >
      <div
        className={`generic-view ${
          visible ? "transition--fade-in" : "transition--fade-out"
        } `}
        onAnimationEnd={(e) => {
          closeView(e);
        }}
        ref={windowRef}
      >
        <span className="generic-view__header">
          <div
            className="generic-view__header__back-arrow btn btn--untoggled"
            tabIndex={0}
            onKeyDown={(e) => e.key === "Enter" && setVisible(false)}
          >
            <Icon onClick={() => setVisible(false)}>
              <SvgClose />
            </Icon>
          </div>
          <p className="title generic-view__header__title">{props.viewTitle}</p>
          <div className="generic-view__header__spacer"></div>
        </span>
        <div className="generic-view__content">{props.children}</div>
      </div>
      <div
        className={`backdrop ${
          visible ? "transition--fade-in" : "transition--fade-out"
        } `}
        onClick={() => setVisible(false)}
      />
    </GenericViewStyle>
  );
}
