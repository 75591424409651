import { Credentials } from "../api/user";

export interface ISendableRequest<T> {
    data?: T
    request: Request
}

export enum Request {
    LogIn = "login",
    LogOut = "logout",
    GetCurrentUser = "getCurrentUser"
}

export class LoginRequest implements ISendableRequest<Credentials> {
    constructor(credentials: Credentials) {
        this.data = credentials;
        this.request = Request.LogIn;
    }
    data: Credentials;
    request: Request;
}

export class GetCurrentUserRequest implements ISendableRequest<null> {
    constructor() {
        this.request = Request.GetCurrentUser
    }
    request: Request;
}

export class LogoutRequest implements ISendableRequest<null> {
    constructor() {
        this.request = Request.LogOut;
    }
    request: Request;
}
