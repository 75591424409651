import styled from "styled-components";
import { ManageStock } from "../components/feature/ManageStock";

const StockStyle = styled.div`
  .manage-stocks {
    padding: 0.5rem;
    padding-bottom: 0.2rem;
    background-color: var(--col-bg-primary);
  }
`;

export function StockView() {
  return (
    <StockStyle>
      <div className="manage-stocks">
        <ManageStock></ManageStock>
      </div>
    </StockStyle>
  );
}
