import { AsyncComponent } from "../common/AsyncComponent";
import { useApiCall } from "../../hooks/useApiCall";
import { useEffect, useState } from "react";
import { AttachmentsUrl } from "../../config";
import styled from "styled-components";
import { AttachmentModel } from "../../models/api/attachmentModel";
import { Icon } from "../common/Icon";
import SvgTrash from "../icons/SvgTrash";
import { List } from "../common/List";
import { AxiosResponse } from "axios";

export interface AttachmentWidgetProps {
  attachments?: AttachmentModel[];
  onAttachmentsChanged?: (attachments: AttachmentModel[]) => void;
  className?: string;
}

function AttachmentsWidgetUnstyled(props: AttachmentWidgetProps) {
  const [, isLoading, , , post] = useApiCall({
    requestHeaders: [{ "Content-Type": "multipart/form-data" }],
  });
  const [list, setList] = useState<AttachmentModel[]>(props.attachments ?? []);
  const [isError, setIsError] = useState(false);

  const addAttachment = async (fileData: any, fileName: string) => {
    try {
      const fileId = await post(`${AttachmentsUrl}/upload`, fileData).then(
        (e: AxiosResponse) => {
          return e.data.id;
        }
      );
      setIsError(false);
      setList((old) => {
        const newAttachment: AttachmentModel = {
          id: fileId,
          fileName,
        };
        return [...old, newAttachment];
      });
    } catch (error) {
      setIsError(true);
    }
  };

  const removeAttachment = async (attachment: AttachmentModel) => {
    setList((old) => {
      return [...old.filter((e) => e.id !== attachment.id)];
    });
  };

  useEffect(() => {
    props.onAttachmentsChanged && props.onAttachmentsChanged(list);
  }, [list]);

  return (
    <div className={`${props.className} add-file`}>
      <AsyncComponent data={list} isLoading={isLoading}>
        {(attachments: AttachmentModel[]) => {
          return (
            <List list={attachments} className="file-list">
              {(attachment: AttachmentModel) => (
                <section className="flex flex-center-justify">
                  <div className="fileName break-words text-left">
                    {attachment.fileName}
                  </div>
                  <div className="action flex flex-right">
                    <Icon onClick={() => removeAttachment(attachment)}>
                      <SvgTrash fill={"#e4004c"} />
                    </Icon>
                  </div>
                </section>
              )}
            </List>
          );
        }}
      </AsyncComponent>
      <form
        key={Math.random()}
        className="add-file-form"
        method="post"
        onSubmit={(e) => {
          e.preventDefault();
          if (e.nativeEvent.target != null) {
            const data = new FormData(e.target as HTMLFormElement);
            let name = (e.target as any)[0].value.split(/(\\|\/)/g).pop();
            addAttachment(data, name);
          }
        }}
        encType="multipart/form-data"
      >
        <div className="flex flex-center-justify">
          <input className="add-file" type="file" name="file" required />
          <input type="submit" className="btn--active" />
        </div>
        {isError && (
          <div className="add-file-error">Nie udało się dołączyć pliku.</div>
        )}
      </form>
    </div>
  );
}

export const AttachmentsWidget = styled(AttachmentsWidgetUnstyled)`
  position: relative;
  .file-list {
    padding-left: 1rem;
  }
  .add-file-error {
    color: var(--col-fg-alert);
    font-size: 0.8rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.5rem;
    margin: auto;
  }
  .add-file-form {
    margin-top: 1rem;
    margin-bottom: 1rem;
    input:invalid {
      outline: none !important;
      background: none !important;
    }
  }
  .list .list__item {
    width: 100%;
  }
`;
