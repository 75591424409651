import { IDispatcher } from "../models/dispatcher/dispatcher-interfaces";
import { getProduct } from "./getProduct";
import { getVariant } from "./getVariant";
import { ActiveOrderModel } from "../models/api/activeOrderModel";
import { OrdersUrl } from "../config";
import axios, { AxiosResponse } from "axios";
import { StoreKeysEnum } from "../store/storeKeysEnum";

export const decorateData = (
  data: ActiveOrderModel,
  dispatcher: IDispatcher
) => {
  const _data = { ...data };
  const extended = _data.orders?.map((el: any) => {
    return {
      ...el,
      product: getProduct(el.productId, dispatcher),
      variant: getVariant(el.variantId, dispatcher),
    };
  });
  _data.orders = extended;
  return _data;
};

export async function updateActiveOrders(dispatcher: IDispatcher) {
  const fetchActiveOrders = async () => {
    const url = OrdersUrl + "/getActiveOrders";
    dispatcher.state.saveState(StoreKeysEnum.LocalOrders, null);
    await axios.get(url).then((response: AxiosResponse) => {
      const _orders: ActiveOrderModel[] = response.data;
      const _decorated = _orders.map((o) => decorateData(o, dispatcher));
      dispatcher.state.saveState(StoreKeysEnum.LocalOrders, _decorated);
      dispatcher.state.saveState(StoreKeysEnum.ActiveOrdersCount, response.data.length);
      return _decorated;
    });
  };
  return await fetchActiveOrders();
}

export async function updateAwaitingOrders(dispatcher: IDispatcher) {
  const fetchAwaitingOrders = async () => {
    const url = OrdersUrl + "/getAwaitingOrders";
    dispatcher.state.saveState(StoreKeysEnum.AwaitingOrdersCount, null);
    await axios.get(url).then((response: AxiosResponse) => {
      dispatcher.state.saveState(
        StoreKeysEnum.AwaitingOrdersCount,
        response.data.length
      );
    });
  };
  return await fetchAwaitingOrders();
}
