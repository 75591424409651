import React from "react";

function SvgClose(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="close"
      role="img"
      viewBox="0 0 35 35"
      {...props}
    >
      <path
        fill={props.fill}
        d="M4,29a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42l24-24a1,1,0,1,1,1.42,1.42l-24,24A1,1,0,0,1,4,29Z"
      />
      <path
        fill={props.fill}
        d="M28,29a1,1,0,0,1-.71-.29l-24-24A1,1,0,0,1,4.71,3.29l24,24a1,1,0,0,1,0,1.42A1,1,0,0,1,28,29Z"
      />
    </svg>
  );
}

export default SvgClose;
