import { AsyncComponent } from "../common/AsyncComponent";
import { useApiCall } from "../../hooks/useApiCall";
import { GeneralUrl } from "../../config";
import { useMemo } from "react";
import { Table } from "../common/Table";
import {
  formatDateToLocaleString,
  getDurationTextFromDate,
} from "../../helpers/formatter";

export function AdminConnectedClients() {
  const [response, isLoading, error] = useApiCall({
    url: GeneralUrl + "/clients",
  });

  const columns = useMemo(
    () => [
      {
        Header: "ConnectionID",
        accessor: "connectionId",
      },
      {
        Header: "Połączenie zestawiono",
        accessor: "connectionDate",
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          const _first = new Date(rowA.values[id]).getTime();
          const _second = new Date(rowB.values[id]).getTime();
          if (_first > _second) return 1;
          if (_second > _first) return -1;
          return 0;
        },
        Cell: (props: { value: string }) => {
          return (
            <>
              {formatDateToLocaleString(props.value)} (
              {getDurationTextFromDate(props.value)})
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <AsyncComponent data={response} isLoading={isLoading} error={error}>
      {(data: any) => {
        return (
          <Table
            className={"admin-table"}
            data={data}
            columns={columns}
          ></Table>
        );
      }}
    </AsyncComponent>
  );
}
