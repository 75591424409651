import styled from "styled-components";

const FilterPillStyle = styled.div`
  .filter-pill {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    min-height: 1.4rem;
    ::before,
    ::after {
      content: "×";
      color: var(--col-fg-active);
    }
    ::before {
      content: "×";
      color: unset;
    }
  }
`;

export interface IFilterPillButton {
  onClick?: (data: unknown) => unknown;
  label?: string;
  className?: string;
  children?: React.ReactElement;
  [x: string]: any;
}

export function FilterPillButton(props: IFilterPillButton) {
  const { onClick, label, className, children, ...rest } = props;
  return (
    <FilterPillStyle>
      <button
        onClick={onClick}
        className={"btn--active filter-pill " + className ?? ""}
        {...rest}
      >
        {props.children || props.label}
      </button>
    </FilterPillStyle>
  );
}
