import { useEffect, useRef } from "react";
import styled from "styled-components";
import {
  Chart,
  LineController,
  BarController,
  BarElement,
  PieController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  ArcElement,
  CategoryScale,
  Tooltip,
  ChartTypeRegistry,
} from "chart.js";
Chart.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  BarController,
  BarElement,
  PieController,
  ArcElement,
  Title,
  CategoryScale,
  Tooltip
);

const LineChartStyle = styled.div`
  height: 100%;
  width: 100%;
  .chart-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    & canvas {
      max-height: 100% !important;
      max-width: 100% !important;
    }
  }
`;
export interface IChart {
  chartData: { key: any; value: number }[];
  chartType: keyof ChartTypeRegistry;
  xFormatter?: (value: any) => any;
  yFormatter?: (value: any) => any;
}

export function ChartComponent(props: IChart) {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const wrapper = useRef<HTMLDivElement>(null);
  let chartObj = useRef<any>(null);

  useEffect(() => {
    if (chartRef.current) {
      createChart(chartRef.current, props.chartData);
    }
    return () => {
      chartObj.current?.destroy();
    };
  }, [chartRef]);

  function createChart(
    el: HTMLCanvasElement,
    dataset: { key: number; value: number }[]
  ) {
    let options = {
      maintainAspectRatio: false,
      responsive: true,
      animation: {
        duration: 0,
      },
      elements: {
        line: {
          tension: 0,
        },
        point: {
          radius: 0,
        },
      },
      scales: props.chartType !== "pie" && {
        x: {
          gridLines: {
            color: getComputedStyle(document.body).getPropertyValue(
              "--col-fg-secondary"
            ),
            zeroLineColor: getComputedStyle(document.body).getPropertyValue(
              "--col-fg-secondary"
            ),
          },
          ticks: {
            font: {
              size: 12,
            },
            color: getComputedStyle(document.body).getPropertyValue(
              "--col-fg-secondary"
            ),
            callback: (value: number) => {
              const sample = props.chartData[value];
              return props.xFormatter ? props.xFormatter(sample) : sample.key;
            },
          },
        },
        y: {
          ticks: {
            font: {
              size: 12,
            },
            color: getComputedStyle(document.body).getPropertyValue(
              "--col-fg-secondary"
            ),
            callback: (value: number) => {
              return props.yFormatter ? props.yFormatter(value) : value;
            },
          },
          gridLines: {
            color: getComputedStyle(document.body).getPropertyValue(
              "--col-fg-secondary"
            ),
            zeroLineColor: getComputedStyle(document.body).getPropertyValue(
              "--col-fg-secondary"
            ),
          },
        },
      },
      plugins: {
        tooltip: {
          enabled: true,
          interaction: {
            mode: "nearest",
            intersect: true,
          },
          backgroundColor: getComputedStyle(document.body).getPropertyValue(
            "--col-fg-secondary"
          ),
          callbacks: {
            title: () => undefined,
            label: (context: any) => {
              return props.yFormatter
                ? props.yFormatter(context.raw)
                : context.raw;
            },
          },
        },
      },
    } as any;
    let data = {
      labels: dataset?.map((x) => x.value),
      datasets: [
        {
          data: dataset?.map((x) => x.value),
          backgroundColor: dataset?.map((x, index) =>
            getComputedStyle(document.body).getPropertyValue("--col-fg-active")
          ),
          borderColor: getComputedStyle(document.body).getPropertyValue(
            "--col-fg-active"
          ),
          borderWidth: 1,
        },
      ],
    };
    chartObj.current?.destroy();
    chartObj.current = new Chart(el, {
      type: props.chartType,
      data: data,
      options: options,
    });
  }

  return (
    <LineChartStyle>
      <div className="chart-wrapper" ref={wrapper}>
        <canvas ref={chartRef} id="chart-container"></canvas>
      </div>
    </LineChartStyle>
  );
}
