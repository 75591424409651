import useDispatcherState from "../../hooks/useDispatcherState";
import { StoreKeysEnum } from "../../store/storeKeysEnum";
import { User } from "../../models/api/user";
import { ConfirmButton } from "../common/ConfirmButton";
import { Input } from "../common/Input";
import { AsyncComponent } from "../common/AsyncComponent";
import { useApiCall } from "../../hooks/useApiCall";
import { useEffect, useState, useRef } from "react";
import { error as showError, info } from "../common/Toast";

import { AuthenticationUrl, RegexPasswordPattern } from "../../config";

export interface ICurrentUserChangePassword {
  onPasswordChanged?: () => void;
}

export function CurrentUserChangePassword(props: ICurrentUserChangePassword) {
  const [, , , , post] = useApiCall({});

  const [currentPassword, setCurrentPassword] = useState<string | null>(null);
  const [newPassword, setNewPassword] = useState<string | null>(null);
  const [confirmedPassword, setConfirmedPassword] = useState<string | null>(
    null
  );
  const [valid, setValid] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const changePassword = (currentPassword: string, newPassword: string) => {
    post(AuthenticationUrl + "/changePassword", {
      currentPassword: currentPassword,
      newPassword: newPassword,
    }).then(
      () => {
        info("Hasło zostało zmienione");
        props.onPasswordChanged && props.onPasswordChanged();
      },
      () => {
        showError("Nie udało się zmienić hasła");
      }
    );
  };

  function checkIsValid(current: string, modified: string) {
    if (current !== modified) {
      const form = formRef.current;
      if (form) {
        form.querySelectorAll("input:invalid").length === 0
          ? setValid(true)
          : setValid(false);
      }
    } else {
      setValid(false);
    }
  }

  useEffect(() => {
    if (currentPassword && newPassword && confirmedPassword === newPassword) {
      checkIsValid(currentPassword, newPassword);
    } else {
      setValid(false);
    }
  }, [currentPassword, newPassword, confirmedPassword]);

  return (
    <AsyncComponent data={true} isLoading={false} error={false}>
      {(data: any) => {
        return (
          <section className="hero-edits">
            <form ref={formRef}>
              <section className="hero__inputs">
                <div className="form-field flex-center-justify current-password">
                  <label htmlFor="user_current-password">Aktualne hasło:</label>
                  <Input
                    id="user_current-password"
                    type="password"
                    autoComplete="current-password"
                    onChange={(e) => {
                      e === ""
                        ? setCurrentPassword(null)
                        : setCurrentPassword(String(e));
                    }}
                  ></Input>
                </div>
                <div className="form-field flex-center-justify">
                  <label htmlFor="user_new-password">Nowe hasło:</label>
                  <Input
                    id="user_new-password"
                    type="password"
                    pattern={RegexPasswordPattern}
                    title="Hasło musi mieć minimum 8 znaków i zawierać przynajmniej jeden znak specjalny."
                    autoComplete="new-password"
                    onChange={(e) => setNewPassword(String(e))}
                  ></Input>
                </div>
                <div className="form-field flex-center-justify">
                  <label htmlFor="user_confirm-new-password">
                    Powtórz nowe hasło:
                  </label>
                  <Input
                    id="user_confirm-new-password"
                    type="password"
                    pattern={RegexPasswordPattern}
                    title="Hasło musi mieć minimum 8 znaków i zawierać przynajmniej jeden znak specjalny."
                    autoComplete="new-password"
                    onChange={(e) => {
                      setConfirmedPassword(String(e));
                    }}
                  ></Input>
                </div>
              </section>
            </form>
            <div className="flex-center-justify">
              <div></div>
              <ConfirmButton
                label="Zatwierdź zmiany"
                confirmLabel="Zatwierdzić zmiany?"
                fitToContent={true}
                sticky={true}
                disabled={!valid}
                action={() => {
                  changePassword(currentPassword ?? "", newPassword ?? "");
                }}
              ></ConfirmButton>
            </div>
          </section>
        );
      }}
    </AsyncComponent>
  );
}
