import { NavItem } from "reactstrap";
import { NavLink as Link } from "react-router-dom";

export interface INavLink {
  to: string;
  children: React.ReactNode;
  [x: string]: any;
}

export function NavLink(props: INavLink) {
  const { to, children, ...rest } = props;
  return (
    <>
      <NavItem tag={"div"}>
        <Link
          className={({ isActive }) =>
            `navbar-light navbar-nav nav-link ${
              isActive && "navbar-light--active"
            }`
          }
          to={to}
          {...rest}
        >
          {children}
        </Link>
      </NavItem>
    </>
  );
}
