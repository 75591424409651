import styled from "styled-components";
import { useEffect, useState } from "react";
import { SimpleDatePicker } from "../common/SimpleDatePicker";
import CheckboxFilter from "../common/CheckboxFilter";
import useDispatcherState from "../../hooks/useDispatcherState";
import { StoreKeysEnum } from "../../store/storeKeysEnum";
import { OptionModel } from "../../models/api/optionModel";
import { AwaitingOrderModel } from "../../models/api/awaitingOrderModel";
import { AttachmentsWidget } from "./AttachmentsWidget";
import { ActiveOrderModel } from "../../models/api/activeOrderModel";

export interface OrderOptionsProps {
  order: AwaitingOrderModel | ActiveOrderModel;
  showDatePickers: boolean;
  showAttachmentPicker: boolean;
  onOrderModified: (order: AwaitingOrderModel | ActiveOrderModel) => void;
  className?: string;
}

const OrderOptionsUnstyled = (props: OrderOptionsProps) => {
  const [modifiedOrder, setModifiedOrder] = useState(props.order);
  const [availableOptions] = useDispatcherState<OptionModel[]>(
    StoreKeysEnum.AvailableOrderOptions
  );

  useEffect(() => {
    props.onOrderModified && props.onOrderModified(modifiedOrder);
  }, [modifiedOrder]);

  return (
    <section className={`${props.className} order-options`}>
      <section className="order-edits">
        <section className="order-inputs">
          {props.showDatePickers && (
            <section className="order-dates">
              <div className="form-field flex-center-justify">
                <label>Rozpocznj o:</label>
                <section className="date-wrapper">
                  <SimpleDatePicker
                    type={"datetime-local"}
                    maxValue={
                      modifiedOrder.expectedCompletionDate
                        ? new Date(modifiedOrder.expectedCompletionDate)
                        : undefined
                    }
                    selectedDate={
                      modifiedOrder.expectedStartingDate
                        ? new Date(modifiedOrder.expectedStartingDate)
                        : undefined
                    }
                    onSelectedDateChanged={(date) =>
                      setModifiedOrder((old) => {
                        return {
                          ...old,
                          expectedStartingDate: date.value ?? null,
                        };
                      })
                    }
                  ></SimpleDatePicker>
                </section>
              </div>
              <div className="form-field flex-center-justify margin-y">
                <label>Zakończ do:</label>
                <section className="date-wrapper">
                  <SimpleDatePicker
                    type={"datetime-local"}
                    minValue={
                      modifiedOrder.expectedStartingDate
                        ? new Date(modifiedOrder.expectedStartingDate)
                        : undefined
                    }
                    selectedDate={
                      modifiedOrder.expectedCompletionDate
                        ? new Date(modifiedOrder.expectedCompletionDate)
                        : undefined
                    }
                    onSelectedDateChanged={(date) =>
                      setModifiedOrder((old) => {
                        return {
                          ...old,
                          expectedCompletionDate: date.value ?? null,
                        };
                      })
                    }
                  ></SimpleDatePicker>
                </section>
              </div>
            </section>
          )}
          <div className="form-field flex-center-justify">
            <label htmlFor="order-remarks">Uwagi:</label>
            <textarea
              id="order-remarks"
              value={modifiedOrder.description}
              name="Uwagi do zamówienia"
              rows={4}
              onChange={(e) =>
                setModifiedOrder((old) => {
                  return {
                    ...old,
                    description:
                      e.target.value !== "" ? e.target.value : undefined,
                  };
                })
              }
            ></textarea>
          </div>
          {props.showAttachmentPicker && (
            <>
              <div className="attachments flex-center-justify">
                <label>Załączniki:</label>
              </div>
              <AttachmentsWidget
                attachments={modifiedOrder.attachments}
                onAttachmentsChanged={(attachments) =>
                  setModifiedOrder((old) => {
                    old.attachments = attachments;
                    return { ...old };
                  })
                }
              />
            </>
          )}
          <CheckboxFilter
            onSelectionChanged={(e) => {
              setModifiedOrder((old) => {
                return {
                  ...old,
                  options: e.map((v) => {
                    return { id: v.key, value: v.value };
                  }),
                };
              });
            }}
            multiSelectMode={true}
            items={
              availableOptions?.map((e) => {
                return {
                  key: e.id,
                  value: e.value,
                  selected: modifiedOrder.options?.find((o) => o.id === e.id)
                    ? true
                    : false,
                };
              }) ?? []
            }
          ></CheckboxFilter>
        </section>
      </section>
    </section>
  );
};

export const OrderOptions = styled(OrderOptionsUnstyled)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
  .attachments {
    padding: 0.2rem;
  }
  ${AttachmentsWidget} {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    padding-bottom: 1.5rem;
  }
  .order-inputs {
    .input {
      margin-top: 0.5rem;
      .label {
        margin-left: 0.5rem;
      }
    }
    .order-dates {
      margin-bottom: 2rem;
    }
    .order-selects {
      margin-bottom: 2rem;
      text-align: left;
      &__header {
        font-size: inherit;
        margin-bottom: 1rem;
      }
    }
    .date-wrapper,
    #order-remarks {
      width: 15rem;
    }
  }
`;
