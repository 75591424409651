import { useContext } from "react";
import { Container, NavbarBrand } from "reactstrap";
import { NavLink } from "./NavLink";
import { Link } from "react-router-dom";
import { Icon } from "./common/Icon";
import useDispatcherState from "../hooks/useDispatcherState";
import { User } from "../models/api/user";
import { RestrictedRoutes } from "../routes/restrictedRoutes";
import { IOrder } from "../models/app/IOrder";
import { StoreKeysEnum } from "../store/storeKeysEnum";
import styled from "styled-components";
import { AppSettingsContext } from "..";
import { AppSettings } from "../models/app/app-settings";
import { Navigation } from "./common/Navigation";

const HeaderStyle = styled.header`
  .header {
    display: grid;
    grid-template-columns: max-content minmax(0, min-content);
    gap: 1rem;
    height: 2.8rem;
    justify-content: space-between;
    align-items: center;
  }
  .banner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .options {
    display: flex;
    &__svg {
      height: 2em;
      width: 2em;
      fill: var(--col-fg-primary);
      margin: 0 var(--spacing-h) 0 var(--spacing-h);
    }
  }
  .nav-actions {
    align-self: center;
  }
`;

export function NavMenu(props: { className: string }) {
  const [user] = useDispatcherState<User>(StoreKeysEnum.User);
  const [order] = useDispatcherState<IOrder | null>(StoreKeysEnum.Order);
  const [settings] = useDispatcherState<AppSettings | null>(
    StoreKeysEnum.AppSettings
  );
  const appSettings = useContext(AppSettingsContext);
  return (
    <HeaderStyle>
      <Container>
        <div className="header border-bottom">
          <div className="nav-actions">
            {order ? (
              <div className="order-widget">
                <Link to={"/order/create"} style={{ textDecoration: "none" }}>
                  <button className="btn btn--alert">
                    Przejdź do zamówienia
                  </button>
                </Link>
              </div>
            ) : (
              <span className="options">
                {user?.authenticated && (
                  <>
                    {settings?.notificationsEnabled ? (
                      <Icon
                        onClick={() =>
                          appSettings.setSettings((old) => {
                            return { ...old, notificationsEnabled: false };
                          })
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M256 32V51.2C329 66.03 384 130.6 384 208V226.8C384 273.9 401.3 319.2 432.5 354.4L439.9 362.7C448.3 372.2 450.4 385.6 445.2 397.1C440 408.6 428.6 416 416 416H32C19.4 416 7.971 408.6 2.809 397.1C-2.353 385.6-.2883 372.2 8.084 362.7L15.5 354.4C46.74 319.2 64 273.9 64 226.8V208C64 130.6 118.1 66.03 192 51.2V32C192 14.33 206.3 0 224 0C241.7 0 256 14.33 256 32H256zM224 512C207 512 190.7 505.3 178.7 493.3C166.7 481.3 160 464.1 160 448H288C288 464.1 281.3 481.3 269.3 493.3C257.3 505.3 240.1 512 224 512z" />
                        </svg>
                      </Icon>
                    ) : (
                      <Icon
                        onClick={() =>
                          appSettings.setSettings((old) => {
                            return { ...old, notificationsEnabled: true };
                          })
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                        >
                          <path d="M186 120.5C209 85.38 245.4 59.84 288 51.2V32C288 14.33 302.3 .0003 320 .0003C337.7 .0003 352 14.33 352 32V51.2C425 66.03 480 130.6 480 208V226.8C480 273.9 497.3 319.2 528.5 354.4L535.9 362.7C544.3 372.2 546.4 385.6 541.2 397.1C540.1 397.5 540.8 397.1 540.6 398.4L630.8 469.1C641.2 477.3 643.1 492.4 634.9 502.8C626.7 513.2 611.6 515.1 601.2 506.9L9.196 42.89C-1.236 34.71-3.065 19.63 5.112 9.196C13.29-1.236 28.37-3.065 38.81 5.112L186 120.5zM160 226.8V222.1L406.2 416H128C115.4 416 103.1 408.6 98.81 397.1C93.65 385.6 95.71 372.2 104.1 362.7L111.5 354.4C142.7 319.2 160 273.9 160 226.8V226.8zM320 512C303 512 286.7 505.3 274.7 493.3C262.7 481.3 256 464.1 256 448H384C384 464.1 377.3 481.3 365.3 493.3C353.3 505.3 336.1 512 320 512z" />
                        </svg>
                      </Icon>
                    )}
                    <NavbarBrand tag={Link} to="/profile">
                      <div className="banner">{user.firstName}</div>
                    </NavbarBrand>
                  </>
                )}
              </span>
            )}
          </div>
          <div className="navigation">
            <Navigation>
              {user ? (
                RestrictedRoutes.map((route) => {
                  return (
                    user.role &&
                    route.roles.includes(user.role) &&
                    route.value && (
                      <NavLink key={route.to} to={route.to}>
                        {route.template ? (
                          <route.template>{route.value}</route.template>
                        ) : (
                          route.value
                        )}
                      </NavLink>
                    )
                  );
                })
              ) : (
                <NavLink to={"/login"}>Zaloguj się</NavLink>
              )}
            </Navigation>
          </div>
        </div>
      </Container>
    </HeaderStyle>
  );
}
