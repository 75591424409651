import { ProductModel } from "../models/api/product";

export function productsOrdered(products?: ProductModel[] | null) {
  const regularProducts =
    products
      ?.filter((e) => e.displaySequence)
      .sort((a, b) => (a.displaySequence ?? 0) - (b.displaySequence ?? 0)) ??
    [];
  const restProducts = products?.filter((e) => !e.displaySequence) ?? [];
  return products ? [...regularProducts, ...restProducts] : null;
}
