import { Role } from "../models/api/user";
import { InventoryRoute } from "./inventory/Inventory";
import { InventoryProductCreateRoute } from "./inventory/product/create/InventoryProductCreate";
import { InventoryProductModifyRoute } from "./inventory/product/modify/InventoryProductModify";
import { InventoryGroupCreateRoute } from "./inventory/group/create/InventoryGroupCreate";
import { InventoryGroupModifyRoute } from "./inventory/group/modify/InventoryGroupModify";
import { ManageRoute } from "./manage/Manage";
import { LogoutRoute } from "./logout/Logout";
import { OrderRoute } from "./order/Order";
import { OrderCreateRoute } from "./order/create/OrderCreate";
import { AdminRoute } from "./admin/Admin";
import { ActiveRoute } from "./active/Active";
import { ActiveRouteTemplate } from "./active/RouteTemplate";
import { PromotionsRoute } from "./promotions/Promotions";
import { StockRoute } from "./stock/Stock";
import { ProfileRoute } from "./profile/Profile";
import { AwaitingRoute } from "./awaiting/Awaiting";
import { AwaitingRouteTemplate } from "./awaiting/RouteTemplate";
import { Outlet } from "react-router";
import { lazy, Suspense } from "react";

export const RestrictedRoutes = [
  {
    to: "/order",
    value: "Zamówienie",
    component: OrderRoute,
    roles: [Role.Admin, Role.Manager, Role.Employee, Role.User],
  },
  {
    to: "/order/create",
    component: OrderCreateRoute,
    roles: [Role.Admin, Role.Manager, Role.Employee, Role.User],
  },
  {
    to: "/active",
    value: "Aktywne",
    component: ActiveRoute,
    template: ActiveRouteTemplate,
    roles: [Role.Admin, Role.Manager, Role.Employee, Role.User],
  },
  {
    to: "/awaiting",
    value: "Oczekujące",
    component: AwaitingRoute,
    template: AwaitingRouteTemplate,
    roles: [Role.Admin, Role.Manager, Role.Employee, Role.User],
  },
  {
    to: "/promotions",
    value: "Promocje",
    component: PromotionsRoute,
    roles: [Role.Admin, Role.Manager, Role.Employee, Role.User],
  },
  {
    to: "/profile",
    component: ProfileRoute,
    roles: [Role.Admin, Role.Manager, Role.Employee, Role.User],
  },
  {
    to: "/stock",
    value: "Magazyn",
    component: StockRoute,
    roles: [Role.Admin, Role.Manager],
  },
  {
    to: "/inventory/product/modify",
    component: InventoryProductModifyRoute,
    roles: [Role.Admin, Role.Manager],
  },
  {
    to: "/inventory/product/create",
    component: InventoryProductCreateRoute,
    roles: [Role.Admin, Role.Manager],
  },
  {
    to: "/inventory/group/modify",
    component: InventoryGroupModifyRoute,
    roles: [Role.Admin, Role.Manager],
  },
  {
    to: "/inventory/group/create",
    component: InventoryGroupCreateRoute,
    roles: [Role.Admin, Role.Manager],
  },
  {
    to: "/inventory",
    value: "Asortyment",
    childRoutes: [
      {
        to: "group",
        roles: [Role.Admin, Role.Manager],
      },
      {
        to: "product",
        roles: [Role.Admin, Role.Manager],
      },
    ],
    component: InventoryRoute,
    roles: [Role.Admin, Role.Manager],
  },
  {
    to: "/manage",
    value: "Zarządzaj",
    component: ManageRoute,
    roles: [Role.Admin, Role.Manager],
  },
  {
    to: "/admin",
    value: "Administrator",
    component: AdminRoute,
    roles: [Role.Admin],
  },
  {
    to: "/logout",
    value: "Wyloguj",
    action: "logout",
    component: LogoutRoute,
    roles: [Role.Admin, Role.Manager, Role.Employee, Role.User],
  },
];
