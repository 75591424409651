export const getCssVariableValue = (propertyName: string, el = "#root") => {
  const element = document.querySelector(el);
  const value = getComputedStyle(element!)
    .getPropertyValue(propertyName)
    .replace(/'|"/g, "")
    .trim();
  return value;
};

export default getCssVariableValue;
