import styled from "styled-components";
import { ActionButton } from "./ActionButton";
import { FilterPillButton } from "./FilterPill";

const FiltersStyle = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .item {
    padding-bottom: 0.5rem;
    width: 100%;
    border-bottom: var(--border-width) solid var(--col-fg-secondary);
  }
  .filters {
    padding: 0.5rem;
  }
  .filter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: var(--border-width) solid var(--col-fg-secondary);
    &__inline {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
  .selections {
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;
    font-size: 0.8rem;
    justify-items: left;
    margin-bottom: unset;
    min-height: 1.3rem;
    margin-left: 1rem;
  }
  .actions {
    padding-top: 0.5rem;
    position: sticky;
    bottom: 0;
    width: 100%;
    background-image: linear-gradient(
      to bottom,
      transparent,
      var(--backdrop-translucent),
      var(--col-bg-secondary),
      var(--col-bg-secondary),
      var(--col-bg-secondary),
      var(--col-bg-secondary)
    );
    &__button {
      width: 100%;
    }
  }
`;

export interface IQuery {
  key: string;
  value: any;
  label: string;
}
export interface IFilters {
  onApply: (query: IQuery[]) => void;
  query?: IQuery[];
  setQuery?: any;
  children: React.ReactNode;
}

export function Filters(props: IFilters) {
  const _query = props.query ?? [];
  return (
    <FiltersStyle>
      <div className="filters">{props.children}</div>
      {_query && _query.length > 0 && (
        <section className="actions">
          <div className="selections">
            {_query?.map((q: IQuery, i: number) => (
              <FilterPillButton
                key={i}
                onClick={() => {
                  props.setQuery((old: IQuery[]) => {
                    const _filtered = old?.filter((e) => e.key !== q.key);
                    return [...(_filtered ?? [])];
                  });
                }}
              >
                <>{q.label}</>
              </FilterPillButton>
            ))}
          </div>
          <div className="actions__button flex-center-justify">
            <div></div>
            <ActionButton
              label="Szukaj"
              onClick={() => props.onApply(_query ?? [])}
            ></ActionButton>
          </div>
        </section>
      )}
    </FiltersStyle>
  );
}
