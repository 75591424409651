import { ActionEnum } from "../models/enums/actionEnum";
import { Row } from "react-table";

export default function useEditableTable() {
  function updateEntry(row: any) {
    const original = row.original;
    const updated = { ...original };
    const state = row.state;
    const internalStates = ["cellState", "isEditEnabled"];
    for (const key in state) {
      if (!internalStates.includes(key)) updated[key] = state[key];
    }
    const action: ActionEnum = updated.id
      ? ActionEnum.Update
      : ActionEnum.Create;
    row.setState((state: any) => {
      const _state = {
        ...state,
        isEditEnabled: false,
      };
      return _state;
    });
    return { item: updated, action: action };
  }

  async function asyncUpdateEntry(row: any) {
    return new Promise(function (resolve, reject) {
      const updated = updateEntry(row);
      row.setState((state: any) => {
        const _state = {
          ...state,
          isEditEnabled: false,
        };
        //ugly hack to be sure that state was set...
        setTimeout(() => resolve(updated), 100);
        return _state;
      });
    });
  }

  function abortUnfocusedEdits(row: Row, prepared: any[]) {
    prepared.forEach((_row) => {
      if (row.index !== _row.index)
        _row.setState((state: any) => ({
          ...state,
          isEditEnabled: false,
        }));
    });
  }

  function abortAllEdits(prepared: any[]) {
    prepared.forEach((_row) => {
      _row.setState((state: any) => ({
        ...state,
        isEditEnabled: false,
      }));
    });
  }

  return { updateEntry, asyncUpdateEntry, abortUnfocusedEdits, abortAllEdits };
}
