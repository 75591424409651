import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Breakpoints } from "../../styles/breakpoints";
import * as mixin from "../../styles/mixins";
import useDispatcher from "../../hooks/useDispatcher";

const ModalStyle = styled.div`
  ${mixin.vBackdrop}

  @media screen and (max-width: ${Breakpoints.Phone - 1}px) {
    .modal-custom-content {
      bottom: 0%;
      width: 100%;
    }
  }

  @media screen and (min-width: ${Breakpoints.Phone}px) {
    .modal-custom-content {
      max-width: 60%;
      width: fit-content;
      min-width: 30%;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      max-height: 100vh;
      height: fit-content;
    }
  }

  .modal-custom-footer {
    padding: 0.2rem;
    display: flex;
    justify-content: center;
  }

  .modal-custom-body {
    padding: var(--spacing-v) var(--spacing-h) var(--spacing-v) var(--spacing-h);
    margin-left: var(--spacing-h);
    margin-right: var(--spacing-h);
    max-height: 80vh;
    overflow: auto;
  }

  .modal-custom-title {
    padding: 0.5rem 1rem 0rem 1rem;
  }

  .modal-custom-header {
    color: var(--col-fg-active);
    display: flex;
    justify-content: space-between;
    height: 3rem;
    border-bottom: var(--border-width) solid var(--col-fg-secondary);
  }

  .modal-custom-content {
    box-sizing: border-box;
    background-color: var(--col-bg-focus);
    color: var(--col-fg-primary);
    position: fixed;
    z-index: 100;
    left: 0;
    right: 0;
    text-align: center;
    max-height: calc(100vh - var(--spacing-v));
    overflow: auto;
    & .btn {
      border: unset;
      background: unset;
      justify-self: center;
      & .close-icon {
        font-size: 1.5rem;
        margin: 0rem 1rem 0rem 1rem;
      }
    }
  }
`;
export interface IModalContent {
  action?: () => void;
  actionName?: string;
  header: string;
  content: string | Object;
}

export interface IModal {
  modalContent: IModalContent | null;
  children: React.ReactNode;
}

export function Modal() {
  const { Dispatcher, Event } = useDispatcher();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<IModalContent>();

  const showModal = Dispatcher.on(Event.ShowModal, (content: IModalContent) => {
    setModalVisible(true);
    setModalContent(content);
  });

  const hideModal = () => {
    setModalVisible(false);
  };

  const invokeModalAction = () => {
    if (modalContent?.action) {
      modalContent.action();
    }
    hideModal();
  };
  useEffect(() => {
    return () => {
      Dispatcher.off(showModal);
    };
  }, [showModal, Dispatcher]);

  return (
    <ModalStyle>
      {modalVisible ? (
        <div className="modal-custom-container">
          <div className="modal-custom-backdrop backdrop" />
          <div className="modal-custom-content">
            <div className="modal-custom-header">
              <h3 className="modal-custom-title">{modalContent?.header}</h3>
              <button
                type="button"
                className="btn btn--untoggled"
                onClick={hideModal}
                aria-label="Close"
              >
                <span className="close-icon" aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-custom-body">
              {React.isValidElement(modalContent?.content) ? (
                modalContent?.content
              ) : (
                <p
                  dangerouslySetInnerHTML={{
                    __html: String(modalContent?.content) || "",
                  }}
                ></p>
              )}
            </div>
            {modalContent?.actionName ? (
              <div className="modal-custom-footer">
                <button className="btn btn--active" onClick={invokeModalAction}>
                  {modalContent?.actionName}
                </button>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </ModalStyle>
  );
}
