import { Loader } from "../components/common/Loader";
import { useState } from "react";
import { Form, FieldType, FormModel } from "../components/common/Form";
import { Breakpoints } from "../styles/breakpoints";
import styled from "styled-components";
import { RegisterUrl } from "../config";
import { useApiCall } from "../hooks/useApiCall";
import { RegexPasswordPattern } from "../config";
import { Link } from "react-router-dom";

const RegisterContainer = styled.div`
  .register-page {
    width: 25rem;
    margin: auto;
    @media screen and (max-width: ${Breakpoints.Phone}px) {
      width: 100%;
    }
    &__form {
    }
    &__register-result {
      padding-top: 1rem;
      color: var(--col-fg-alert);
    }
    &__register {
      padding-top: 1rem;
    }
    &__login {
      padding-top: 1rem;
      a {
        color: var(--col-fg-secondary);
      }
    }
  }
`;
export function RegisterView() {
  const [pending, setPending] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [, , , , post] = useApiCall({});

  const FormData = [
    {
      label: "firstname",
      displayLabel: "Imię",
      type: FieldType.Text,
      required: true,
    },
    {
      label: "lastname",
      displayLabel: "Nazwisko",
      type: FieldType.Text,
      required: true,
    },
    {
      label: "username",
      displayLabel: "Adres e-mail",
      type: FieldType.Email,
      required: true,
      pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
      title: "Wpisz prawidłowy adres-email.",
    },
    {
      label: "password",
      displayLabel: "Hasło",
      type: FieldType.Password,
      required: true,
      pattern: RegexPasswordPattern,
      title:
        "Hasło musi mieć minimum 8 znaków i zawierać przynajmniej jeden znak specjalny.",
    },
    {
      label: "password-repeated",
      displayLabel: "Powtórz hasło",
      type: FieldType.Password,
      required: true,
      pattern: RegexPasswordPattern,
      title:
        "Hasło musi mieć minimum 8 znaków i zawierać przynajmniej jeden znak specjalny.",
    },
  ];

  function tryToRegister(form: FormModel) {
    setAlertMessage(null);
    if (form.model[3].value !== form.model[4].value) {
      setAlertMessage("Wpisane hasła się nie pokrywają");
      setPending(false);
    } else {
      setPending(true);
      post(RegisterUrl, {
        firstName: form.model[0].value,
        lastName: form.model[1].value,
        username: form.model[2].value,
        password: form.model[3].value,
      }).then(
        () => {
          setAlertMessage(
            "Konto zostało założone. Pamiętaj o konieczności aktywacji konta przez managera."
          );
          setPending(false);
        },
        () => {
          setAlertMessage("Coś poszło nie tak");
          setPending(false);
        }
      );
    }
  }
  return (
    <RegisterContainer>
      <div className="register-page">
        <Loader loaderVisible={pending}></Loader>
        <div className="register-page__form">
          <Form
            title={"Zarejestruj się"}
            labels={FormData}
            actionTitle={"Zatwierdź"}
            onSubmit={(model: any) => {
              tryToRegister(model);
            }}
          ></Form>
        </div>
        {alertMessage ? (
          <div className="register-page__register-result">{alertMessage}</div>
        ) : null}
        <div className="register-page__login">
          <Link to="/login">Zaloguj się</Link>
        </div>
      </div>
    </RegisterContainer>
  );
}
