import { useContext } from "react";
import { DispatcherContext } from "../index"
import { IDispatcher } from "../models/dispatcher/dispatcher-interfaces";
import { Event } from "../models/dispatcher/dispatcher-events";
import { Request } from "../models/dispatcher/dispatcher-requests";

export default function useDispatcher() {
    const Dispatcher: IDispatcher = useContext(DispatcherContext);
    return { Dispatcher, Event, Request };
}
