import styled from "styled-components";
import ExpandableContainer from "../common/ExpandableContainer";
import CheckboxFilter from "../common/CheckboxFilter";
import { useEffect, useState } from "react";
import useProduct from "../../hooks/useProduct";
import useVariant from "../../hooks/useVariant";
import { ProductModel } from "../../models/api/product";
import { User } from "../../models/api/user";
import { VariantModel } from "../../models/api/variant";
import isEqual from "lodash.isequal";
import { Filters, IQuery } from "../common/Filters";
import { IRange, RangeSelector } from "../common/RangeSelector";
import { daysOfWeek as days } from "../common/WeekDaySelector";
import { IngredientModel } from "../../models/api/ingredient";
import useIngredient from "../../hooks/useIngredient";

const ReportsFiltersStyle = styled.section`
  height: 100%;
`;

export interface IReportsFilters {
  products: ProductModel[];
  ingredients: IngredientModel[];
  users: User[];
  onApply: (query: IQuery[]) => void;
  query?: IQuery[];
}

export function ReportsFilters(props: IReportsFilters) {
  const [productChanged, setProductChanged] = useState<boolean>();
  const { getProduct } = useProduct();
  const { getVariant } = useVariant();
  const { getIngredient } = useIngredient();
  const [users, setUsers] = useState<number[]>(
    props.query?.filter((e) => e.key === "userId").map((u) => u.value)[0] ?? []
  );
  const [makers, setMakers] = useState<number[]>(
    props.query?.filter((e) => e.key === "makerId").map((u) => u.value)[0] ?? []
  );

  const [products, setProducts] = useState<number[]>(
    props.query?.filter((e) => e.key === "productId")?.map((v) => v.value)[0] ??
      []
  );
  const [ingredients, setIngredients] = useState<number[]>(
    props.query
      ?.filter((e) => e.key === "ingredientId")
      ?.map((v) => v.value)[0] ?? []
  );
  const [variants, setVariants] = useState<number[]>(
    props.query?.filter((e) => e.key === "variantId").map((u) => u.value)[0] ??
      []
  );
  const [daysOfWeek, setDaysOfWeek] = useState<number[]>(
    props.query?.filter((e) => e.key === "dayOfWeek").map((u) => u.value)[0] ??
      []
  );
  const [currentVariants, setCurrentVariants] = useState<any[]>([]);
  const [selectedRange, setSelectedRange] = useState<IRange>(
    getRangeFromQuery(props.query)
  );

  function getRangeFromQuery(query?: IQuery[]): IRange {
    const _from = new Date(query?.find((e) => e.key === "fromDate")?.value);
    const _to = new Date(query?.find((e) => e.key === "toDate")?.value);
    return {
      from: !isNaN(_from?.getTime()) ? _from : null,
      to: !isNaN(_to?.getTime()) ? _to : null,
    };
  }

  const [query, setQuery] = useState<IQuery[]>();

  useEffect(() => {
    const _variants = products.map((p) => getProduct(p)?.variants);
    const _merged: VariantModel[] = [];
    _variants.forEach((v) => v && _merged.push(...v));
    setCurrentVariants(_merged);
  }, [products]);

  useEffect(() => {
    const initial =
      props.query?.filter((e) => e.key === "productId").map((u) => u.value) ??
      [];
    if (productChanged && !isEqual(initial, products)) {
      setVariants([]);
    }
  }, [products, productChanged]);

  useEffect(() => {
    setProductChanged(true);
  }, []);

  useEffect(() => {
    const query = [
      {
        key: "productId",
        value: products,
        label: products.map((e) => getProduct(e ?? 0)?.description).join(", "),
      },
      {
        key: "variantId",
        value: variants,
        label: variants.map((e) => getVariant(e ?? 0)?.description).join(", "),
      },
      {
        key: "ingredientId",
        value: ingredients,
        label: ingredients
          .map((e) => getIngredient(e ?? 0)?.description)
          .join(", "),
      },
      {
        key: "dayOfWeek",
        value: daysOfWeek,
        label: daysOfWeek
          .map((u) => days?.find((e) => e.key === u)?.value)
          .join(", "),
      },
      {
        key: "userId",
        value: users,
        label:
          "zamówił: " +
          users
            .map((u) => props.users?.find((e) => e.id === u)?.firstName)
            .join(", "),
      },
      {
        key: "makerId",
        value: makers,
        label:
          "zrealizował: " +
          makers
            .map((u) => props.users?.find((e) => e.id === u)?.firstName)
            .join(", "),
      },
      {
        key: "fromDate",
        value: selectedRange?.from?.toISOString(),
        label: "od: " + selectedRange?.from?.toLocaleDateString(),
      },
      {
        key: "toDate",
        value: selectedRange?.to?.toISOString(),
        label: "do: " + selectedRange?.to?.toLocaleDateString(),
      },
    ];
    const isValid = (e: IQuery) => {
      if (e.value === null || e.value === undefined) return false;
      if (Array.isArray(e.value) && e.value.length === 0) return false;
      return true;
    };
    setQuery(query.filter((e) => isValid(e)) as IQuery[]);
  }, [
    products,
    variants,
    ingredients,
    users,
    makers,
    selectedRange,
    daysOfWeek,
  ]);

  return (
    <ReportsFiltersStyle>
      <Filters query={query} setQuery={setQuery} onApply={props.onApply}>
        <section className="item filter filter__inline">
          <div className="filter-label">Zakres</div>
          <div className="filter-value">
            <RangeSelector
              fromDate={selectedRange.from ?? undefined}
              toDate={selectedRange.to ?? undefined}
              onSelectedRangeChanged={(date) => {
                setSelectedRange({ from: date.from, to: date.to });
              }}
            />
          </div>
        </section>
        <section className="item filter filter__inline">
          <ExpandableContainer
            header={"Dzień tygodnia"}
            expanded={daysOfWeek?.length ? true : false}
          >
            <CheckboxFilter
              onSelectionChanged={(e) => setDaysOfWeek(e.map((e) => e.key))}
              multiSelectMode={true}
              items={days?.map((p) => {
                return {
                  key: p.key,
                  value: p.fullName,
                  selected: query
                    ?.find((e) => e.key === "dayOfWeek")
                    ?.value.includes(p.key),
                };
              })}
            ></CheckboxFilter>
          </ExpandableContainer>
        </section>
        <section className="item filter filter__inline">
          <ExpandableContainer
            header={"Produkt"}
            expanded={products?.length ? true : false}
          >
            <CheckboxFilter
              onSelectionChanged={(e) => setProducts(e.map((e) => e.key))}
              multiSelectMode={true}
              items={props.products?.map((p) => {
                return {
                  key: p.id,
                  value: p.description,
                  selected: query
                    ?.find((e) => e.key === "productId")
                    ?.value.includes(p.id),
                };
              })}
            ></CheckboxFilter>
          </ExpandableContainer>
        </section>
        <section className="item filter filter__inline">
          <ExpandableContainer
            header={"Wariant"}
            expanded={variants?.length ? true : false}
          >
            <CheckboxFilter
              onSelectionChanged={(e) => setVariants(e.map((e) => e.key))}
              multiSelectMode={true}
              items={currentVariants.map((v) => {
                return {
                  key: v.id,
                  value: v.description,
                  selected: query
                    ?.find((e) => e.key === "variantId")
                    ?.value.includes(v.id),
                };
              })}
            ></CheckboxFilter>
          </ExpandableContainer>
        </section>
        <section className="item filter filter__inline">
          <ExpandableContainer
            header={"Składnik"}
            expanded={ingredients?.length ? true : false}
          >
            <CheckboxFilter
              onSelectionChanged={(e) => setIngredients(e.map((e) => e.key))}
              multiSelectMode={true}
              items={props.ingredients?.map((p) => {
                return {
                  key: p.id,
                  value: p.description,
                  selected: query
                    ?.find((e) => e.key === "ingredientId")
                    ?.value.includes(p.id),
                };
              })}
            ></CheckboxFilter>
          </ExpandableContainer>
        </section>
        <section className="item filter filter__inline">
          <ExpandableContainer
            header={"Zamówił"}
            expanded={users?.length ? true : false}
          >
            <CheckboxFilter
              onSelectionChanged={(e) => setUsers(e.map((e) => e.key))}
              multiSelectMode={true}
              items={props.users.map((u) => {
                return {
                  key: u.id,
                  value: u.firstName + " " + u.lastName,
                  selected: query
                    ?.find((e) => e.key === "userId")
                    ?.value.includes(u.id),
                };
              })}
            ></CheckboxFilter>
          </ExpandableContainer>
        </section>
        <section className="item filter filter__inline">
          <ExpandableContainer
            header={"Zrealizował"}
            expanded={makers?.length ? true : false}
          >
            <CheckboxFilter
              onSelectionChanged={(e) => setMakers(e.map((e) => e.key))}
              multiSelectMode={true}
              items={props.users.map((u) => {
                return {
                  key: u.id,
                  value: u.firstName + " " + u.lastName,
                  selected: query
                    ?.find((e) => e.key === "makerId")
                    ?.value.includes(u.id),
                };
              })}
            ></CheckboxFilter>
          </ExpandableContainer>
        </section>
      </Filters>
    </ReportsFiltersStyle>
  );
}
