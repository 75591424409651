import { AxiosResponse } from "axios";
import styled from "styled-components";
import { AsyncComponent } from "../components/common/AsyncComponent";
import { CurrentUser } from "../components/feature/CurrentUser";
import { AuthenticationUrl } from "../config";
import { useApiCall } from "../hooks/useApiCall";
import useDispatcherState from "../hooks/useDispatcherState";
import { User } from "../models/api/user";
import { StoreKeysEnum } from "../store/storeKeysEnum";
const ProfileStyle = styled.div``;

export function ProfileView() {
  const [, setUser] = useDispatcherState<User>(StoreKeysEnum.User);

  const [response, isLoading, error, get, post, refresh] = useApiCall<User>({
    url: AuthenticationUrl + "/GetCurrentUser",
    setResponseOnPost: false,
  });

  const modifyUser = (_user: User) => {
    post(AuthenticationUrl + "/modifySelf", _user).then(() => {
      get(AuthenticationUrl + "/GetCurrentUser").then(
        (response: AxiosResponse<User>) => {
          response.data.role &&
            setUser({ ...response.data, authenticated: true });
          refresh();
        }
      );
    });
  };

  return (
    <ProfileStyle>
      <AsyncComponent data={response} isLoading={isLoading} error={error}>
        {(data: User) => {
          return <CurrentUser user={data} onUserModified={modifyUser} />;
        }}
      </AsyncComponent>
    </ProfileStyle>
  );
}
