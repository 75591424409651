import { ProductModel } from "../models/api/product";
import { IDispatcher } from "../models/dispatcher/dispatcher-interfaces";
import { StoreKeysEnum } from "../store/storeKeysEnum";

export const getVariant = (id: number, dispatcher: IDispatcher) => {
  const products = dispatcher.state.getState(
    StoreKeysEnum.Products
  ) as ProductModel[];

  const findVariant = (product: ProductModel, variantId: number) => {
    return product.variants?.find((el) => el.id === variantId);
  };
  if (!isNaN(id) && products) {
    for (const product of products) {
      const _variant = findVariant(product, id);
      if (_variant) return _variant;
    }
  }
  return null;
};
