import intervalToDuration from "date-fns/intervalToDuration";
import { durationToText } from "./durationToText";

export function formatNumber(
  value: string | number,
  decimals = 2,
  fixed = false
) {
  let _n = Number(value);
  let _m = _n % 1;
  return fixed ? _n.toFixed(decimals) : _m ? _n.toFixed(decimals) : _n;
}

export function formatDateToLocaleString(date?: string): string | undefined {
  if (date) {
    const dt = new Date(String(date));
    return dt.toLocaleDateString() + " " + dt.toLocaleTimeString();
  } else {
    return "-";
  }
}

export function getDurationTextFromDate(date?: string) {
  if (date) {
    const duration = intervalToDuration({
      start: new Date(date),
      end: new Date(Date.now()),
    });
    return durationToText(duration);
  }
  return null;
}
