export function hexToRGB(hex: string, alpha?: number) {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);
  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

export function hslToRGB(value: string) {
  const _val = value
    .replace("hsl", "")
    .replace("(", "")
    .replace(")", "")
    .replaceAll("%", "");
  let [h, s, l] = _val.split(",").map((e) => Number(e));
  s /= 100;
  l /= 100;
  const k = (n: any) => (n + h / 30) % 12;
  const a = s * Math.min(l, 1 - l);
  const f = (n: any) =>
    l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
  return "rgb(" + 255 * f(0) + ", " + 255 * f(8) + ", " + 255 * f(4) + ")";
}
