export function durationToText(duration?: Duration) {
  const prefix = {
    months: ["miesiąc", "miesięcy"],
    days: ["dzień", "dni"],
    hours: ["godzinę", "godzin"],
    minutes: ["minutę", "minut"],
  };

  function durationLabel(duration: number, property: string) {
    const label =
      duration > 1
        ? duration + " " + (prefix as any)[property][1]
        : (prefix as any)[property][0];
    return label + " temu";
  }

  if (duration) {
    if (duration.months) return durationLabel(duration.months, "months");
    if (duration.days) return durationLabel(duration.days, "days");
    if (duration.hours) return durationLabel(duration.hours, "hours");
    return durationLabel(duration.minutes ?? 0, "minutes");
  }
}
