import { IngredientModel } from "../models/api/ingredient";
import { IDispatcher } from "../models/dispatcher/dispatcher-interfaces";
import { StoreKeysEnum } from "../store/storeKeysEnum";

export const getIngredient = (id: number, dispatcher: IDispatcher) => {
  const ingredients = dispatcher.state.getState(
    StoreKeysEnum.Ingredients
  ) as IngredientModel[];

  return !isNaN(id) ? ingredients?.find((p) => p.id === id) ?? null : null;
};
