import styled from "styled-components";
import { ChartComponent } from "../common/ChartComponent";
import { formatNumber } from "../../helpers/formatter";
import { Toggler } from "../common/Toggler";
import { useState } from "react";

const ReportSalesStyle = styled.section`
  white-space: nowrap;
  .sales-report {
    &__header {
      width: 100%;
      .toggler {
        margin-left: 1rem;
        flex-wrap: wrap;
        &__label {
          margin-right: 0.5rem;
        }
      }
    }
    &__summary {
      font-size: 1.5rem;
      text-align: right;
      margin-top: 1rem;
      white-space: break-spaces;
    }
    &__chart {
      border: var(--border-width) solid var(--col-fg-secondary);
      margin-top: 0.5rem;
      height: 20rem;
    }
  }
`;

interface IReportSales {
  cashVolumeFromSales: number;
  groupCashList: { cashAmount: number; group: number; ordersCount: number }[];
  xFormatter?: (value: any) => any;
}

export function ReportSalesBarChart(props: IReportSales) {
  const [countToggled, setCountToggled] = useState(false);
  return (
    <ReportSalesStyle>
      <div className="sales-report__products">
        <span className="sales-report__header  flex-center-justify">
          <section>
            {countToggled ? "Ilość zamówień:" : "Suma pieniędzy:"}
          </section>
          <section className="flex-right toggler">
            <section className="toggler__label">
              Zamówienia:
            </section>
            <Toggler
              toggled={countToggled}
              onToggleChanged={(value) => setCountToggled(value)}
            ></Toggler>
          </section>
        </span>
        <div className="sales-report__chart" key={countToggled.toString()}>
          <ChartComponent
            chartType="bar"
            chartData={props.groupCashList?.map((el) => {
              return {
                key: el.group,
                value: countToggled ? el.ordersCount : el.cashAmount,
              };
            })}
            xFormatter={props.xFormatter}
            yFormatter={(value: number) =>
              countToggled ? value + " szt." : value + " zł"
            }
          ></ChartComponent>
        </div>
      </div>
      <div className="sales-report__summary">
        Łączna suma sprzedaży: {formatNumber(props.cashVolumeFromSales) + " zł"}
      </div>
    </ReportSalesStyle>
  );
}
