import { useState } from "react";
import styled from "styled-components";
import { Icon } from "./Icon";

const QuantityStyle = styled.div`
  .quantity {
    & * {
      display: flex;
    }
    flex-direction: row;
    &__line {
      display: flex;
      align-items: center;
      &__decrement {
        cursor: pointer;
      }
      &__input {
        margin-left: 1rem;
        margin-right: 1rem;
      }
      &__increment {
        cursor: pointer;
      }
    }
  }
`;
export interface IQuantity {
  initialValue: number;
  minValue?: number;
  maxValue?: number;
  valueSuffix?: string;
  customPlusTemplate?: string | JSX.Element;
  customMinusTemplate?: string | JSX.Element;
  onValueChanged: (value: number) => void;
}

export const Quantity = (props: IQuantity) => {
  const [count, setCount] = useState(props.initialValue);

  const decrementCount = () => {
    let targetValue = count - 1;
    if (props.minValue && targetValue <= props.minValue)
      targetValue = props.minValue;
    setQuantity(targetValue);
  };

  const incrementCount = () => {
    let targetValue = count + 1;
    if (props.maxValue && targetValue >= props.maxValue)
      targetValue = props.maxValue;
    setQuantity(targetValue);
  };

  const setQuantity = (qty: any) => {
    const regExp = /^([1-9]|[1-9]\d|[1-9]\d\d|[1-9]\d\d\d)$/;
    if (regExp.test(qty)) {
      setCount(parseInt(qty));
      props.onValueChanged(qty);
    }
  };

  return (
    <QuantityStyle>
      <div className="quantity">
        <div className="quantity__line">
          <div>
            <button className="btn btn--active" onClick={decrementCount}>
              {props.customMinusTemplate ? (
                props.customMinusTemplate
              ) : (
                <Icon>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fal"
                    data-icon="minus"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      fill="currentColor"
                      d="M376 232H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h368c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"
                    ></path>
                  </svg>
                </Icon>
              )}
            </button>
          </div>
          <div className="quantity__line__input">
            {count}
            {props.valueSuffix ?? ""}
          </div>
          <div>
            <button className="btn btn--active" onClick={incrementCount}>
              {props.customPlusTemplate ? (
                props.customPlusTemplate
              ) : (
                <Icon>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fal"
                    data-icon="plus"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      fill="currentColor"
                      d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"
                    ></path>
                  </svg>
                </Icon>
              )}
            </button>
          </div>
        </div>
      </div>
    </QuantityStyle>
  );
};
