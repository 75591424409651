import useDispatcherState from "../hooks/useDispatcherState";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

export const NotificationRouteTemplateStyle = styled.section`
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
  position: relative;
  .count {
    padding: 0.3rem;
    padding-bottom: 0.2rem;
    margin-right: 0.2rem;
    z-index: 1;
    position: relative;
    &:before {
      position: absolute;
      width: 100%;
      height: 1.2rem;
      background-color: var(--col-bg-primary);
      content: "";
      z-index: -1;
      border-radius: 50%;
      animation: blink 1s step-start infinite;
      left: 50%;
      top: 52%;
      transform: translate(-50%, -50%);
    }
    &--dismissed {
      &:before {
        animation: unset;
      }
    }
    &--active {
      color: var(--col-bg-primary);
      &:before {
        background-color: var(--col-fg-active);
        animation: unset;
      }
    }
  }
`;

export function RouteTemplateWithNotification(props: {
  storeKey: string;
  children: JSX.Element | string;
}) {
  const [value] = useDispatcherState<number>(props.storeKey);
  const [count, setCount] = useState(value ?? 0);
  const [dismissed, setDismissed] = useState(false);
  const elRef = useRef<HTMLSpanElement>(null);

  const countState = useRef({
    current: 0,
    previous: 0,
  });

  useEffect(() => {
    const el = elRef.current;
    el?.classList.remove("count--active");
    const parent = el?.closest(".navbar-light--active");
    if (parent) {
      el?.classList.add("count--active");
    }
  });

  useEffect(() => {
    countState.current = {
      ...countState.current,
      current: value ?? 0,
    };
    setCount(value);
  }, [value]);

  useEffect(() => {
    const el = elRef.current;
    const parent = el?.closest(".navbar-light--active");
    if (parent) {
      // link is selected, can dismiss blinking
      setDismissed(true);
    }
  }, [count]);

  useEffect(() => {}, [dismissed, value]);

  return (
    <NotificationRouteTemplateStyle>
      {count > 0 && (
        <span ref={elRef} className={`count count--dismissed`}>
          {count}
        </span>
      )}
      {props.children}
    </NotificationRouteTemplateStyle>
  );
}
