import useDispatcherState from "../hooks/useDispatcherState";
import { List } from "../components/common/List";
import styled from "styled-components";
import { StoreKeysEnum } from "../store/storeKeysEnum";
import { ActiveOrderModel } from "../models/api/activeOrderModel";
import { OrderList } from "../components/feature/OrderList";
import useActiveOrders from "../hooks/useActiveOrders";
import { AsyncComponent } from "../components/common/AsyncComponent";

export const ActiveOrdersViewStyle = styled.div`
  .order-list {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 33%;
    flex-grow: 1;
    justify-content: space-between;
    .item {
      margin: 0.3rem;
      flex-grow: 1;
    }
    &__main {
      width: 100%;
    }
    &__wrapper {
      gap: 0.5rem;
    }
  }
`;

export function ActiveOrdersView() {
  const [localOrders] = useDispatcherState<ActiveOrderModel[]>(
    StoreKeysEnum.LocalOrders
  );
  useActiveOrders();
  return (
    <ActiveOrdersViewStyle>
      <AsyncComponent data={localOrders} isLoading={!localOrders}>
        {() => {
          return (
            <List
              list={localOrders}
              className={"order-list order-list__wrapper"}
            >
              {(order: ActiveOrderModel) => <OrderList order={order} />}
            </List>
          );
        }}
      </AsyncComponent>
    </ActiveOrdersViewStyle>
  );
}
