import styled from "styled-components";
import { AwaitingOrders } from "../components/feature/AwaitingOrders";
import useDispatcherState from "../hooks/useDispatcherState";
import { ProductModel } from "../models/api/product";
import { StoreKeysEnum } from "../store/storeKeysEnum";

const Style = styled.div`
  .awaiting-orders {
    padding: 0.5rem;
    padding-bottom: 0.2rem;
    background-color: var(--col-bg-primary);
  }
`;

export function AwaitingOrdersView() {
  const [products] = useDispatcherState<ProductModel[]>(StoreKeysEnum.Products);
  return (
    <Style>
      <div className="awaiting-orders" key={"products" + products?.length}>
        <AwaitingOrders></AwaitingOrders>
      </div>
    </Style>
  );
}
