import styled from "styled-components";
import { useEffect, useState } from "react";
import { IngredientModel } from "../../models/api/ingredient";
import { DropDown } from "../common/DropDown";
import { UnitModel } from "../../models/api/unit";
import { Input } from "../common/Input";

const NewIngridienttStyle = styled.section`
  border: var(--border-width) solid var(--col-bg-focus);
  margin: 0.2rem;
  width: 100%;
  .variant {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
  }
  .input-field {
    padding: 0.2rem;
    display: flex;
    flex-direction: column;
    .name {
      text-align: left;
    }
    .input {
      height: 2rem;
      width: 100%;
    }
    &__unit {
      flex-basis: 30%;
      .unit {
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &__amount {
      flex-basis: 20%;
    }
  }
  .action {
    margin-top: 0.5rem;
    display: flex;
    padding: 0.2rem;
  }
`;

export interface INewIngridient {
  ingridient: IngredientModel;
  units: UnitModel[];
  availableIngridients: IngredientModel[];
  onIngridientChanged: (ingridient: IngredientModel) => void;
  onIngridientDeleted: (ingridient: IngredientModel) => void;
}

export function NewIngridient(props: INewIngridient) {
  const [ingridient, setIngridient] = useState(props.ingridient ?? []);

  useEffect(() => {
    setIngridient(props.ingridient ?? []);
  }, [props.ingridient]);

  useEffect(() => {
    props.onIngridientChanged(ingridient);
  }, [ingridient]);

  return (
    <NewIngridienttStyle>
      <div className="variant">
        <div className="input-field">
          <div className="name">Składnik:</div>
          <DropDown
            items={props.availableIngridients.map((ingridient) => {
              return { key: ingridient?.id, value: ingridient?.description };
            })}
            selectedIndex={props.availableIngridients.findIndex(
              (e) => e.id === ingridient?.id
            )}
            onItemSelected={(e: any) =>
              e &&
              setIngridient((old: IngredientModel) => {
                return {
                  ...old,
                  id: props.availableIngridients.find(
                    (item) => item.id === e.key
                  )?.id,
                  description: props.availableIngridients.find(
                    (item) => item.id === e.key
                  )?.description,
                  unit: props.availableIngridients.find(
                    (item) => item.id === e.key
                  )?.unit,
                };
              })
            }
          ></DropDown>
        </div>
        <div className="input-field input-field__amount">
          <div className="name">Ilość:</div>
          <Input
            type="number"
            className="input"
            value={props.ingridient?.amount ?? ""}
            onChange={(e) =>
              e &&
              setIngridient((old: IngredientModel) => {
                return {
                  ...old,
                  amount: isNaN(Number(e)) ? undefined : Number(e),
                };
              })
            }
          ></Input>
        </div>
        <div className="input-field input-field__unit">
          <div className="name">Jednostka:</div>
          <div className="unit" key={ingridient?.id}>
            {
              props.units?.find((el) => el.id === ingridient?.unit?.id)
                ?.description
            }
          </div>
        </div>
      </div>
      <div className="variant-actions">
        <button
          className="btn--alert"
          onClick={() => {
            props.onIngridientDeleted(ingridient);
          }}
        >
          Usuń składnik
        </button>
      </div>
    </NewIngridienttStyle>
  );
}
