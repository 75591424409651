import { ProductModel } from "../../models/api/product";
import styled from "styled-components";

export const ProductStyle = styled.section`
  width: 100%;
  height: 100%;
  .product {
    height: 100%;
    span {
      display: flex;
      white-space: break-spaces;
      text-align: left;
      align-items: center;
    }
    &__btn {
      width: 100%;
      height: 100%;
      ${(props) => (props as any).theme};
    }
    &__sequence {
      font-size: 2rem;
      color: var(--col-fg-active-darker);
      text-align: center;
      display: unset;
      margin-right: 1rem;
      align-items: center;
    }
    &__description {
      font-size: 1.8rem;
      align-items: center;
    }
  }
`;

export interface IProduct {
  product: ProductModel;
  onProductSelected: (product: ProductModel) => void;
}

export function Product(props: IProduct) {
  return (
    <ProductStyle
      theme={
        props.product.colorCode &&
        `background-color: var(${props.product.colorCode})!important`
      }
    >
      <div className="product">
        <button
          className="btn btn--active btn--big product__btn"
          onClick={() => props.onProductSelected(props.product)}
        >
          <span>
            <div className="product product__sequence">
              {props.product.displaySequence}
            </div>
            <div className="product product__description">
              {props.product.description}
            </div>
          </span>
        </button>
      </div>
    </ProductStyle>
  );
}
