import styled from "styled-components";
import { VariantModel } from "../../models/api/variant";

const VariantStyle = styled.section`
  width: 100%;
  * {
    font-size: 2rem;
    display: flex;
  }
  .variant {
    &__btn {
      width: 100%;
      ${(props) => (props as any).theme};
    }
  }
`;

export interface IVariant {
  variant: VariantModel;
  onVariantSelected: (product: VariantModel) => void;
}

export function ProductVariant(props: IVariant) {
  return (
    <VariantStyle
      theme={
        props.variant.colorCode &&
        `background-color: var(${props.variant.colorCode})!important`
      }
    >
      <div className="variant">
        <button
          className="variant__btn btn btn--active"
          onClick={() => props.onVariantSelected(props.variant)}
        >
          <span>
            <div className="variant variant__description">
              {props.variant.description}
            </div>
          </span>
        </button>
      </div>
    </VariantStyle>
  );
}
