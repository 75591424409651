import { useMemo } from "react";
import useDispatcherState from "./useDispatcherState";
import { StoreKeysEnum } from "../store/storeKeysEnum";
import { AppSettingsKeys } from "../models/app/appSettingsKeys";
import { OrderStateEnum } from "../models/enums/orderStateEnum";
import { AppSettingModel } from "../models/api/appSettingModel";
import { ActiveOrderModel } from "../models/api/activeOrderModel";

export default function useIsOrderValid(order?: ActiveOrderModel) {
  const [settings] = useDispatcherState<AppSettingModel[]>(
    StoreKeysEnum.ServerSettings
  );
  const isOrderValid = useMemo(() => {
    if (order?.orders?.[0]?.state !== OrderStateEnum.Awaiting) {
      return true;
    }
    const isStartingDateRequired =
      settings
        ?.find(
          (e) => e.key === AppSettingsKeys.RequireExpectedStartingDateInNewOrder
        )
        ?.value?.toLocaleLowerCase() === String(true).toLocaleLowerCase() ??
      false;
    const isCompletionDateRequired =
      settings
        ?.find(
          (e) =>
            e.key === AppSettingsKeys.RequireExpectedCompletionDateInNewOrder
        )
        ?.value?.toLocaleLowerCase() === String(true).toLocaleLowerCase() ??
      false;

    if (isStartingDateRequired && !order?.expectedStartingDate) {
      return false;
    }
    if (isCompletionDateRequired && !order?.expectedCompletionDate) {
      return false;
    }
    return true;
  }, [settings, order]);

  return isOrderValid;
}
