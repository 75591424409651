import styled from "styled-components";
import { useApiCall } from "../../hooks/useApiCall";
import { AsyncComponent } from "../common/AsyncComponent";
import { DropDown } from "../common/DropDown";
import { ReportsUrl } from "../../config";
import { useEffect, useMemo, useState } from "react";
import { ReportSales } from "./ReportSales";
import { ReportSalesBarChart } from "./ReportSalesBarChart";
import { ReportSalesGrid } from "./ReportSalesGrid";
import { AuthenticationUrl } from "../../config";
import { User } from "../../models/api/user";
import { IQuery } from "../common/Filters";
import { ReportsFilters } from "./ReportsFilters";
import { GenericView } from "../common/GenericView";
import useDispatcherState from "../../hooks/useDispatcherState";
import { StoreKeysEnum } from "../../store/storeKeysEnum";
import { ProductModel } from "../../models/api/product";
import { dayOfWeek } from "../../helpers/dayOfWeek";
import { ReportProductCreationTimeGrid } from "./ReportProductCreationTimeGrid";
import { FilterPillButton } from "../common/FilterPill";
import { ViewMoreButton } from "../common/ViewMoreButton";
import { IngredientModel } from "../../models/api/ingredient";
import { ReportSalesHeatMap } from "./ReportSalesHeatMap";

const ReportsStyle = styled.section`
  height: 100%;
  .report {
    &__header {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      .filter-label {
        margin-right: 1rem;
        text-align: left;
        max-width: max-content;
      }
      .filter-value {
        max-width: 30rem;
      }
    }
    &__filters {
      display: flex;
      flex-wrap: wrap;
      gap: 0.2rem;
    }
    &__content {
    }
  }
`;

export function Reports() {
  const availableReports = [
    { key: "sales", value: "Raport sprzedaży" },
    { key: "sales/perHour", value: "Sprzedaż według godzin" },
    { key: "sales/perDayOfWeek", value: "Sprzedaż według dnia tyg." },
    { key: "sales/heatMap", value: "Mapa cieplna sprzedaży" },
    { key: "duration/productCreation", value: "Średni czas realizacji" },
    { key: "orders", value: "Zamówienia" },
  ];
  const [response, isLoading, error, get, post] = useApiCall({});
  const [users] = useDispatcherState<User[]>(StoreKeysEnum.Users);
  const [products] = useDispatcherState<ProductModel[]>(StoreKeysEnum.Products);
  const [ingredients] = useDispatcherState<IngredientModel[]>(
    StoreKeysEnum.Ingredients
  );
  const [selectedReport, setSelectedReport] = useState(availableReports[0]);
  const [filters, setFilters] = useState<React.ReactNode>();
  const [query, setQuery] = useState<IQuery[]>([
    {
      key: "fromDate",
      value: (() => {
        const date = new Date(Date.now());
        date.setHours(0, 0, 0, 0);
        return date.toISOString();
      })(),
      label: "od: " + new Date(Date.now()).toLocaleDateString(),
    },
  ]);

  function clearSelection(key: string) {
    setQuery((old) => {
      const _filtered = old.filter((e) => e.key !== key);
      return [..._filtered];
    });
  }

  const invokeFiltersView = (
    products: ProductModel[],
    users: User[],
    viewClosed?: boolean
  ) => {
    setFilters(
      <GenericView
        viewTitle={"Filtruj wyniki"}
        closed={viewClosed}
        onClose={() => setFilters(undefined)}
        fitToContent={true}
      >
        <ReportsFilters
          users={users}
          products={products}
          ingredients={ingredients}
          query={query}
          onApply={(filters) => {
            invokeFiltersView(products, users ?? [], true);
            setQuery(filters);
          }}
        ></ReportsFilters>
      </GenericView>
    );
  };

  const reportContent = useMemo(
    () => (
      <div className="report">
        <div className="report__header flex-center-justify">
          <div className="flex-left flex-grow">
            <div className="filter-label">Wybierz raport:</div>
            <div className="filter-value flex-grow">
              <DropDown
                items={availableReports}
                onItemSelected={(e) => setSelectedReport(e)}
              />
            </div>
          </div>
          <ViewMoreButton
            onClick={() => invokeFiltersView(products, users ?? [])}
          ></ViewMoreButton>
        </div>
        <div className="report__filters">
          {query.map((q, i) => (
            <FilterPillButton key={i} onClick={() => clearSelection(q.key)}>
              <>{q.label}</>
            </FilterPillButton>
          ))}
        </div>
        <div className="report__content">
          <AsyncComponent data={response} isLoading={isLoading} error={error}>
            {(data: any) => {
              return (
                <>
                  {selectedReport.key === "sales" && (
                    <ReportSales
                      soldVariants={data.soldVariants}
                      soldProducts={data.soldProducts}
                      ingredientsUsed={data.ingredientsUsed}
                      cashVolumeFromSales={data.cashVolumeFromSales}
                      ingredientsCost={data.ingredientsCost}
                      labourCost={data.labourCost}
                    ></ReportSales>
                  )}
                  {selectedReport.key === "sales/perHour" && (
                    <ReportSalesBarChart
                      groupCashList={data.hourCashList?.map((el: any) => {
                        return { ...el, group: el.hour };
                      })}
                      cashVolumeFromSales={data.cashVolumeFromSales}
                    ></ReportSalesBarChart>
                  )}
                  {selectedReport.key === "sales/perDayOfWeek" && (
                    <ReportSalesBarChart
                      groupCashList={data.dayOfWeekCashList?.map((el: any) => {
                        return { ...el, group: el.day };
                      })}
                      cashVolumeFromSales={data.cashVolumeFromSales}
                      xFormatter={(val) => dayOfWeek(val.key)}
                    ></ReportSalesBarChart>
                  )}
                  {selectedReport.key === "sales/heatMap" && (
                    <ReportSalesHeatMap {...data}></ReportSalesHeatMap>
                  )}
                  {selectedReport.key === "orders" && Array.isArray(data) && (
                    <ReportSalesGrid
                      orders={data}
                      users={users ?? []}
                    ></ReportSalesGrid>
                  )}
                  {selectedReport.key === "duration/productCreation" && (
                    <ReportProductCreationTimeGrid
                      productsCreationTime={data.productsCreationTime}
                    ></ReportProductCreationTimeGrid>
                  )}
                </>
              );
            }}
          </AsyncComponent>
        </div>
      </div>
    ),
    [products, users, response, isLoading, error]
  );

  useEffect(() => {
    if (query.length > 0) {
      let url = `${ReportsUrl}/${selectedReport.key}?`;
      query.forEach((q) => (url += q.key + "=" + q.value + "&"));
      get(url.slice(0, -1));
    } else {
      get(null);
    }
  }, [selectedReport, query]);

  return (
    <ReportsStyle>
      {reportContent}
      {filters}
    </ReportsStyle>
  );
}
