import styled from "styled-components";
import { useMemo } from "react";
import { Table } from "../common/Table";
import useProduct from "../../hooks/useProduct";
import { Row } from "react-table";
import { mDesktop } from "../../styles/mixins";

const ReportSalesStyle = styled.section`
  .sales-report {
    &__grid {
      border: var(--border-width) solid var(--col-fg-secondary);
      margin-top: 0.5rem;
    }
  }
`;

interface IReportCreationTime {
  productsCreationTime: {
    creationTimeInMins: number;
    productId: number;
  }[];
}

export function ReportProductCreationTimeGrid(props: IReportCreationTime) {
  const { getProduct } = useProduct();

  const columns = useMemo(
    () => [
      {
        Header: "Produkt",
        accessor: "productId",
        Cell: (props: { value: number; row: Row }) =>
          getProduct(props.value)?.description,
      },
      {
        Header: "Czas realizacji w minutach",
        accessor: "creationTimeInMins",
      },
    ],
    []
  );
  return (
    <ReportSalesStyle>
      <div className="sales-report__products">
        <div>{`Czas realizacji (${props.productsCreationTime?.length} elementów):`}</div>
        <div className="sales-report__grid">
          <Table
            data={props.productsCreationTime ?? []}
            columns={columns}
          ></Table>
        </div>
      </div>
    </ReportSalesStyle>
  );
}
