import styled from "styled-components";

const InputStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 2rem;
  width: 100%;
  input {
    width: 100%;
    border: var(--border-width) solid var(--col-bg-primary);
    font-size: 1rem;
    :focus {
      border: var(--border-width) solid var(--col-fg-active);
    }
    :invalid:focus {
      + span {
        margin: unset;
        position: relative;
        &::before {
          position: absolute;
          left: 0;
          right: 0;
          font-size: 0.7rem;
          line-height: 0.8rem;
          content: "${(props) => props.theme.invalidLabel}";
        }
      }
    }
  }
`;

export interface InputProps {
  value?: string | number;
  type?: string;
  onChange?: (value: string | number) => void;
  [x: string]: unknown;
}

export function Input(props: InputProps) {
  const { value, type, onChange, ...rest } = props;
  return (
    <InputStyle
      theme={{
        invalidLabel: props.title ?? "Invalid input",
      }}
    >
      <input
        type={props.type ? props.type : "text"}
        defaultValue={props.value}
        onChange={(e) => {
          props.onChange && props.onChange(e.target.value);
        }}
        {...rest}
      ></input>
      <span className="invalid"></span>
    </InputStyle>
  );
}
