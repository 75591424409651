import styled from "styled-components";
import useAppSettings from "../../hooks/useAppSettings";
import { Toggler } from "../common/Toggler";
import { Quantity } from "../common/Quantity";

const CurrentUserAppSettingsStyle = styled.section`
  .settings {
    text-align: left;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .quantity__line {
      .btn {
        width: 4rem;
        padding: 0.2rem;
      }
    }
    .setting {
      gap: 2rem;
      justify-content: space-between;
    }
  }
`;

export function CurrentUserAppSettings() {
  const { AppSettings, ColorScheme } = useAppSettings();

  function changeTheme(d: boolean) {
    d
      ? (document.body.className = "night-theme")
      : (document.body.className = "light-theme");
    AppSettings.setSettings({
      ...AppSettings.getSettings(),
      preferredColorScheme: d ? ColorScheme.Dark : ColorScheme.Light,
    });
  }

  function changeBaseFont(d: number) {
    document.documentElement.style.setProperty(
      "--global-font-base-size",
      `${d}px`
    );
    AppSettings.setSettings({
      ...AppSettings.getSettings(),
      baseFontSize: d + "px",
    });
  }

  return (
    <CurrentUserAppSettingsStyle>
      <span className="settings">
        <span className="flex setting">
          <div>Włącz tryb ciemny</div>
          <Toggler
            toggled={document.body.className === "night-theme" ? true : false}
            onToggleChanged={(d) => {
              changeTheme(d as boolean);
            }}
          ></Toggler>
        </span>
        <span className="flex setting">
          <div>Skaluj interfejs</div>
          <Quantity
            initialValue={Number(
              AppSettings.getSettings().baseFontSize?.replace("px", "")
            )}
            maxValue={20}
            minValue={8}
            customMinusTemplate={"-"}
            customPlusTemplate={"+"}
            onValueChanged={(v) => changeBaseFont(v)}
          ></Quantity>
        </span>
      </span>
    </CurrentUserAppSettingsStyle>
  );
}
