import "bootstrap/dist/css/bootstrap.css";
import { createRoot } from "react-dom/client";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { APP_VERSION } from "./app-version";
import { Dispatcher } from "./services/dispatcher";
import { IDispatcher } from "./models/dispatcher/dispatcher-interfaces";
import enableGestures from "./services/gestures";
import configureAuthentication from "./services/authentication";
import { createBrowserHistory, History } from "history";
import { AppSettingsService } from "./services/settings";
import { IAppSettingsService } from "./models/app/IAppSettingsService";
import { ColorScheme } from "./models/app/app-settings";
import { OrderHub } from "./services/orderHub";
import { NotificationService } from "./services/notifications";
import { INotificationService } from "./models/app/INotifcationService";

//bootstrap app
(globalThis as any).orderMaker = {
  appVersion: APP_VERSION,
};

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

const DispatcherObj: IDispatcher = new Dispatcher();
const AppSettingsObj = new AppSettingsService(DispatcherObj);
const NotificationObj = new NotificationService(DispatcherObj);
const HistoryObj: History = createBrowserHistory();
const _ = new OrderHub(DispatcherObj, NotificationObj);

enableGestures(DispatcherObj, document.getElementById("root"));
configureAuthentication(DispatcherObj, HistoryObj);

(globalThis as any).dispatcher = DispatcherObj;

const appSettings = AppSettingsObj.getSettings();
AppSettingsObj.setSettings(appSettings);
// set theme according to preffered color scheme
switch (appSettings.preferredColorScheme) {
  case ColorScheme.Dark: {
    document.body.className = "night-theme";
    break;
  }
  case ColorScheme.Light: {
    document.body.className = "light-theme";
    break;
  }
  default: {
    document.body.className = "night-theme";
  }
}
document.documentElement.style.setProperty(
  "--global-font-base-size",
  `${appSettings.baseFontSize}`
);

export const DispatcherContext =
  React.createContext<IDispatcher>(DispatcherObj);
export const HistoryContext = React.createContext<History>(HistoryObj);
export const AppSettingsContext =
  React.createContext<IAppSettingsService>(AppSettingsObj);
export const NotificationContext =
  React.createContext<INotificationService>(NotificationObj);

const root = createRoot(rootElement!);
root.render(
  <BrowserRouter basename={baseUrl ? baseUrl : ""}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
