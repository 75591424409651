import { AsyncComponent } from "../common/AsyncComponent";
import { useApiCall } from "../../hooks/useApiCall";
import { GeneralUrl } from "../../config";
import { useMemo } from "react";
import { Table } from "../common/Table";
import {
  formatDateToLocaleString,
  getDurationTextFromDate,
} from "../../helpers/formatter";
import { ConfirmButton } from "../common/ConfirmButton";

export function AdminServerLogs() {
  const [response, isLoading, error, , post, refresh] = useApiCall({
    url: GeneralUrl + "/logs",
    setResponseOnPost: false,
  });

  const columns = useMemo(
    () => [
      {
        Header: "Wiadomość",
        accessor: "message",
      },
      {
        Header: "Data",
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          const _first = new Date(rowA.values[id]).getTime();
          const _second = new Date(rowB.values[id]).getTime();
          if (_first > _second) return 1;
          if (_second > _first) return -1;
          return 0;
        },
        accessor: "timeStamp",
        Cell: (props: { value: string }) => {
          return (
            <>
              {formatDateToLocaleString(props.value)} (
              {getDurationTextFromDate(props.value)})
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <AsyncComponent data={response} isLoading={isLoading} error={error}>
      {(data: any) => {
        return (
          <>
            <Table
              className={"admin-table"}
              data={data}
              columns={columns}
            ></Table>
            <ConfirmButton
              label="Wyczyść wszystkie logi"
              confirmLabel="Kliknij aby wyczyścić logi"
              action={() => {
                post(GeneralUrl + "/logs/clear", null).then(() => refresh());
              }}
            ></ConfirmButton>
          </>
        );
      }}
    </AsyncComponent>
  );
}
