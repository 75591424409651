import React from "react";
import useDispatcherState from "../../hooks/useDispatcherState";
import { Role, User } from "../../models/api/user";
import { StoreKeysEnum } from "../../store/storeKeysEnum";
import {} from "./Loader";

export interface IRestrictedElement {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  restrictions: Role[];
}

export function RestrictedElement(props: IRestrictedElement) {
  const [activeUser] = useDispatcherState<User>(StoreKeysEnum.User);
  return (
    <>
      {activeUser?.role && !props.restrictions.includes(activeUser.role)
        ? props.children
        : props.fallback}
    </>
  );
}
