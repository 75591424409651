import { ToastContainer, toast, ToastContent } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function Toast() {
  return <ToastContainer autoClose={3000} hideProgressBar={true} />;
}

export function info(message: ToastContent | string) {
  toast.info(message);
}

export function error(message: ToastContent | string) {
  toast.error(message);
}

export function success(message: ToastContent | string) {
  toast.success(message);
}

export function warning(message: ToastContent | string) {
  toast.warning(message);
}
